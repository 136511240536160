import SlidingPane from 'react-sliding-pane';
import { GrClose } from 'react-icons/gr';
import SmoothCollapse from 'react-smooth-collapse';
import LangaugeSwitcher from '../LanguageSwitcher';
import config from '../../configs/config';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import './styles/smallDevicesSlidePane.scss';
import { useSelector } from 'react-redux';
import { languageSelector } from '../../store/slices/appSlice';
import { TRANSLATIONS } from '../../translations/translations';
import { useState } from 'react';
import { documentList } from '../../configs/documentsConfig';
import { ENDPOINTS } from '../../configs/endpoints';

const { IUCN_TOOL_URL } = ENDPOINTS;

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  handleOnClick: () => void;
}

const SmallDevicesSlidePane = ({ isOpen, setIsOpen, handleOnClick }: Props) => {
  const language = useSelector(languageSelector);
  const selectedLanguage = TRANSLATIONS[language];
  const [expandDocuments, setExpandDocuments] = useState(false);

  const DOCUMENTS_LIST = documentList(selectedLanguage);

  const toggleDocumentDropdown = () => {
    setExpandDocuments(!expandDocuments);
  };

  return (
    <SlidingPane
      isOpen={isOpen}
      width='300px'
      hideHeader={true}
      className='sliding-pane'
      overlayClassName='sliding-pane-overlay'
      onRequestClose={() => {
        setIsOpen(false);
      }}
    >
      <div className='header-links'>
        <button onClick={() => setIsOpen(false)} className='close-btn'>
          <GrClose />
        </button>
        <ul>
          <li>
            <button onClick={toggleDocumentDropdown}>Documents</button>
          </li>
          <li>
            <a href={IUCN_TOOL_URL} target='_blank' rel='noreferrer'>
              {selectedLanguage['Add Contributions']}
            </a>
          </li>
        </ul>
        <div className='launch-app-btn'>
          <button onClick={handleOnClick}>{selectedLanguage['View Contributions']}</button>
        </div>
        <div className='language-switcher-btns'>
          <LangaugeSwitcher {...config.languageConfig} />
        </div>
        <SmoothCollapse expanded={expandDocuments}>
          <div className='expand-documents-container'>
            <div className='document-links-list'>
              {DOCUMENTS_LIST.map((item) => (
                <a key={item.id} target='_blank' rel='noreferrer' href={item.url}>
                  {item.label}
                </a>
              ))}
            </div>
          </div>
        </SmoothCollapse>
      </div>
    </SlidingPane>
  );
};

export default SmallDevicesSlidePane;
