import { configureStore, combineReducers } from '@reduxjs/toolkit';

import mapSlice from './slices/mapSlice';
import appSlice from './slices/appSlice';
import sidePanelSlice from './slices/sidePanelSlice';
import chartSlice from './slices/chartSlice';
import loaderSlice from './slices/loaderSlice';

const rootReducer = combineReducers({
  mapSlice,
  appSlice,
  sidePanelSlice,
  chartSlice,
  loaderSlice,
});

export type RootState = ReturnType<typeof rootReducer>;

export default configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
