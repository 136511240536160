import { getRemainingValue, removeDecimalsFromNumber } from './helperMethods';

export async function countryChartDataBuilder(ISO3: any, selectedLanguage: any) {
  const { contributionData, countryData, regionData, GLOBAL_VALUES } = ISO3;
  const { data, country } = countryData;
  const { regionCarbonResponse, regionTAResponse, regionRestorationResponse, region } = regionData;

  if (data) {
    const threatAbatementRemaining = getRemainingValue(
      data[0]?.country_score_TA_total,
      data[2]?.country_score_TA_total
    );

    const threatAbatement = [
      {
        id: 'star-threat-abatement',
        label: selectedLanguage['Threat Abatement Contribution-Land region'],
        chartData: [
          {
            name: 'Contribution',
            y: removeDecimalsFromNumber(data[0]?.country_score_TA_total),
          },
          {
            name: country || selectedLanguage['No Data'],
            y: threatAbatementRemaining.y,
            rawValue: threatAbatementRemaining.rawValue,
          },
        ],
      },
      {
        id: 'star-threat-abatement',
        label: selectedLanguage['Threat Abatement Land region-Continent'],
        chartData: [
          {
            name: country || selectedLanguage['No Data'],
            y: removeDecimalsFromNumber(data[2]?.country_score_TA_total),
          },
          {
            name: region || selectedLanguage['No Data'],
            y: getRemainingValue(data[2]?.country_score_TA_total, regionTAResponse[0]?.region_TA_total).y,
            rawValue: getRemainingValue(data[2]?.country_score_TA_total, regionTAResponse[0]?.region_TA_total).rawValue,
          },
        ],
      },
      {
        id: 'star-threat-abatement',
        label: selectedLanguage['Threat Abatement Continent-Global'],
        chartData: [
          {
            name: region || selectedLanguage['No Data'],
            y: removeDecimalsFromNumber(regionTAResponse[0]?.region_TA_total),
          },
          {
            name: selectedLanguage['Global'],
            y: getRemainingValue(regionTAResponse[0]?.region_TA_total, GLOBAL_VALUES.THREAT_ABATEMENT).y,
            rawValue: getRemainingValue(regionTAResponse[0]?.region_TA_total, GLOBAL_VALUES.THREAT_ABATEMENT).rawValue,
          },
        ],
      },
    ];

    const restorationRemaining = getRemainingValue(
      contributionData?.contributionSTARRTotal,
      data[2]?.country_score_R_total
    );

    const restoration = [
      {
        id: 'star-restoration',
        label: selectedLanguage['Restoration Contribution-Land region'],
        chartData: [
          {
            name: 'Contribution',
            y: removeDecimalsFromNumber(contributionData?.contributionSTARRTotal),
          },
          {
            name: country || selectedLanguage['No Data'],
            y: restorationRemaining.y,
            rawValue: restorationRemaining.rawValue,
          },
        ],
      },

      {
        id: 'star-restoration',
        label: selectedLanguage['Restoration Land region - Continent'],
        chartData: [
          {
            name: country || selectedLanguage['No Data'],
            y: removeDecimalsFromNumber(contributionData?.contributionSTARRTotal),
          },
          {
            name: region || selectedLanguage['No Data'],
            y: getRemainingValue(data[2]?.country_score_R_total, regionRestorationResponse[0]?.region_Restoration_total)
              .y,
            rawValue: getRemainingValue(
              data[2]?.country_score_R_total,
              regionRestorationResponse[0]?.region_Restoration_total
            ).rawValue,
          },
        ],
      },
      {
        id: 'star-restoration',
        label: selectedLanguage['Restoration Continent-Global'],
        chartData: [
          {
            name: region || selectedLanguage['No Data'],
            y: removeDecimalsFromNumber(regionRestorationResponse[0]?.region_Restoration_total),
          },
          {
            name: selectedLanguage['Global'],
            y: getRemainingValue(regionRestorationResponse[0]?.region_Restoration_total, GLOBAL_VALUES.STAR_RESTORATION)
              .y,
            rawValue: getRemainingValue(
              regionRestorationResponse[0]?.region_Restoration_total,
              GLOBAL_VALUES.STAR_RESTORATION
            ).rawValue,
          },
        ],
      },
    ];

    const climateMitigation = [
      {
        id: 'climate-mitigation',
        label: selectedLanguage['Climate Mitigation Contribution-Land region'],
        chartData: [
          {
            name: 'Contribution',
            y: removeDecimalsFromNumber(contributionData?.contributionCarbonTotal),
          },
          {
            name: country || selectedLanguage['No Data'],
            y: getRemainingValue(contributionData?.contributionCarbonTotal, data[1]?.country_carbon_total).y,
            rawValue: getRemainingValue(contributionData?.contributionCarbonTotal, data[1]?.country_carbon_total)
              .rawValue,
          },
        ],
      },
      {
        id: 'climate-mitigation',
        label: selectedLanguage['Climate Mitigation Land region - Continent'],
        chartData: [
          {
            name: country || selectedLanguage['No Data'],
            y: removeDecimalsFromNumber(data[1]?.country_carbon_total),
          },
          {
            name: region || selectedLanguage['No Data'],
            y: getRemainingValue(data[1]?.country_carbon_total, regionCarbonResponse[0]?.region_carbon_total).y,
            rawValue: getRemainingValue(data[1]?.country_carbon_total, regionCarbonResponse[0]?.region_carbon_total)
              .rawValue,
          },
        ],
      },
      {
        id: 'climate-mitigation',
        label: selectedLanguage['Climate Mitigation Continent-Global'],
        chartData: [
          {
            name: region || selectedLanguage['No Data'],
            y: removeDecimalsFromNumber(regionCarbonResponse[0]?.region_carbon_total),
          },
          {
            name: selectedLanguage['Global'],
            y: getRemainingValue(regionCarbonResponse[0]?.region_carbon_total, GLOBAL_VALUES.CLIMATE_MITIGATION).y,
            rawValue: getRemainingValue(regionCarbonResponse[0]?.region_carbon_total, GLOBAL_VALUES.CLIMATE_MITIGATION)
              .rawValue,
          },
        ],
      },
    ];

    return {
      threatAbatement,
      restoration,
      climateMitigation,
    };
  } else {
    return {
      threatAbatement: [],
      restoration: [],
      climateMitigation: [],
    };
  }
}
