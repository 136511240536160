import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { ChartContentType } from './sidePanelSlice';

export const CHART_IDS = {
  STAR_THREAT_ABATEMENT: 'star-threat-abatement',
  STAR_RESTORATION: 'star-restoration',
  CLIMATE_MITIGATION: 'climate-mitigation',
} as const;

interface StateTypes {
  chartData: any;
  visibleChartPanel: ChartContentType; // contribution chart panel
  visibleChartOrg: ChartContentType;
  visibleChartCountry: ChartContentType;
  visibleChartRegion: ChartContentType;
}
const initialState: StateTypes = {
  chartData: {},
  visibleChartPanel: CHART_IDS.STAR_THREAT_ABATEMENT,
  visibleChartOrg: CHART_IDS.STAR_THREAT_ABATEMENT,
  visibleChartCountry: CHART_IDS.STAR_THREAT_ABATEMENT,
  visibleChartRegion: CHART_IDS.STAR_THREAT_ABATEMENT,
};

export const chartSlice = createSlice({
  name: 'chartSlice',
  initialState,
  reducers: {
    setChartData: (state, action: PayloadAction<any>) => {
      state.chartData = action.payload;
    },
    setVisibleChartPanel: (state, action: PayloadAction<ChartContentType>) => {
      state.visibleChartPanel = action.payload;
    },
    setVisibleChartOrg: (state, action: PayloadAction<ChartContentType>) => {
      state.visibleChartOrg = action.payload;
    },
    setVisibleChartCountry: (state, action: PayloadAction<ChartContentType>) => {
      state.visibleChartCountry = action.payload;
    },
    setVisibleChartRegion: (state, action: PayloadAction<ChartContentType>) => {
      state.visibleChartRegion = action.payload;
    },
  },
});

export const { setChartData, setVisibleChartPanel, setVisibleChartCountry, setVisibleChartOrg, setVisibleChartRegion } =
  chartSlice.actions;

export const chartDataSelector = (state: RootState) => state.chartSlice.chartData;
export const visibleChartPanelSelector = (state: RootState) => state.chartSlice.visibleChartPanel;

export const visibleChartOrgSelector = (state: RootState) => state.chartSlice.visibleChartOrg;
export const visibleChartCountrySelector = (state: RootState) => state.chartSlice.visibleChartCountry;
export const visibleChartRegionSelector = (state: RootState) => state.chartSlice.visibleChartRegion;

export default chartSlice.reducer;
