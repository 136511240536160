import { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AiFillCaretLeft } from 'react-icons/ai';
import Pagination from '@material-ui/lab/Pagination';
import {
  countryLevelVisibleChartSelector,
  setOrgsCount,
  setSelectedOrganizationName,
  setVisiblePanel,
} from '../../../store/slices/sidePanelSlice';
import {
  languageSelector,
  regionLevelDataSelector,
  setCountryLevelData,
  setRegionLevelData,
  setUserContributions,
} from '../../../store/slices/appSlice';
import { mapController } from '../../../controllers/MapController';
import RegionStatsDropdown from '../../Dropdowns/RegionStatsDropdown';
import { statisticsQueries } from '../../../configs/statisticsQueries';
import { getFeatures, queryDataStatistics } from '../../../utils/fetchData';
import { regionChartDataBuilder } from '../../../utils/regionLevelChartData';
import { letters } from '../../../configs/lettersData';
import './styles/sidePanel.scss';
import './styles/sidePanelCountry.scss';
import './styles/sidePanelRegion.scss';
import { GLOBAL_VALUES } from '../../../configs/globalValues';
import { ENDPOINTS } from '../../../configs/endpoints';
import { getCarbonTotal, getTotalByProp, queryStringBuilder } from '../../../utils/helperMethods';
import ShowRecordsComponent from '../../showRecordsComponent/ShowRecordsComponent';
import { GlobalDataTypes } from './types';
import { setSelectedCountryOrRegion } from '../../../store/slices/mapSlice';
import { TRANSLATIONS } from '../../../translations/translations';
import { visibleChartRegionSelector } from '../../../store/slices/chartSlice';
const { regionTA, regionRestoration, regionCarbon, countryAggregate } = statisticsQueries;

const globalDataDefaultValues = {
  carbonValue: 0,
  starValueRTotal: 0,
  starValueTATotal: 0,
  totalBudget: 0,
  totalBudgetNeeded: 0,
  totalBeneficiaries: 0,
  totalStaff: 0,
};

const SidePanelRegion = () => {
  const [expanedFilters, setExpandedFilters] = useState(true);
  const [statsData, setStatsData] = useState<GlobalDataTypes>(globalDataDefaultValues);
  const [chartsData, setCahrtsData] = useState({});
  const [dataCopy, setDataCopy] = useState<any>([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [lettersData, setLettersData] = useState(letters);
  const regionLevelData = useSelector(regionLevelDataSelector);
  const countryLevelVisibleChart = useSelector(countryLevelVisibleChartSelector); // todo: remove variable

  const visibleChartRegion = useSelector(visibleChartRegionSelector);
  const dispatch = useDispatch();

  const language = useSelector(languageSelector);
  const selectedLanguage = TRANSLATIONS[language];

  const getData = useCallback(async () => {
    const where = `MapRegion='${regionLevelData.region}'`;

    const queryParams = {
      url: ENDPOINTS.APPROVED_CONTRIBUTIONS_LAYER,
      queryParams: {
        where: `beneficiaryRegions LIKE '%${regionLevelData.region}%'`,
        returnGeometry: false,
        outFields: ['*'],
      },
    };

    const [test, regionCarbonResponse, regionTAResponse, regionRestorationResponse, getRegionStats] = await Promise.all(
      [
        await queryDataStatistics({ ...countryAggregate, where }),

        queryDataStatistics({ ...regionCarbon, where }),
        queryDataStatistics({ ...regionTA, where }),
        queryDataStatistics({ ...regionRestoration, where }),
        await getFeatures(queryParams),
      ]
    );

    const contributionCarbonTotal = getCarbonTotal(getRegionStats);
    const contributionSTARRTotal = getTotalByProp(getRegionStats, 'starValueR');
    const contributionSTARTATotal = getTotalByProp(getRegionStats, 'starValueTA');

    const statsValues = {
      carbonValue: contributionCarbonTotal,
      starValueRTotal: contributionSTARRTotal,
      starValueTATotal: contributionSTARTATotal,
      totalBudget: getTotalByProp(getRegionStats, 'totalBudget'),
      totalBudgetNeeded: getTotalByProp(getRegionStats, 'budgetNeeded'),
      totalBeneficiaries: getTotalByProp(getRegionStats, 'totalBeneficiaries'),
      totalStaff: getTotalByProp(getRegionStats, 'totalStaff'),
    };

    const contributionData = {
      contributionCarbonTotal,
      contributionSTARRTotal,
      contributionSTARTATotal,
    };

    const regionData = {
      regionCarbonResponse,
      regionTAResponse,
      regionRestorationResponse,
      region: regionLevelData.region,
    };
    // region to global
    const regionGlobalChartData = regionChartDataBuilder(
      {
        globalData: GLOBAL_VALUES,
        regionData,
        contributionData,
      },
      selectedLanguage
    );
    setCahrtsData(regionGlobalChartData);
    setStatsData(statsValues);
  }, [regionLevelData.region, selectedLanguage]);

  const handleExpandedFilter = () => {
    setExpandedFilters(!expanedFilters);
  };

  const handlePagesCount = useCallback(() => {
    const usersPerPage = 10;
    const pagesVisited = pageNumber * usersPerPage;
    const displayUsers = regionLevelData.data.slice(pagesVisited, pagesVisited + usersPerPage);
    const pagesCount = Math.ceil(regionLevelData.data.length / usersPerPage);

    setPageCount(pagesCount);
    setDataCopy(displayUsers);
  }, [pageNumber, regionLevelData.data]);

  const handleRecordSelection = (data: any) => {
    dispatch(setOrgsCount(data.orgCount));
    dispatch(setUserContributions(data));
    dispatch(setVisiblePanel('organization-panel'));
    dispatch(setSelectedOrganizationName(data.organization));

    let where = queryStringBuilder(`beneficiaryRegions LIKE '%${regionLevelData.region}%'`);

    if (data?.partnerOrgs) {
      const partnerOrgs = data?.partnerOrgs;
      where += ` AND partnerOrgs LIKE '%${partnerOrgs}%'`;
    } else {
      where += ` AND ownerOrgs LIKE '%${data.organization}%'`;
    }

    mapController.filterLayer(where);
    mapController.queryMapExtent(where);

    const params = {
      page: 0,
      count: 10,
      where,
      outFields: ['*'],
      orderByFields: ['name'],
      keepPrevious: false,
    };

    mapController.queryOrgsByPage(params);
  };

  const handleFilterByLetter = (data: any) => {
    if (!data.selected) {
      const filterResult = regionLevelData.data.filter(
        (userData: any) => userData.organization[0].toLowerCase() === data.label.toLowerCase()
      );
      setDataCopy(filterResult);
    } else {
      setDataCopy(regionLevelData.data);
    }

    const updateLettersData = lettersData.map((letter: any) => {
      if (data.id === letter.id) {
        return {
          ...letter,
          selected: !letter.selected,
        };
      }
      return {
        ...letter,
        selected: false,
      };
    });
    setLettersData(updateLettersData);
  };

  const handlePageChange = (event: any, page: any) => {
    // console.log(event);
    // TODO: leave event for now, as function is expecting 2 parameters
    setPageNumber(page - 1);
  };

  const handleGoBack = () => {
    dispatch(setVisiblePanel('main-panel'));
    dispatch(setRegionLevelData({ region: '', OBJECTID: null, data: [] }));
    dispatch(setCountryLevelData({ country: '', countryISO3: '', data: [] }));
    dispatch(setSelectedCountryOrRegion(null));
    mapController.resetMapView();
  };

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    if (regionLevelData.region) {
      setDataCopy(regionLevelData.data);
      getData();
    }
  }, [regionLevelData.region, regionLevelData.data, getData]);

  useEffect(() => {
    if (regionLevelData.region) {
      handlePagesCount();
    }
  }, [regionLevelData.region, pageNumber, handlePagesCount]);

  return (
    <div className='SidePanel SidePanelRegion'>
      <div className='side-panel-header'>
        <div className='return-btn-wrapper'>
          <div className='return-to-results' onClick={handleGoBack}>
            <span>
              <AiFillCaretLeft />
            </span>
            <h4>{selectedLanguage['Return to Results']}</h4>
          </div>
        </div>
        <h4 className='country-name'>{regionLevelData.region}</h4>
        <div className='border-bottom'></div>
      </div>
      <div className='auto-height country-level-height'>
        <RegionStatsDropdown
          handleExpandedFilter={handleExpandedFilter}
          expanedFilters={expanedFilters}
          statsData={statsData}
          chartData={chartsData}
          visibleChart={visibleChartRegion}
          countryName={regionLevelData.region}
        />

        <div className='side-panel-filters'>
          {lettersData.map((letter: any, index: number) => (
            <button
              disabled={!regionLevelData.data}
              className={letter.selected ? 'selected' : ''}
              onClick={() => handleFilterByLetter(letter)}
              key={index}
            >
              {letter.label}
            </button>
          ))}
        </div>
        <ShowRecordsComponent data={dataCopy} handleRecordSelection={handleRecordSelection} />
      </div>
      <div>
        <Pagination
          classes={{ root: 'pagination-wrapper' }}
          onChange={handlePageChange}
          count={pageCount}
          variant='outlined'
          shape='rounded'
        />
      </div>
    </div>
  );
};

export default SidePanelRegion;
