import Skeleton from 'react-loading-skeleton';
import { SkeletonConfigTypes } from '../../utils/types';
import './skeletonLoader.scss';

interface Props {
  data: SkeletonConfigTypes[];
}

const SkeletonLoader = ({ data }: Props) => {
  return (
    <div className='SkeletonLoader'>
      {data.map((skeleton, index) => (
        <div key={index} style={skeleton.style ? skeleton.style : {}}>
          <Skeleton circle={skeleton.circle} count={skeleton.count} height={skeleton.height} />
        </div>
      ))}
    </div>
  );
};

export default SkeletonLoader;
