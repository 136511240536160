import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import './styles/index.scss';
import { useSelector } from 'react-redux';
import { languageSelector } from '../../store/slices/appSlice';
import { TRANSLATIONS } from '../../translations/translations';
require('highcharts/modules/no-data-to-display')(Highcharts);

interface Props {
  data: { categories: string[]; data: any[] };
}

const ColumnChart = ({ data }: Props) => {
  const language = useSelector(languageSelector);
  const selectedLanguage = TRANSLATIONS[language];

  const options = {
    title: {
      text: '',
    },
    chart: {
      height: 320,
      type: 'column',
    },
    legend: {
      enabled: false,
    },
    yAxis: {
      min: 0,
      max: 100,
      title: {
        text: selectedLanguage['% Contribution to Project'],
      },
      labels: {
        enabled: false,
      },
    },
    tooltip: {
      formatter: function (this: any) {
        return `${selectedLanguage['Percent']}: <b>${this.y.toFixed(2)}</b>`;
      },
    },
    xAxis: {
      categories: data.categories,
      labels: {
        enabled: false,
      },
      title: {
        text: selectedLanguage['Threat Type'],
      },
    },
    series: data.data,
    credits: {
      text: '',
    },
    lang: {
      noData: selectedLanguage['No data available'],
    },
    noData: {
      style: {
        fontSize: '20px',
        fontWeight: 'bold',
      },
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        dataLabels: {
          enabled: true,
          crop: false,
          padding: 0,
          overflow: 'allow',
          formatter: function (this: any) {
            // return data label instead of percentage value
            return this.series.userOptions.name;
          },
          style: {
            fontWeight: 200,
            fontSize: '10px',
            color: '#333',
            borderColor: '#333',
          },
        },
      },
    },
  };
  return (
    <div className='chart-wrapper'>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default ColumnChart;
