import { BsFillQuestionCircleFill } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { globalChartContent } from '../../configs/globalModalContent';
import { languageSelector, setIsGlobalModalOpen, setGlobalModalContent } from '../../store/slices/appSlice';
import { TRANSLATIONS } from '../../translations/translations';
import './styles/charttooltip.scss';

export type ChartTypes = 'Conservation' | 'Restoration' | 'Climate change';

interface Props {
  chartType: ChartTypes;
}

const ChartTooltip = ({ chartType }: Props) => {
  const language = useSelector(languageSelector);
  const selectedLanguage = TRANSLATIONS[language];
  const dispatch = useDispatch();

  const handleGlobalModal = (chartType: ChartTypes) => {
    const getModalContent = globalChartContent(selectedLanguage);
    const content = getModalContent[chartType];
    dispatch(setIsGlobalModalOpen(true));
    dispatch(setGlobalModalContent(content));
  };
  return (
    <>
      <span role='button' className='tooltip-btn' onClick={() => handleGlobalModal(chartType)}>
        <BsFillQuestionCircleFill />
      </span>
    </>
  );
};

export default ChartTooltip;
