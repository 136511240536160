import { useDispatch, useSelector } from 'react-redux';
import { TRANSLATIONS } from '../../translations/translations';
import { languageSelector, setVisiblewindow } from '../../store/slices/appSlice';
import { IUCN_EMAIL } from '../../configs/iucnConfig';
import './styles/maintanancePage.scss';

const MaintenancePage = () => {
  const language = useSelector(languageSelector);
  const selectedLanguage = TRANSLATIONS[language];
  const dispatch = useDispatch();

  return (
    <div className='maintenance-page'>
      <div className='message-container'>
        <p>
          {
            selectedLanguage[
              'The data underlying the IUCN Contributions for Nature Platform are currently being updated. Please check back soon for updated results. Please contact'
            ]
          }{' '}
          <a href='mailto:contributionsplatform@iucn.org'>{IUCN_EMAIL}</a>{' '}
          {selectedLanguage['if you have any questions.']}
        </p>
        <div className='btn-container'>
          <button onClick={() => dispatch(setVisiblewindow('homepage'))}>{selectedLanguage['Go Back']}</button>
        </div>
      </div>
    </div>
  );
};

export default MaintenancePage;
