export const documentList = (selectedLanguage: any) => {
  return [
    {
      id: 1,
      label: selectedLanguage['Data Entry Module - Guidance'],
      url: 'https://www.iucncontributionsfornature.org/documents/Guidance-Data%20Entry%20Module%20of%20IUCN%20Contributions%20for%20Nature%20platform.pdf',
    },
    {
      id: 2,
      label: selectedLanguage['Validation Module - Guidance'],
      url: 'https://www.iucncontributionsfornature.org/documents/Guidance-Validation%20Module%20of%20IUCN%20Contributions%20for%20Nature%20platform.pdf',
    },
    {
      id: 3,
      label: selectedLanguage['Frequently Asked Questions (FAQs)'],
      url: 'https://iucncontributionsfornature.org/documents/FAQs_document_en.pdf',
    },
    {
      id: 4,
      label: selectedLanguage['Terms and Conditions'],
      url: 'https://www.iucncontributionsfornature.org/documents/Contributions%20for%20Nature%20User%20Agreement.pdf',
    },
  ];
};
