import ScaleLoader from 'react-spinners/ScaleLoader';
import './index.scss';

const Loader = () => {
  return (
    <div className='loader'>
      <ScaleLoader loading={true} color={'#037fb0'} width={30} height={45} />
    </div>
  );
};

export default Loader;
