import { headerDescriptionBuilder } from '../configs/chartsConfig';
import { GLOBAL_VALUES } from '../configs/globalValues';
import { getRemainingValue, removeDecimalsFromNumber } from './helperMethods';
import { GlobalAggregateTypes } from './types';

export const globalChartPiesBuilder = (aggregateData: GlobalAggregateTypes[], selectedLanguage: any) => {
  const aggregateValues = aggregateData[0];
  const { STAR_RESTORATION, THREAT_ABATEMENT, CLIMATE_MITIGATION } = GLOBAL_VALUES;

  const globalRTotal = [
    {
      name: selectedLanguage['Star Restoration'],
      data: [
        {
          name: selectedLanguage['Global'],
          y: getRemainingValue(aggregateValues?.aggregate_score_R_total, STAR_RESTORATION).y,
          rawValue: getRemainingValue(aggregateValues?.aggregate_score_R_total, STAR_RESTORATION).rawValue,
        },
        {
          name: selectedLanguage['Contribution'],
          y: removeDecimalsFromNumber(aggregateValues?.aggregate_score_R_total),
        },
      ],
    },
  ];

  const globalTATotal = [
    {
      name: selectedLanguage['Threat Abatement'],
      data: [
        {
          name: selectedLanguage['Global'],
          y: getRemainingValue(aggregateValues?.aggregate_score_TA_total, THREAT_ABATEMENT).y,
          rawValue: getRemainingValue(aggregateValues?.aggregate_score_TA_total, THREAT_ABATEMENT).rawValue,
        },
        {
          name: selectedLanguage['Contribution'],
          y: removeDecimalsFromNumber(aggregateValues?.aggregate_score_TA_total),
          sliced: true,
          selected: true,
        },
      ],
    },
  ];

  const globalCarbonTotal = [
    {
      name: selectedLanguage['Climate Mitigation'],
      data: [
        {
          name: selectedLanguage['Global'],
          y: getRemainingValue(aggregateValues?.aggregate_carbon_total, CLIMATE_MITIGATION).y,
          rawValue: getRemainingValue(aggregateValues?.aggregate_carbon_total, CLIMATE_MITIGATION).rawValue,
        },
        {
          name: selectedLanguage['Contribution'],
          y: removeDecimalsFromNumber(aggregateValues?.aggregate_carbon_total),
        },
      ],
    },
  ];
  return {
    globalTATotal,
    globalRTotal,
    globalCarbonTotal,
  };
};

export const contributionToCountryData = (params: any, selectedLanguage: any) => {
  const { contributionData, countryData } = params;
  const { countryCarbonLookup, countryStarLookup, country } = countryData;

  const contributionCountryRestoration = [
    {
      name: selectedLanguage['Restoration'],
      data: [
        {
          name: selectedLanguage['Contribution total'],
          y: removeDecimalsFromNumber(contributionData[0]?.starValueR),
        },
        {
          name: `${country} ${selectedLanguage['total']}`,
          y: getRemainingValue(contributionData[0]?.starValueR, countryStarLookup[0]?.country_R_total).y,
          rawValue: getRemainingValue(contributionData[0]?.starValueR, countryStarLookup[0]?.country_R_total).rawValue,
        },
      ],
    },
  ];

  const contributionCountryTA = [
    {
      name: selectedLanguage['Threat Abatement'],
      data: [
        {
          name: selectedLanguage['Contribution total'],
          y: removeDecimalsFromNumber(contributionData[0]?.starValueTA),
        },
        {
          name: `${country} ${selectedLanguage['total']}`,
          y: getRemainingValue(contributionData[0]?.starValueTA, countryStarLookup[0]?.country_TA_total).y,
          rawValue: getRemainingValue(contributionData[0]?.starValueTA, countryStarLookup[0]?.country_TA_total)
            .rawValue,

          sliced: true,
          selected: true,
        },
      ],
    },
  ];

  const contributionCountryCarbon = [
    {
      name: selectedLanguage['Contribution to Land region Climate Mitigation'],
      data: [
        {
          name: selectedLanguage['Contribution total'],
          y: removeDecimalsFromNumber(contributionData[0]?.carbonValue),
        },

        {
          name: `${country} ${selectedLanguage['total']}`,
          y: getRemainingValue(contributionData[0]?.carbonValue, countryCarbonLookup[0]?.country_carbon_total).y,
          rawValue: getRemainingValue(contributionData[0]?.carbonValue, countryCarbonLookup[0]?.country_carbon_total)
            .rawValue,
        },
      ],
    },
  ];
  return {
    contributionCountryRestoration,
    contributionCountryTA,
    contributionCountryCarbon,
  };
};

export const countryToRegionData = (params: any, selectedLanguage: any) => {
  const { countryData, regionData } = params;
  const { countryCarbonLookup, countryStarLookup, country } = countryData;
  const { regionCarbonResponse, regionRestorationResponse, regionTAResponse, region } = regionData;

  const countryRegionRestoration = [
    {
      name: selectedLanguage['Restoration'],
      data: [
        {
          name: `${country} ${selectedLanguage['total']}`,
          y: removeDecimalsFromNumber(countryStarLookup[0]?.country_R_total),
        },
        {
          name: `${region} ${selectedLanguage['total']}`,
          y: getRemainingValue(
            countryStarLookup[0]?.country_R_total,
            regionRestorationResponse[0]?.region_Restoration_total
          ).y,
          rawValue: getRemainingValue(
            countryStarLookup[0]?.country_R_total,
            regionRestorationResponse[0]?.region_Restoration_total
          ).rawValue,
        },
      ],
    },
  ];

  const countryRegionTA = [
    {
      name: selectedLanguage['Threat Abatement'],
      data: [
        {
          name: `${country} ${selectedLanguage['total']}`,
          y: removeDecimalsFromNumber(countryStarLookup[0]?.country_TA_total),
        },
        {
          name: `${region} ${selectedLanguage['total']}`,
          y: getRemainingValue(countryStarLookup[0]?.country_TA_total, regionTAResponse[0]?.region_TA_total).y,
          rawValue: getRemainingValue(countryStarLookup[0]?.country_TA_total, regionTAResponse[0]?.region_TA_total)
            .rawValue,
          sliced: true,
          selected: true,
        },
      ],
    },
  ];

  const countryRegionCarbon = [
    {
      name: selectedLanguage['Land region to continent Climate Mitigation'],
      data: [
        {
          name: `${country} ${selectedLanguage['total']}`,
          y: removeDecimalsFromNumber(countryCarbonLookup[0]?.country_carbon_total),
        },

        {
          name: `${region} ${selectedLanguage['total']}`,
          y: getRemainingValue(
            countryCarbonLookup[0]?.country_carbon_total,
            regionCarbonResponse[0]?.region_carbon_total
          ).y,
          rawValue: getRemainingValue(
            countryCarbonLookup[0]?.country_carbon_total,
            regionCarbonResponse[0]?.region_carbon_total
          ).rawValue,
        },
      ],
    },
  ];
  return {
    countryRegionRestoration,
    countryRegionTA,
    countryRegionCarbon,
  };
};

export const regionGlobalData = (params: any, selectedLanguage: any) => {
  const { regionData, GLOBAL_VALUES } = params;
  const { STAR_RESTORATION, THREAT_ABATEMENT, CLIMATE_MITIGATION } = GLOBAL_VALUES;
  const { regionCarbonResponse, regionRestorationResponse, regionTAResponse, region } = regionData;

  const regionGlobalRestoration = [
    {
      name: selectedLanguage['Restoration'],
      data: [
        {
          name: `${region} ${selectedLanguage['total']}`,
          y: removeDecimalsFromNumber(regionRestorationResponse[0]?.region_Restoration_total),
        },
        {
          name: selectedLanguage['Global total'],
          y: getRemainingValue(regionRestorationResponse[0]?.region_Restoration_total, STAR_RESTORATION).y,
          rawValue: getRemainingValue(regionRestorationResponse[0]?.region_Restoration_total, STAR_RESTORATION)
            .rawValue,
        },
      ],
    },
  ];

  const regionGlobalTA = [
    {
      name: selectedLanguage['Threat Abatement'],
      data: [
        {
          name: `${region} ${selectedLanguage['total']}`,
          y: removeDecimalsFromNumber(regionTAResponse[0]?.region_TA_total),
        },
        {
          name: selectedLanguage['Global total'],
          y: getRemainingValue(regionTAResponse[0]?.region_TA_total, THREAT_ABATEMENT).y,
          rawValue: getRemainingValue(regionTAResponse[0]?.region_TA_total, THREAT_ABATEMENT).rawValue,
          sliced: true,
          selected: true,
        },
      ],
    },
  ];

  const regionGlobalCarbon = [
    {
      name: selectedLanguage['Continent to global Climate Mitigation'],
      data: [
        {
          name: `${region} ${selectedLanguage['total']}`,
          y: removeDecimalsFromNumber(regionCarbonResponse[0]?.region_carbon_total),
        },

        {
          name: selectedLanguage['Global total'],
          y: getRemainingValue(regionCarbonResponse[0]?.region_carbon_total, CLIMATE_MITIGATION).y,
          rawValue: getRemainingValue(regionCarbonResponse[0]?.region_carbon_total, CLIMATE_MITIGATION).rawValue,
        },
      ],
    },
  ];
  return {
    regionGlobalRestoration,
    regionGlobalTA,
    regionGlobalCarbon,
  };
};

export const contributionToRegionData = (params: any, selectedLanguage: any) => {
  const { contributionData, regionData } = params;
  const { regionCarbonResponse, regionTAResponse, regionRestorationResponse, region } = regionData;

  const contributionRegionRestoration = [
    {
      name: selectedLanguage['Restoration'],
      data: [
        {
          name: selectedLanguage['Contribution total'],
          y: removeDecimalsFromNumber(contributionData[0]?.starValueR),
        },
        {
          name: `${region} ${selectedLanguage['total']}`,
          y: getRemainingValue(contributionData[0]?.starValueR, regionRestorationResponse[0]?.region_Restoration_total)
            .y,
          rawValue: getRemainingValue(
            contributionData[0]?.starValueR,
            regionRestorationResponse[0]?.region_Restoration_total
          ).rawValue,
        },
      ],
    },
  ];

  const contributionRegionTA = [
    {
      name: selectedLanguage['Threat Abatement'],
      data: [
        {
          name: selectedLanguage['Contribution total'],
          y: removeDecimalsFromNumber(contributionData[0]?.starValueTA),
        },
        {
          name: `${region} ${selectedLanguage['total']}`,
          y: getRemainingValue(contributionData[0]?.starValueTA, regionTAResponse[0]?.region_TA_total).y,
          rawValue: getRemainingValue(contributionData[0]?.starValueTA, regionTAResponse[0]?.region_TA_total).rawValue,
          sliced: true,
          selected: true,
        },
      ],
    },
  ];

  const contributionRegionCarbon = [
    {
      name: selectedLanguage['Contribution to Land region Climate Mitigation'],
      data: [
        {
          name: selectedLanguage['Contribution total'],
          y: removeDecimalsFromNumber(contributionData[0]?.carbonValue),
        },

        {
          name: `${region} ${selectedLanguage['total']}`,
          y: getRemainingValue(contributionData[0]?.carbonValue, regionCarbonResponse[0]?.region_carbon_total).y,
          rawValue: getRemainingValue(contributionData[0]?.carbonValue, regionCarbonResponse[0]?.region_carbon_total)
            .rawValue,
        },
      ],
    },
  ];
  return {
    contributionRegionRestoration,
    contributionRegionTA,
    contributionRegionCarbon,
  };
};

export const contributionGlobalData = (params: any, selectedLanguage: any) => {
  const { GLOBAL_VALUES, contributionData } = params;
  const { STAR_RESTORATION, THREAT_ABATEMENT, CLIMATE_MITIGATION } = GLOBAL_VALUES;

  const contributionGlobalRestoration = [
    {
      name: selectedLanguage['Restoration'],
      data: [
        {
          name: selectedLanguage['Contribution total'],
          y: removeDecimalsFromNumber(contributionData[0]?.starValueR),
        },
        {
          name: selectedLanguage['Global total'],
          y: getRemainingValue(contributionData[0]?.starValueR, STAR_RESTORATION).y,
          rawValue: getRemainingValue(contributionData[0]?.starValueR, STAR_RESTORATION).rawValue,
        },
      ],
    },
  ];

  const contributionGlobalTA = [
    {
      name: selectedLanguage['Threat Abatement'],
      data: [
        {
          name: selectedLanguage['Contribution total'],
          y: removeDecimalsFromNumber(contributionData[0]?.starValueTA),
        },
        {
          name: selectedLanguage['Glboal total'],
          y: getRemainingValue(contributionData[0]?.starValueTA, THREAT_ABATEMENT).y,
          rawValue: getRemainingValue(contributionData[0]?.starValueTA, THREAT_ABATEMENT).rawValue,
          sliced: true,
          selected: true,
        },
      ],
    },
  ];

  const contributionGlobalCarbon = [
    {
      name: selectedLanguage['Continent to global Climate Mitigation'],
      data: [
        {
          name: selectedLanguage['Contribution total'],
          y: removeDecimalsFromNumber(contributionData[0]?.carbonValue),
        },

        {
          name: selectedLanguage['Global total'],
          y: getRemainingValue(contributionData[0]?.carbonValue, CLIMATE_MITIGATION).y,
          rawValue: getRemainingValue(contributionData[0]?.carbonValue, CLIMATE_MITIGATION).rawValue,
        },
      ],
    },
  ];
  return {
    contributionGlobalRestoration,
    contributionGlobalTA,
    contributionGlobalCarbon,
  };
};

export const threeChartDataBuilder = (params: any) => {
  const {
    contributionCountryChartData,
    countryRegionChartData,
    regionGlobalChartData,
    country,
    region,
    selectedLanguage,
  } = params;

  const headerText = headerDescriptionBuilder(3, { country, region }, selectedLanguage);

  const climateMitigation = {
    header: headerText.climateMitigation.header,
    subHeader: headerText.climateMitigation.subHeader,
    data: [
      ...contributionCountryChartData.contributionCountryCarbon,
      ...countryRegionChartData.countryRegionCarbon,
      ...regionGlobalChartData.regionGlobalCarbon,
    ],
  };

  const restoration = {
    header: headerText.restoration.header,
    subHeader: headerText.restoration.subHeader,
    data: [
      ...contributionCountryChartData.contributionCountryRestoration,
      ...countryRegionChartData.countryRegionRestoration,
      ...regionGlobalChartData.regionGlobalRestoration,
    ],
  };
  const threatAbatement = {
    header: headerText.threatAbatement.header,
    subHeader: headerText.threatAbatement.subHeader,
    data: [
      ...contributionCountryChartData.contributionCountryTA,
      ...countryRegionChartData.countryRegionTA,
      ...regionGlobalChartData.regionGlobalTA,
    ],
  };

  return {
    climateMitigation,
    restoration,
    threatAbatement,
  };
};

export const twoChartDataBuilder = (params: any) => {
  const { contributionRegionChartData, regionGlobalChartData, selectedLanguage } = params;

  const headerText = headerDescriptionBuilder(2, { country: '', region: '' }, selectedLanguage);

  const climateMitigation = {
    header: headerText.climateMitigation.header,
    subHeader: headerText.climateMitigation.subHeader,
    data: [...contributionRegionChartData.contributionRegionCarbon, ...regionGlobalChartData.regionGlobalCarbon],
  };

  const restoration = {
    header: headerText.restoration.header,
    subHeader: headerText.restoration.subHeader,
    data: [
      ...contributionRegionChartData.contributionRegionRestoration,
      ...regionGlobalChartData.regionGlobalRestoration,
    ],
  };
  const threatAbatement = {
    header: headerText.threatAbatement.header,
    subHeader: headerText.threatAbatement.subHeader,
    data: [...contributionRegionChartData.contributionRegionTA, ...regionGlobalChartData.regionGlobalTA],
  };

  return {
    climateMitigation,
    restoration,
    threatAbatement,
  };
};

export const oneChartDataBuilder = (params: any) => {
  const { contributionGlobalChartData, selectedLanguage } = params;
  const headerText = headerDescriptionBuilder(1, { country: '', region: '' }, selectedLanguage);

  const climateMitigation = {
    header: headerText.climateMitigation.header,
    subHeader: headerText.climateMitigation.subHeader,
    data: [...contributionGlobalChartData.contributionGlobalCarbon],
  };

  const restoration = {
    header: headerText.restoration.header,
    subHeader: headerText.restoration.subHeader,
    data: [...contributionGlobalChartData.contributionGlobalRestoration],
  };
  const threatAbatement = {
    header: headerText.threatAbatement.header,
    subHeader: headerText.threatAbatement.subHeader,
    data: [...contributionGlobalChartData.contributionGlobalTA],
  };

  return {
    climateMitigation,
    restoration,
    threatAbatement,
  };
};
