import { useSelector } from 'react-redux';
import { languageSelector } from '../../store/slices/appSlice';
import { TRANSLATIONS } from '../../translations/translations';

import './styles/homeHeader.scss';

const HomeHeader = () => {
  const language = useSelector(languageSelector);
  const selectedLanguage = TRANSLATIONS[language];

  return (
    <header className='home-header'>
      <div className='header-wrapper'>
        <h1 className='header-description'>
          <span>{selectedLanguage['Welcome to the IUCN']}</span>
          {selectedLanguage['Contributions for Nature Platform']}
        </h1>
      </div>
    </header>
  );
};

export default HomeHeader;
