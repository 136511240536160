import { useSelector, useDispatch } from 'react-redux';
import { mapController } from '../../controllers/MapController';
import { userDataSelector, setUserContributions, languageSelector } from '../../store/slices/appSlice';
import { setSelectedContribution } from '../../store/slices/mapSlice';
import {
  orgsInContributionListSelector,
  setSelectedOrganizationName,
  setOrgsCount,
  setVisiblePanel,
} from '../../store/slices/sidePanelSlice';
import { TRANSLATIONS } from '../../translations/translations';
import './styles/displayAllOrgs.scss';

const DisplayAllOrgs = () => {
  const userData = useSelector(userDataSelector);
  const orgsInContributionList = useSelector(orgsInContributionListSelector);
  const dispatch = useDispatch();
  const language = useSelector(languageSelector);
  const selectedLanguage = TRANSLATIONS[language];

  const handleSelectOrg = (org: string) => {
    const selectedOrg = userData.find((item) => item.organization === org);

    if (selectedOrg && selectedOrg?.organization) {
      let where = `ownerOrgs IS NOT NULL AND beneficiaryCountries IS NOT NULL AND ownerOrgs LIKE '%${org}%'`;

      if (selectedOrg?.partnerOrgs) {
        const partnerOrgs = selectedOrg?.partnerOrgs;
        where += ` OR partnerOrgs LIKE '%${partnerOrgs}%'`;
      }
      dispatch(setSelectedOrganizationName(org));
      dispatch(setUserContributions(selectedOrg));
      dispatch(setOrgsCount(selectedOrg.orgCount));
      dispatch(setVisiblePanel('organization-panel'));
      dispatch(setSelectedContribution(null));

      const params = {
        page: 0,
        count: 10,
        where,
        outFields: ['*'],
        orderByFields: ['name'],
        keepPrevious: false,
      };

      mapController.closePopup();
      mapController.queryOrgsByPage(params);
      mapController.queryMapExtent(`${where} AND ownerOrgs LIKE '%${selectedOrg.organization}%'`);
      mapController.filterLayer(`ownerOrgs LIKE '%${selectedOrg.organization}%' AND ${where}`);
    }
  };

  return (
    <div className='DisplayAllOrgs'>
      <div className='display-all-orgs-header'>
        <h3>{selectedLanguage['Partner(s)']}</h3>
      </div>

      <div className='orgs-wrapper'>
        {!!orgsInContributionList.length &&
          orgsInContributionList.map((org, index) => (
            <div className='org' key={index}>
              <button onClick={() => handleSelectOrg(org.label)}>{org.label}</button>
            </div>
          ))}
      </div>
    </div>
  );
};

export default DisplayAllOrgs;
