import SmoothCollapse from 'react-smooth-collapse';
import { TiArrowSortedDown, TiArrowSortedUp } from 'react-icons/ti';
import './styles/memberTypeDropdown.scss';
import { useSelector } from 'react-redux';
import { languageSelector } from '../../store/slices/appSlice';
import { TRANSLATIONS } from '../../translations/translations';

interface Props {
  handleExpandedFilter: (value: any) => void;
  expanedFilters: boolean;
  memberTypes: any;
  handleContributionsFilter: (value: any) => void;
}
const MemberTypeDropdown = ({
  handleExpandedFilter,
  expanedFilters,
  memberTypes,
  handleContributionsFilter,
}: Props) => {
  const language = useSelector(languageSelector);
  const selectedLanguage = TRANSLATIONS[language];

  return (
    <div className='filter-dropdown'>
      <div className='toggle-btn-wrapper'>
        <button onClick={handleExpandedFilter}>
          {selectedLanguage['Filter by IUCN constituent type']}
          <span>{expanedFilters ? <TiArrowSortedUp /> : <TiArrowSortedDown />}</span>
        </button>
      </div>
      <div className='collapse-wrapper'>
        <SmoothCollapse expanded={expanedFilters}>
          <div className='added-contributions'>
            {!!memberTypes.length &&
              memberTypes.map((filter: any) => (
                <label key={filter.label} className={filter.checked ? 'contribution-selected' : ''}>
                  <input
                    type='checkbox'
                    defaultChecked={filter.checked}
                    name={filter.label}
                    id={filter.label}
                    value={filter.label}
                    onChange={handleContributionsFilter}
                  />
                  <span>{filter.label}</span>
                </label>
              ))}
          </div>
        </SmoothCollapse>
      </div>
    </div>
  );
};

export default MemberTypeDropdown;
