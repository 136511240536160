import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { urlParamBuilder } from '../publicApp/sidePanels/helpers/utils';
import {
  mapLoadedSelector,
  selectedContributionSelector,
  selectedMapLayersSelector,
  strMapExtentSelector,
} from '../../store/slices/mapSlice';
import { countryLevelDataSelector, languageSelector, regionLevelDataSelector } from '../../store/slices/appSlice';
import { selectedOrganizationNameSelector } from '../../store/slices/sidePanelSlice';
import {
  visibleChartCountrySelector,
  visibleChartOrgSelector,
  visibleChartPanelSelector,
  visibleChartRegionSelector,
} from '../../store/slices/chartSlice';

const useUrlParamsBuilder = () => {
  const selectedMapLayers = useSelector(selectedMapLayersSelector);
  const selectedContribution = useSelector(selectedContributionSelector);
  const regionLevelData = useSelector(regionLevelDataSelector);
  const countryLevelData = useSelector(countryLevelDataSelector);
  const selectedOrganizationName = useSelector(selectedOrganizationNameSelector);
  const visibleContributionChart = useSelector(visibleChartPanelSelector);
  const visibleChartOrg = useSelector(visibleChartOrgSelector);
  const visibleChartCountry = useSelector(visibleChartCountrySelector);
  const visibleChartRegion = useSelector(visibleChartRegionSelector);
  const strMapExtent = useSelector(strMapExtentSelector);
  const mapLoaded = useSelector(mapLoadedSelector);
  const language = useSelector(languageSelector);

  useEffect(() => {
    if (mapLoaded) {
      urlParamBuilder();
    }
  }, [
    mapLoaded,
    selectedMapLayers,
    selectedContribution,
    regionLevelData,
    countryLevelData,
    selectedOrganizationName,
    language,
    visibleContributionChart,
    visibleChartOrg,
    visibleChartCountry,
    visibleChartRegion,
    strMapExtent,
  ]);

  return null;
};

export default useUrlParamsBuilder;
