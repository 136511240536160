import SmoothCollapse from 'react-smooth-collapse';
import { TiArrowSortedDown, TiArrowSortedUp } from 'react-icons/ti';
import GlobalLevelPieChart from '../charts/GlobalLevelPieChart';
import './styles/globalStatsDropdown.scss';
import { useSelector } from 'react-redux';
import { languageSelector } from '../../store/slices/appSlice';
import { TRANSLATIONS } from '../../translations/translations';
import ChartTooltip from '../tooltipComponent/ChartTooltiop';

interface Props {
  globalCarbonValue?: number;
  globalExpanded: boolean;
  handleGlobalExpanded: () => void;
  data: any;
}

const GlobalStatsDropdown = ({ globalExpanded, handleGlobalExpanded, data }: Props) => {
  const language = useSelector(languageSelector);
  const selectedLanguage = TRANSLATIONS[language];

  return (
    <div className={!globalExpanded ? 'remove-height global-dropdown' : 'global-dropdown'}>
      <div className='toggle-btn-wrapper'>
        <button onClick={handleGlobalExpanded}>
          {selectedLanguage['Quick Global Stats']}
          <div className='left-side'>
            <span className='text'>
              {globalExpanded ? selectedLanguage['Close Statistics'] : selectedLanguage['Expand Statistics']}
            </span>
            <span className='arrow-icon'>{globalExpanded ? <TiArrowSortedUp /> : <TiArrowSortedDown />}</span>
          </div>
        </button>
      </div>
      <div className='collapse-wrapper'>
        <SmoothCollapse expanded={globalExpanded}>
          <div className='stats-wrapper'>
            <div className='single-stats-wrapper'>
              <div className='description-wrapper'></div>
              <div className='chart'>
                <div className='chart-header'>
                  <p>
                    {selectedLanguage['Conservation contribution to biodiversity']}
                    <ChartTooltip chartType='Conservation' />
                  </p>
                </div>
                <GlobalLevelPieChart data={data.globalTATotal} />
              </div>
            </div>
            <div className='single-stats-wrapper'>
              <div className='description-wrapper'></div>
              <div className='chart'>
                <div className='chart-header'>
                  <p>
                    {selectedLanguage['Restoration contribution to biodiversity']}
                    <ChartTooltip chartType='Restoration' />
                  </p>
                </div>
                <GlobalLevelPieChart data={data.globalRTotal} />
              </div>
            </div>
            <div className='single-stats-wrapper'>
              <div className='description-wrapper'></div>
              <div className='chart'>
                <div className='chart-header'>
                  <p>
                    {selectedLanguage['Restoration contribution to climate change mitigation']}
                    <ChartTooltip chartType='Climate change' />
                  </p>
                </div>
                <GlobalLevelPieChart data={data.globalCarbonTotal} />
              </div>
            </div>
          </div>
        </SmoothCollapse>
      </div>
    </div>
  );
};

export default GlobalStatsDropdown;
