import { MdPictureAsPdf } from 'react-icons/md';
import { AttachmentTypes } from '../../store/slices/sidePanelSlice';

interface Props {
  data: AttachmentTypes[];
}
const DisplayAttachments = ({ data }: Props) => {
  return (
    <div className='attachments-wrapper'>
      {!!data.length &&
        data.map((item) => {
          return (
            <div className='attachment' key={item.url}>
              {item.type.includes('image') && (
                <>
                  <a href={item.url} target='_blank' rel='noreferrer'>
                    <img src={item.url} alt='attachment' />
                  </a>
                </>
              )}

              {item.type.includes('pdf') && (
                <div className='docs-wrapper'>
                  <a className='pdf' href={item.url} target='_blank' rel='noreferrer'>
                    <span>
                      <MdPictureAsPdf />{' '}
                    </span>
                  </a>
                </div>
              )}
            </div>
          );
        })}
    </div>
  );
};

export default DisplayAttachments;
