const IUCN_SERVICE_URL = 'https://services9.arcgis.com/7mjlWnKuScfOfGFP/ArcGIS/rest/services';

export const PROD_ENDPOINTS = {
  API: 'https://uizmyijf52.us-east-1.awsapprunner.com/v1',
  CALCULATOR_API: 'https://q3m4tq8emq.us-east-1.awsapprunner.com/v1',
  ORG_EMAIL: `${IUCN_SERVICE_URL}/organization_metadata_prod/FeatureServer/0`,
  WORLD_MAP_LAYER: `${IUCN_SERVICE_URL}/IUCN_Countries_with_regions/FeatureServer`,
  WORLD_COUNTRY_LOOKUP: `${IUCN_SERVICE_URL}/IUCN_Countries_with_regions/FeatureServer/18`,
  APPROVED_CONTRIBUTIONS_LAYER: `${IUCN_SERVICE_URL}/Contributions_Data_Prod_view/FeatureServer/0`,
  REGION_LAYER: `${IUCN_SERVICE_URL}/CfN_Regions_map/FeatureServer/13`,
  KBA_POLYGON_LAYER: `${IUCN_SERVICE_URL}/KBA_Polygon_and_Point/FeatureServer/0`,
  WDPA_LAYER:
    'https://data-gis.unep-wcmc.org/server/rest/services/ProtectedSites/The_World_Database_of_Protected_Areas/MapServer/1',
  SEARCH_COUNTRY_LAYER: `https://services.arcgis.com/P3ePLMYs2RVChkJx/ArcGIS/rest/services/World_Countries_(Generalized)/FeatureServer/0`,
  ORG_AGGREGATIONS: `${IUCN_SERVICE_URL}/cfn_organization_aggregations_prod/FeatureServer/0`,
  STAR_LOOKUP: `${IUCN_SERVICE_URL}/STARLookupTable/FeatureServer/11`,
  CFN_AGGREGATIONS: `${IUCN_SERVICE_URL}/CfN_admin_bdy_aggregations_prod/FeatureServer/0`,
  CARBON_COUNTRY_LOOKUP: `${IUCN_SERVICE_URL}/CarbonLookupCountry/FeatureServer/14`,
  REGION_TA_LOOKUP: `${IUCN_SERVICE_URL}/STAR_Lookup_Region_TA/FeatureServer/17`,
  REGION_RESTORATION_LOOKUP: `${IUCN_SERVICE_URL}/STAR_Lookup_Region_R/FeatureServer/16`,
  REGION_CARBON_LOOKUP: `${IUCN_SERVICE_URL}/Carbon_Lookup_Regions/FeatureServer/15`,
  IUCN_TOOL_URL: 'https://www.iucncontributionsfornature.org/entry/',
};

export const DEV_ENDPOINTS = {
  API: 'https://emvnv87cpx.us-east-1.awsapprunner.com/v1',

  CALCULATOR_API: 'https://3agny2j2kh.us-east-1.awsapprunner.com/v1',
  ORG_EMAIL: `${IUCN_SERVICE_URL}/organization-email/FeatureServer/0`,
  WORLD_MAP_LAYER: `${IUCN_SERVICE_URL}/IUCN_Countries_with_regions/FeatureServer`,
  WORLD_COUNTRY_LOOKUP: `${IUCN_SERVICE_URL}/IUCN_Countries_with_regions/FeatureServer/18`,
  // APPROVED_CONTRIBUTIONS_LAYER: `${IUCN_SERVICE_URL}/Contributions_For_Nature_All_Data_DEV4/FeatureServer/0`,
  APPROVED_CONTRIBUTIONS_LAYER: `${IUCN_SERVICE_URL}/Contributions_For_Nature_All_Data_DEV4_2_view/FeatureServer/0`,
  REGION_LAYER: `${IUCN_SERVICE_URL}/CfN_Regions_map/FeatureServer/13`,
  KBA_POLYGON_LAYER: `${IUCN_SERVICE_URL}/KBA_Polygon_and_Point/FeatureServer/0`,
  WDPA_LAYER:
    'https://data-gis.unep-wcmc.org/server/rest/services/ProtectedSites/The_World_Database_of_Protected_Areas/MapServer/1',
  SEARCH_COUNTRY_LAYER: `https://services.arcgis.com/P3ePLMYs2RVChkJx/ArcGIS/rest/services/World_Countries_(Generalized)/FeatureServer/0`,
  ORG_AGGREGATIONS: `${IUCN_SERVICE_URL}/cfn-organization-aggregations/FeatureServer/0`,
  STAR_LOOKUP: `${IUCN_SERVICE_URL}/STARLookupTable/FeatureServer/11`,
  CFN_AGGREGATIONS: `${IUCN_SERVICE_URL}/CfN_Aggregations/FeatureServer/6`,
  CARBON_COUNTRY_LOOKUP: `${IUCN_SERVICE_URL}/CarbonLookupCountry/FeatureServer/14`,
  REGION_TA_LOOKUP: `${IUCN_SERVICE_URL}/STAR_Lookup_Region_TA/FeatureServer/17`,
  REGION_RESTORATION_LOOKUP: `${IUCN_SERVICE_URL}/STAR_Lookup_Region_R/FeatureServer/16`,
  REGION_CARBON_LOOKUP: `${IUCN_SERVICE_URL}/Carbon_Lookup_Regions/FeatureServer/15`,
  IUCN_TOOL_URL: 'https://alpha.blueraster.io/iucn-contributions-for-nature/staging/',
};

export const ENDPOINTS = process.env.REACT_APP_NODE_ENV === 'development' ? DEV_ENDPOINTS : PROD_ENDPOINTS;
