import { useState } from 'react';
import './header.scss';
import { useDispatch, useSelector } from 'react-redux';
import IUCNLogoEN from '../../assets/IUCN_logo_white.webp';
import IUCNLogoES from '../../assets/IUCN_logo_es.jpeg';
import IUCNLogoFR from '../../assets/IUCN_logo_fr.jpeg';
import { languageSelector, setVisiblewindow } from '../../store/slices/appSlice';
import { setVisiblePanel } from '../../store/slices/sidePanelSlice';
import LangaugeSwitcher from '../LanguageSwitcher';
import config from '../../configs/config';
import { TRANSLATIONS } from '../../translations/translations';
import SmoothCollapse from 'react-smooth-collapse';
import { useClipboard } from '../customHooks/useClipboard';
import { IoShareSocial, IoCheckmark } from 'react-icons/io5';

const Header = () => {
  const dispatch = useDispatch();
  const language = useSelector(languageSelector);
  const [expandDocuments, setExpandDocuments] = useState(false);
  const clipboard = useClipboard({ timeout: 3000 });

  const selectedLanguage = TRANSLATIONS[language];
  const DOCUMENTS_LIST = [
    {
      id: 1,
      label: selectedLanguage['Frequently Asked Questions (FAQs)'],
      url: 'https://iucncontributionsfornature.org/documents/FAQs_document_en.pdf',
    },
    {
      id: 2,
      label: selectedLanguage['Terms and Conditions'],
      url: 'https://www.iucncontributionsfornature.org/documents/Contributions%20for%20Nature%20User%20Agreement.pdf',
    },
  ];

  const renderLogo = () => {
    if (language === 'en') {
      return IUCNLogoEN;
    } else if (language === 'es') {
      return IUCNLogoES;
    } else {
      return IUCNLogoFR;
    }
  };

  const handleGoBackToHomepage = () => {
    dispatch(setVisiblewindow('homepage'));
    dispatch(setVisiblePanel('main-panel'));

    // reset url
    window.history.replaceState({}, '', `${window.location.origin}`);
  };

  const toggleDocumentDropdown = () => {
    setExpandDocuments(!expandDocuments);
  };

  return (
    <header className='Header'>
      <img src={renderLogo()} alt='IUCN logo' onClick={handleGoBackToHomepage} />
      {/* add login button at the header for the moment */}
      <div className='button-container'>
        <button className='doc-button' onClick={toggleDocumentDropdown}>
          Documents
        </button>

        <LangaugeSwitcher {...config.languageConfig} />
        <button
          type='button'
          className={clipboard.copied ? 'copied copy-link-button' : 'copy-link-button'}
          onClick={() => clipboard.copy(window.location.href)}
        >
          <span title={clipboard.copied ? selectedLanguage['Copied'] : selectedLanguage['Share url']}>
            {clipboard.copied ? <IoCheckmark size={15} /> : <IoShareSocial size={15} />}
          </span>
        </button>
      </div>

      <div className='smooth-dropdown-wrapper'>
        <SmoothCollapse expanded={expandDocuments}>
          <div className='expand-documents-container'>
            <div className='document-links-list'>
              {DOCUMENTS_LIST.map((item) => (
                <a key={item.id} target='_blank' rel='noreferrer' href={item.url}>
                  {item.label}
                </a>
              ))}
            </div>
          </div>
        </SmoothCollapse>
      </div>
    </header>
  );
};

export default Header;
