import { apiUrl, calculatorApiUrl, orgEmailUrl } from './apiUrl';
import { layersConfig, LAYER_IDS } from './layersConfig';
import { skeletonConfig } from './skeletonConfig';
import languageConfig from './languagesConfig';

const config = {
  apiUrl,
  calculatorApiUrl,
  orgEmailUrl,
  layersConfig,
  LAYER_IDS,
  skeletonConfig,
  languageConfig,
};
export default config;
