import { useSelector, useDispatch } from 'react-redux';
import {
  isContributionLoadingSelector,
  isCountryLoadingSelector,
  VisiblePanel,
  visiblePanelsSelector,
} from '../../store/slices/sidePanelSlice';
import SidepanelMain from '../publicApp/sidePanels/SidePanelMain';
import SidePanelOrganization from './sidePanels/SidePanelOrganization';
import SidePanelContribution from '../publicApp/sidePanels/SidePanelContribution';
import SidePanelCountry from './sidePanels/SidePanelCountry';
import config from '../../configs/config';
import SkeletonLoader from '../SkeletonLoader/SkeletonLoader';
import SidePanelRegion from './sidePanels/SidePanelRegion';
import { useEffect } from 'react';
import { getFeatures } from '../../utils/fetchData';
import { ENDPOINTS } from '../../configs/endpoints';
import { setCountryOptions } from '../../store/slices/mapSlice';
import useUrlParamsBuilder from '../customHooks/useUrlParamsBuilder';

const PublicApp = () => {
  const visiblePanel = useSelector(visiblePanelsSelector);
  const isCountryLoading = useSelector(isCountryLoadingSelector);
  const isContributionLoading = useSelector(isContributionLoadingSelector);

  const { skeletonConfig } = config;

  useUrlParamsBuilder();

  const dispatch = useDispatch();

  useEffect(() => {
    const queryParams = {
      where: 'ISO3 IS NOT NULL AND Display_Name IS NOT NULL',
      outFields: ['ISO3, Display_Name'],
      returnGeoemtry: false,
      returnDistinctValues: true,
    };

    const queryCountries = async () => {
      const response = await getFeatures({ queryParams, url: ENDPOINTS.WORLD_COUNTRY_LOOKUP });
      const countries = updateCountryData(response);
      dispatch(setCountryOptions(countries));
    };
    queryCountries();
  }, [dispatch]);

  function updateCountryData(data: { Display_Name: string; ISO3: string }[]) {
    return data.map((item) => {
      return {
        label: item.Display_Name,
        value: item.ISO3,
      };
    });
  }

  const handlePanelToDisplay = (panel: VisiblePanel) => {
    switch (panel) {
      case 'main-panel':
        return <SidepanelMain />;
      case 'organization-panel':
        return <SidePanelOrganization />;
      case 'contribution-panel':
        return (
          <>
            {!isContributionLoading && <SidePanelContribution />}
            {isContributionLoading && <SkeletonLoader data={skeletonConfig.countributionPanel} />}
          </>
        );
      case 'country-panel':
        return (
          <>
            {!isCountryLoading && <SidePanelCountry />}
            {isCountryLoading && <SkeletonLoader data={skeletonConfig.countryPanel} />}
          </>
        );
      case 'region-panel':
        return (
          <>
            {!isCountryLoading && <SidePanelRegion />}
            {isCountryLoading && <SkeletonLoader data={skeletonConfig.countryPanel} />}
          </>
        );
    }
  };
  return <div>{handlePanelToDisplay(visiblePanel)}</div>;
};

export default PublicApp;
