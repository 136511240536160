const HEADER_HEIGHT = 98.95;
const PANEL_PADDING = 64;
const windowHeight = window.innerHeight;

const height = windowHeight - HEADER_HEIGHT - PANEL_PADDING;

export const skeletonConfig = {
  mainPanel: [
    {
      style: { padding: '0 0 16px 0' },
      circle: false,
      count: 1,
      height: 260,
    },
    {
      style: null,
      circle: false,
      count: 1,
      height: height - 260 - 16,
    },
  ],
  countryPanel: [
    {
      style: { width: '25%' },
      circle: false,
      count: 1,
      height: 19,
    },
    {
      style: { padding: '40px 0 32px 0', width: '50%' },
      circle: true,
      count: 1,
      height: 63,
    },
    {
      style: { padding: '0 0 16px 0' },
      circle: false,
      count: 1,
      height: 63,
    },
    {
      style: { padding: '0 0 16px 0', borderRadius: '50%' },
      circle: true,
      count: 1,
      height: 32,
    },
    {
      style: null,
      circle: false,
      count: 1,
      height: height - 19 - 72 - 63 - 16 - 63 - 16 - 32,
    },
  ],
  countributionPanel: [
    {
      style: { width: '90%' },
      circle: false,
      count: 1,
      height: 19,
    },
    {
      style: { padding: '40px 0 32px 0', width: '50%' },
      circle: false,
      count: 1,
      height: 63,
    },
    {
      style: { margin: '32px 0' },
      circle: false,
      count: 1,
      height: 204,
    },
    {
      style: null,
      circle: false,
      count: 1,
      height: height - 19 - 72 - 63 - 64 - 204,
    },
  ],
};
