import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '@material-ui/lab/Pagination';
import { FiSearch } from 'react-icons/fi';
import {
  expandedFiltersSelector,
  globalExpandedSelector,
  setExpandedFilters,
  setGlobalExpanded,
  setOrgsCount,
  setSelectedOrganizationName,
  setVisiblePanel,
} from '../../../store/slices/sidePanelSlice';
import {
  languageSelector,
  memberTypesSelector,
  setMemberTypes,
  setUserContributions,
  userDataSelector,
} from '../../../store/slices/appSlice';
import { letters } from '../../../configs/lettersData';
import MemberTypeDropdown from '../../Dropdowns/MemberTypeDropdown';
import './styles/sidePanel.scss';
import GlobalStatsDropdown from '../../Dropdowns/GlobalStatsDropdown';
import { statisticsQueries } from '../../../configs/statisticsQueries';
import { queryDataStatistics } from '../../../utils/fetchData';
import { globalChartPiesBuilder } from '../../../utils/chartDataBuilder';
import { mapController } from '../../../controllers/MapController';
import ShowRecordsComponent from '../../showRecordsComponent/ShowRecordsComponent';
import TooltipComponent from '../../tooltipComponent/TooltipComponent';
import { TRANSLATIONS } from '../../../translations/translations';

const SidepanelMain = () => {
  const memberTypes = useSelector(memberTypesSelector);
  const userData = useSelector(userDataSelector);
  const [userDataCopy, setUserDataCopy] = useState<any>([]);
  const [currentFilters, setCurrentFilters] = useState<string[]>([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [lettersData, setLettersData] = useState(letters);
  const [globalPieChartsData, setGlobalPieChartsData] = useState<any>({});
  const globalExpanded = useSelector(globalExpandedSelector);
  const expandedFilters = useSelector(expandedFiltersSelector);
  const dispatch = useDispatch();
  const language = useSelector(languageSelector);
  const selectedLanguage = TRANSLATIONS[language];

  useEffect(() => {
    const fetchCarbonData = async () => {
      const { globalAggregate } = statisticsQueries;
      const globalAggregateData = await queryDataStatistics(globalAggregate);

      const pieChartsData = globalChartPiesBuilder(globalAggregateData, selectedLanguage);
      setGlobalPieChartsData(pieChartsData);
    };
    fetchCarbonData();
  }, [selectedLanguage]);

  useEffect(() => {
    if (!!currentFilters.length) {
      setUserDataCopy(currentFilters);
    }

    // this will only run once there are now filters on, show all data
    if (!currentFilters.length) {
      setUserDataCopy(userData);

      // if user is on a different page than the first one, then calculate what data to display depending on the pageNumber
      handlePagesCount();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFilters]);

  useEffect(() => {
    handlePagesCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData, pageNumber]);

  function handlePagesCount() {
    const usersPerPage = 10;
    const pagesVisited = pageNumber * usersPerPage;
    const displayUsers = userData.slice(pagesVisited, pagesVisited + usersPerPage);
    const pagesCount = Math.ceil(userData.length / usersPerPage);

    setPageCount(pagesCount);
    setUserDataCopy(displayUsers);
  }

  const handleGlobalExpanded = () => {
    dispatch(setGlobalExpanded(!globalExpanded));
  };

  const handleContributionsFilter = (event: any) => {
    handleDataManipulation(event);

    const updateFilters = memberTypes.map((fil: any) => {
      if (event.target.name === fil.label) {
        return {
          ...fil,
          checked: event.target.checked,
        };
      }

      return fil;
    });

    dispatch(setMemberTypes(updateFilters));
  };

  function handleDataManipulation(event: any) {
    let clickedFilters: any = [];

    if (event.target.checked) {
      userData.forEach((data: any) => {
        if (event.target.value === data.memberType) {
          clickedFilters.push(data);
        }
      });
      setCurrentFilters([...currentFilters, ...clickedFilters]);
    } else {
      const filtered = currentFilters.filter((data: any) => data.memberType !== event.target.value);
      setCurrentFilters(filtered);
    }
  }

  const handleToggleSerchBar = () => {};
  const handleExpandedFilter = () => {
    dispatch(setExpandedFilters(!expandedFilters));
  };

  const handleRecordSelection = (data: any) => {
    dispatch(setOrgsCount(data.orgCount));
    dispatch(setUserContributions(data));
    dispatch(setSelectedOrganizationName(data.organization));
    dispatch(setVisiblePanel('organization-panel'));

    let where = `beneficiaryCountries IS NOT NULL AND ownerOrgs LIKE '%${data.organization}%'`;

    if (data.partnerOrgs) {
      where += ` OR partnerOrgs LIKE '%${data.partnerOrgs}%'`;
    }

    mapController.queryMapExtent(where);
    mapController.filterLayer(where);

    const params = {
      page: 0,
      count: 10,
      where,
      outFields: ['*'],
      orderByFields: ['name'],
      keepPrevious: false,
    };
    mapController.queryOrgsByPage(params);
  };

  const handleSearchContribution = (event: any) => {
    const value = event.target.value;
    const filterResult = userData.filter((data: any) => data.organization.toLowerCase().includes(value.toLowerCase()));
    setUserDataCopy(filterResult);
  };

  const handleFilterByLetter = (data: any) => {
    if (!data.selected) {
      const filterResult = userData.filter(
        (userData: any) => userData.organization[0].toLowerCase() === data.label.toLowerCase()
      );
      setUserDataCopy(filterResult);
    } else {
      setUserDataCopy(userData);
    }

    const updateLettersData = lettersData.map((letter: any) => {
      if (data.id === letter.id) {
        return {
          ...letter,
          selected: !letter.selected,
        };
      }
      return {
        ...letter,
        selected: false,
      };
    });
    setLettersData(updateLettersData);
  };

  const handlePageChange = (event: any, page: any) => {
    console.log(event);
    // TODO: leave event for now, as function is expecting 2 parameters
    setPageNumber(page - 1);
  };

  return (
    <div className='SidePanel'>
      <GlobalStatsDropdown
        globalExpanded={globalExpanded}
        handleGlobalExpanded={handleGlobalExpanded}
        data={globalPieChartsData}
      />

      <div className='side-panel-container'>
        <div className='body-wrapper'>
          <div className='side-panel-header'>
            <div className='side-panel-header-wrapper'>
              <h4>{selectedLanguage['Search & Filter Contributions']}</h4>
              <div className='tooltip-wrapper'>
                <TooltipComponent
                  position='top'
                  description={selectedLanguage['You will only see filters for which we have data']}
                />
              </div>
            </div>

            <label htmlFor='search-contribution' className='search-icon-label'>
              <span className='search-icon' onClick={handleToggleSerchBar}>
                <FiSearch />
              </span>
              <input
                type='text'
                placeholder={selectedLanguage['Search Constituents']}
                onChange={handleSearchContribution}
              />
            </label>
          </div>
          <div className={globalExpanded ? 'global-expanded auto-height' : 'auto-height'}>
            <MemberTypeDropdown
              handleExpandedFilter={handleExpandedFilter}
              expanedFilters={expandedFilters}
              memberTypes={memberTypes}
              handleContributionsFilter={handleContributionsFilter}
            />

            <div className='side-panel-filters'>
              {lettersData.map((letter: any, index: number) => (
                <button
                  className={letter.selected ? 'selected' : ''}
                  onClick={() => handleFilterByLetter(letter)}
                  key={index}
                  disabled={!userData.length}
                >
                  {letter.label}
                </button>
              ))}
            </div>

            <ShowRecordsComponent data={userDataCopy} handleRecordSelection={handleRecordSelection} />
          </div>
        </div>
      </div>
      <div>
        <Pagination
          classes={{ root: 'pagination-wrapper' }}
          onChange={handlePageChange}
          count={pageCount}
          disabled={userData && userData.length < 10}
          variant='outlined'
          shape='rounded'
        />
      </div>
    </div>
  );
};

export default SidepanelMain;
