import { LanguageType } from '../store/slices/appSlice';

const availableLanguages = ['en', 'fr', 'es'];

const languageConfig: any = {
  availableLanguages,
  defaultLanguage: 'en' as LanguageType,
  localStorageKey: 'iucn-public-app-preferred-language',
};

export default languageConfig;
