import i18n from 'i18next';
import { initReactI18next } from 'react-i18next'; //https://www.i18next.com/overview/configuration-options
import HttpApi from 'i18next-http-backend'; //https://github.com/i18next/i18next-http-backend#backend-options
import config from './configs/config';

i18n
  .use(HttpApi)
  .use(initReactI18next)
  .init({
    lng: config.languageConfig.defaultLanguage,
    fallbackLng: config.languageConfig.defaultLanguage,
    whitelist: config.languageConfig.availableLanguages,
    backend: {
      loadPath: './locales/{{lng}}/{{ns}}.json',
    },
  });

export default i18n;
