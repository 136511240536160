import { getFeatures } from '../../../../utils/fetchData';
import { PieChartDataComponent } from '../../../../utils/types';
import { ENDPOINTS } from '../../../../configs/endpoints';
import { GLOBAL_VALUES } from '../../../../configs/globalValues';
import { statisticsQueries } from '../../../../configs/statisticsQueries';
import { ChartContentType } from '../../../../store/slices/sidePanelSlice';
import { queryDataStatistics } from '../../../../utils/fetchData';
import { getRemainingValue } from '../../../../utils/helperMethods';

export const DEFAULT_CHART_DATA = {
  'star-restoration': [],
  'star-threat-abatement': [],
  'climate-mitigation': [],
} as Record<ChartContentType, any[] | PieChartDataComponent[]>;

const handleNullValues = (value: number | null) => {
  return value === null ? 0 : value;
};

export const getPropertyTotalValue = (data: any, key: string) => {
  return data
    .map((value: any) => handleNullValues(value.attributes[`${key}`]))
    .reduce((a: number, b: number) => a + b, 0);
};

export const getCarbonData = (data: any) => {
  return data
    .map((values: any) => values.attributes.carbonValue)
    .filter((values: any) => values !== null)
    .map((values: any) => JSON.parse(values))
    .map((values: any) => values.result.totalCarbon)
    .reduce((a: number, b: number) => a + b, 0);
};

const fetchOrgRegions = async (orgName: string) => {
  const regions = await getFeatures({
    url: ENDPOINTS.ORG_AGGREGATIONS,
    queryParams: {
      where: `organization LIKE '%${orgName}%'`,
      returnDistinctValues: true,
      outFields: ['MapRegion', 'iso3'],
      returnGeometry: false,
    },
  });

  return regions;
};

const handleCountryName = (data: any, iso3: string | undefined) => {
  if (!data.length) return { ISO3: iso3 ? iso3 : '', Display_Name: 'Not found' };

  if (iso3) {
    const findCountry = data.find((item: any) => item.ISO3 === iso3);

    return { ISO3: findCountry?.ISO3, Display_Name: findCountry?.Display_Name };
  }
  const { ISO3, Display_Name } = data[0];
  return { ISO3, Display_Name };
};

const handleRegionName = (data: any, iso3: string | undefined) => {
  if (!data.length) return iso3;

  if (data.length === 1) {
    const { MapRegion } = data[0];
    return MapRegion;
  }
  if (iso3) {
    const findRegion = data.find((item: any) => item.iso3 === iso3);
    const MapRegion = findRegion?.MapRegion ? findRegion.MapRegion : iso3;
    return MapRegion;
  } else {
    const { MapRegion } = data[0];
    return MapRegion;
  }
};

const checkForUniqueRegions = (regions: { MapRegion: string; iso3: string }[]) => {
  if (regions?.length === 1) {
    return regions;
  }
  const mapRegions = regions.map((item) => item.MapRegion);
  const uniqueRegions = [...new Set(mapRegions)];
  return uniqueRegions.map((item) => {
    return { MapRegion: item, iso3: '' };
  });
};

export const fetchStatsData = async (orgName: string, partners?: string | null) => {
  const outStatistics: any = [
    {
      onStatisticField: 'budgetNeeded',
      outStatisticFieldName: 'budgetNeeded',
      statisticType: 'sum',
    },
    {
      onStatisticField: 'totalBeneficiaries',
      outStatisticFieldName: 'totalBeneficiaries',
      statisticType: 'sum',
    },
    {
      onStatisticField: 'totalBudget',
      outStatisticFieldName: 'totalBudget',
      statisticType: 'sum',
    },
    {
      onStatisticField: 'totalStaff',
      outStatisticFieldName: 'totalStaff',
      statisticType: 'sum',
    },
  ];
  let where = `ownerOrgs LIKE '%${orgName}%'`;

  if (partners) {
    where += `OR partnerOrgs LIKE '%${partners}%'`;
  }

  return await getFeatures({
    url: ENDPOINTS.APPROVED_CONTRIBUTIONS_LAYER,
    queryParams: {
      outStatistics,
      where,
      outFields: ['*'],
      returnGeometry: false,
    },
  });
};

const fetchOrgCountries = async (orgName: string) => {
  const countries = await getFeatures({
    url: ENDPOINTS.ORG_AGGREGATIONS,
    queryParams: {
      where: `organization LIKE '%${orgName}%'`,
      returnDistinctValues: true,
      outFields: ['ISO3'],
      returnGeometry: false,
    },
  });

  if (countries.length < 1) {
    return countries;
  }

  const countryIsos = countries.map(({ iso3 }) => iso3);

  const countryNamesAndIsos = await getFeatures({
    url: ENDPOINTS.WORLD_COUNTRY_LOOKUP,
    queryParams: {
      where: `ISO3 IN ('${countryIsos.join("','")}')`,
      returnDistinctValues: true,
      outFields: ['ISO3', 'Display_Name'],
      returnGeometry: false,
    },
  });

  return countryNamesAndIsos;
};

export const fetchOrgAggregations = async (orgName: string, iso3?: string, region?: string) => {
  const outStatistics = [
    {
      onStatisticField: 'star_value_ta',
      outStatisticFieldName: 'org_ta',
      statisticType: 'sum',
    },
    {
      onStatisticField: 'star_value_r',
      outStatisticFieldName: 'org_r',
      statisticType: 'sum',
    },
    {
      onStatisticField: 'carbon_value',
      outStatisticFieldName: 'org_carbon',
      statisticType: 'sum',
    },
  ];

  let where = `organization LIKE '%${orgName}%'`;

  if (iso3) {
    where += ` AND iso3='${iso3}'`;
  }
  if (region) {
    where += ` AND MapRegion='${region}'`;
  }

  const queryParams = {
    where,

    outStatistics,
    returnGeometry: false,
  } as __esri.QueryProperties;

  const data = await getFeatures({
    url: ENDPOINTS.ORG_AGGREGATIONS,
    queryParams,
  });

  return data;
};

export const getStats = async (org: string, partner: string | null, iso3?: string, region?: string) => {
  let result: any = {
    totalCarbon: null,
    starValueR: null,
    starValueTA: null,
    budgetNeeded: null,
    totalBeneficiaries: null,
    totalBudget: null,
    totalStaff: null,
  };
  const aggregateData = await fetchOrgAggregations(org, iso3, region);
  const totalsData = await fetchStatsData(org, partner);

  if (aggregateData && aggregateData.length) {
    result = {
      ...result,
      totalCarbon: aggregateData[0]?.org_carbon,
      starValueR: aggregateData[0]?.org_r,
      starValueTA: aggregateData[0]?.org_ta,
    };
  }
  if (totalsData && totalsData.length) {
    result = {
      ...result,
      budgetNeeded: totalsData[0]?.budgetNeeded,
      totalBeneficiaries: totalsData[0]?.totalBeneficiaries,
      totalBudget: totalsData[0]?.totalBudget,
      totalStaff: totalsData[0]?.totalStaff,
    };
  }
  return result;
};

export const fetchOrgChartData = async (
  orgName: string,
  selectedLanguage: any,
  country?: string,
  region?: string
): Promise<Record<ChartContentType, Array<any>>> => {
  const { regionTA, regionRestoration, regionCarbon, carbonLookupCountry, starLookupCountry } = statisticsQueries;
  const { STAR_RESTORATION, THREAT_ABATEMENT, CLIMATE_MITIGATION } = GLOBAL_VALUES;
  const GLOBAL_LABEL = selectedLanguage['Global'];
  const GLOBAL_COMPARISON_LABEL = selectedLanguage['Global Comparison'];
  const REGION_COMPARISON_LABEL = selectedLanguage['Regional Comparison'];
  const COUNTRY_COMPARISON_LABEL = selectedLanguage['Land region Comparison'];

  const chartData = {
    'star-restoration': [],
    'star-threat-abatement': [],
    'climate-mitigation': [],
  } as Record<ChartContentType, any[] | PieChartDataComponent[]>;

  const [orgAggregations] = await fetchOrgAggregations(orgName, country, region);
  const { org_ta, org_r, org_carbon } = orgAggregations; // values can be null

  const orgRToGlobalData = [
    {
      name: orgName || selectedLanguage['No Data'],
      y: org_r || 0,
    },
    {
      name: GLOBAL_LABEL,
      y: getRemainingValue(org_r, STAR_RESTORATION).y,
      rawValue: getRemainingValue(org_r, STAR_RESTORATION).rawValue,
    },
    GLOBAL_COMPARISON_LABEL,
  ] as any;

  const orgTAToGlobalData = [
    {
      name: orgName || selectedLanguage['No Data'],
      y: org_ta || 0,
    },
    {
      name: GLOBAL_LABEL,
      y: getRemainingValue(org_ta, THREAT_ABATEMENT).y,
      rawValue: getRemainingValue(org_ta, THREAT_ABATEMENT).rawValue,
    },
    GLOBAL_COMPARISON_LABEL,
  ] as any;

  const orgCarbonToGlobalData = [
    {
      name: orgName || selectedLanguage['No Data'],
      y: org_carbon || 0,
    },
    {
      name: GLOBAL_LABEL,
      y: getRemainingValue(org_carbon, CLIMATE_MITIGATION).y,
      rawValue: getRemainingValue(org_carbon, CLIMATE_MITIGATION).rawValue,
    },
    GLOBAL_COMPARISON_LABEL,
  ] as any;

  chartData['star-restoration'].push(orgRToGlobalData as PieChartDataComponent);
  chartData['star-threat-abatement'].push(orgTAToGlobalData as PieChartDataComponent);
  chartData['climate-mitigation'].push(orgCarbonToGlobalData as PieChartDataComponent);

  const regions = await fetchOrgRegions(orgName);
  const uniqueRegions = checkForUniqueRegions(regions);

  if (uniqueRegions.length > 1 && !country && !region) {
    // multiple regions, display one chart
    return chartData;
  }

  // 1 region -> org:region region:global
  // Fetch region sums and create org:region comparisons
  const MapRegion = handleRegionName(regions, country);

  const regionName = region ? region : MapRegion;
  const regionWhereClause = `MapRegion='${regionName}'`;

  const [regionCarbonResponse] = await queryDataStatistics({ ...regionCarbon, where: regionWhereClause });
  const [regionTAResponse] = await queryDataStatistics({ ...regionTA, where: regionWhereClause });
  const [regionRestorationResponse] = await queryDataStatistics({ ...regionRestoration, where: regionWhereClause });

  const { region_carbon_total } = regionCarbonResponse;
  const { region_TA_total } = regionTAResponse;
  const { region_Restoration_total } = regionRestorationResponse;

  // With multiple regions, global comparisons becomes region:global (part:whole)
  orgRToGlobalData[0] = {
    name: regionName,
    y: region_Restoration_total,
  };
  orgTAToGlobalData[0] = {
    name: regionName || selectedLanguage['No Data'],
    y: region_TA_total,
  };
  orgCarbonToGlobalData[0] = {
    name: regionName || selectedLanguage['No Data'],
    y: region_carbon_total,
  };

  const orgRToRegionData = [
    {
      name: orgName || selectedLanguage['No Data'],
      y: org_r || 0,
    },
    {
      name: regionName || selectedLanguage['No Data'],
      y: getRemainingValue(org_r, region_Restoration_total).y,
      rawValue: getRemainingValue(org_r, region_Restoration_total).rawValue,
    },
    REGION_COMPARISON_LABEL,
  ] as any;

  const orgTAToRegionData = [
    {
      name: orgName || selectedLanguage['No Data'],
      y: org_ta || 0,
    },
    {
      name: regionName || selectedLanguage['No Data'],
      y: getRemainingValue(org_ta, region_TA_total).y,
      rawValue: getRemainingValue(org_ta, region_TA_total).rawValue,
    },
    REGION_COMPARISON_LABEL,
  ] as any;

  const orgCarbonToRegionData = [
    {
      name: orgName || selectedLanguage['No Data'],
      y: org_carbon || 0,
    },
    {
      name: regionName || selectedLanguage['No Data'],
      y: getRemainingValue(org_carbon, region_carbon_total).y,
      rawValue: getRemainingValue(org_carbon, region_carbon_total).rawValue,
    },
    REGION_COMPARISON_LABEL,
  ] as any;

  chartData['star-restoration'].unshift(orgRToRegionData);
  chartData['star-threat-abatement'].unshift(orgTAToRegionData);
  chartData['climate-mitigation'].unshift(orgCarbonToRegionData);

  const countries = await fetchOrgCountries(orgName);

  if (countries.length > 1 && uniqueRegions.length === 1 && !country && !region) {
    // mulitple countries one region display 2 charts
    return chartData;
  }

  // 1 country -> org:country country:region region:global
  // Fetch country sums and create org:region comparisons
  const { ISO3, Display_Name } = handleCountryName(countries, country);

  const where = `ISO3='${ISO3}'`;

  const [countryCarbonLookup] = await queryDataStatistics({ ...carbonLookupCountry, where });
  const [countryStarLookup] = await queryDataStatistics({ ...starLookupCountry, where });

  const { country_R_total, country_TA_total } = countryStarLookup;

  // if user clicks on regions or selects a region from dropdown, always display 2 charts
  if (region) {
    return chartData;
  }

  // With multiple countries, regional comparisons becomes country:region (part:whole)
  orgRToRegionData[0] = {
    name: Display_Name || selectedLanguage['No Data'],
    y: country_R_total,
  };
  orgTAToRegionData[0] = {
    name: Display_Name || selectedLanguage['No Data'],
    y: country_TA_total,
  };
  orgCarbonToRegionData[0] = {
    name: Display_Name || selectedLanguage['No Data'],
    y: countryCarbonLookup?.country_carbon_total,
  };

  const orgRToCountryData = [
    {
      name: orgName || selectedLanguage['No Data'],
      y: org_r || 0,
    },
    {
      name: Display_Name,
      y: getRemainingValue(org_r, country_R_total).y,
      rawValue: getRemainingValue(org_r, country_R_total).rawValue,
    },
    COUNTRY_COMPARISON_LABEL,
  ] as any;

  const orgTAToCountryData = [
    {
      name: orgName || selectedLanguage['No Data'],
      y: org_ta || 0,
    },
    {
      name: Display_Name || selectedLanguage['No Data'],
      y: getRemainingValue(org_ta, country_TA_total).y,
      rawValue: getRemainingValue(org_ta, country_TA_total).rawValue,
    },
    COUNTRY_COMPARISON_LABEL,
  ] as any;

  const orgCarbonToCountryData = [
    {
      name: orgName || selectedLanguage['No Data'],
      y: org_carbon || 0,
    },
    {
      name: Display_Name || selectedLanguage['No Data'],
      y: getRemainingValue(org_carbon, countryCarbonLookup?.country_carbon_total).y,
      rawValue: getRemainingValue(org_carbon, countryCarbonLookup?.country_carbon_total).rawValue,
    },
    COUNTRY_COMPARISON_LABEL,
  ] as any;

  chartData['star-restoration'].unshift(orgRToCountryData);
  chartData['star-threat-abatement'].unshift(orgTAToCountryData);
  chartData['climate-mitigation'].unshift(orgCarbonToCountryData);

  return chartData;
};

export const fetchOrgDescription = async (orgName: string) => {
  const queryParams = {
    where: `organization='${orgName}'`,
    outFields: ['description', 'org_country'],
    returnGeometry: false,
  };

  const data = await getFeatures({
    url: ENDPOINTS.ORG_EMAIL,
    queryParams,
  });

  return data;
};
