import { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AiFillCaretLeft } from 'react-icons/ai';
import {
  orgsCountSelector,
  setOrganizationData,
  setOrgsCount,
  setVisiblePanel,
} from '../../../store/slices/sidePanelSlice';
import {
  countryLevelDataSelector,
  languageSelector,
  organizationDescriptionSelector,
  regionLevelDataSelector,
  setCountryLevelData,
  setOrganizationDescription,
  setRegionLevelData,
  setUserContributions,
  userContributionsSelector,
} from '../../../store/slices/appSlice';
import { mapController } from '../../../controllers/MapController';
import TabbedCharts from '../../chartPanels/TabbedCharts';
import { ChartContentType } from '../../../store/slices/sidePanelSlice';
import { fetchOrgChartData, fetchOrgDescription, DEFAULT_CHART_DATA, getStats } from './helpers/organizationHelpers';
import { PieChartDataComponent } from '../../../utils/types';
import './styles/sidePanel.scss';
import './styles/sidePanelOrganization.scss';
import DisplayContributions from '../../displayContributions/DisplayContributions';
import { TRANSLATIONS } from '../../../translations/translations';

interface TotalsTypes {
  budgetNeeded: null | number;
  totalBeneficiaries: null | number;
  totalBudget: null | number;
  totalStaff: null | number;
  totalCarbon: null | number;
  starValueTA: null | number;
  starValueR: null | number;
}

const totalsDefaultValues = {
  budgetNeeded: null,
  totalBeneficiaries: null,
  totalBudget: null,
  totalStaff: null,
  totalCarbon: null,
  starValueTA: null,
  starValueR: null,
};

type TotalTypes =
  | 'starValueR'
  | 'starValueTA'
  | 'totalCarbon'
  | 'totalStaff'
  | 'totalBudget'
  | 'totalBeneficiaries'
  | 'budgetNeeded';

const SidePanelOrganization = () => {
  const dispatch = useDispatch();

  const organizationContributions = useSelector(userContributionsSelector);
  const countryLevelData = useSelector(countryLevelDataSelector);
  const regionLevelData = useSelector(regionLevelDataSelector);
  const organizationDescription = useSelector(organizationDescriptionSelector);
  const orgsCount = useSelector(orgsCountSelector);
  const [countryName, setCountryName] = useState('');
  const [totals, setTotals] = useState<TotalsTypes>(totalsDefaultValues);
  const [orgChartData, setOrgChartData] = useState<Record<ChartContentType, any[] | PieChartDataComponent[]>>(
    Object.assign({}, DEFAULT_CHART_DATA)
  );
  const [busy, setBusy] = useState(false);

  const language = useSelector(languageSelector);
  const selectedLanguage = TRANSLATIONS[language];

  const getOrgDescription = useCallback(
    async (orgName: string) => {
      const [data] = await fetchOrgDescription(orgName);

      if (data) {
        const { description, org_country } = data;
        setCountryName(org_country);
        dispatch(setOrganizationDescription(description));
      } else {
        dispatch(setOrganizationDescription(selectedLanguage['No description available']));
      }
    },
    [dispatch, selectedLanguage]
  );

  const getOrgChartData = useCallback(
    async (orgName: string) => {
      const { countryISO3 } = countryLevelData;
      const { region } = regionLevelData;
      setBusy(true);
      const chartData = await fetchOrgChartData(orgName, selectedLanguage, countryISO3, region);
      setOrgChartData(chartData);
      setBusy(false);
    },
    [selectedLanguage, countryLevelData, regionLevelData]
  );

  const togglePanels = () => {
    const { country, countryISO3 } = countryLevelData;
    const { region } = regionLevelData;

    if (region) {
      dispatch(setVisiblePanel('region-panel'));
      mapController.filterLayer(`beneficiaryRegions = '${region}'`);
      mapController.queryMapExtent(`beneficiaryRegions ='${region}'`);

      return;
    }
    // if country is defined it means user is coming from country level panel
    if (country) {
      dispatch(setVisiblePanel('country-panel'));
      mapController.filterLayer(`beneficiaryCountries LIKE '%${countryISO3}%'`);
      mapController.queryMapExtent(`beneficiaryCountries LIKE '%${countryISO3}%'`);
      return;
    }
    dispatch(setVisiblePanel('main-panel'));
    dispatch(setCountryLevelData({ country: '', countryISO3: '', data: [] }));
    dispatch(setRegionLevelData({ region: '', OBJECTID: null, data: [] }));
    dispatch(setOrganizationData([]));
    dispatch(setUserContributions({}));
    dispatch(setOrgsCount(0));
    mapController.resetMapView();
    setTotals(totalsDefaultValues);
  };

  useEffect(() => {
    const { orgName, partnerOrgs } = organizationContributions;
    const { countryISO3 } = countryLevelData;
    const { region } = regionLevelData;
    const orgNameForSql = orgName?.replace("'", "''");

    async function getData() {
      const response = await getStats(orgNameForSql, partnerOrgs, countryISO3, region);

      if (response) {
        const budgetNeeded = response.budgetNeeded;
        const totalBeneficiaries = response.totalBeneficiaries;
        const totalBudget = response.totalBudget;
        const totalStaff = response.totalStaff;
        const starValueR = response.starValueR;
        const starValueTA = response.starValueTA;
        const totalCarbon = response.totalCarbon;

        setTotals(() => {
          return {
            ...totals,
            budgetNeeded,
            totalBeneficiaries,
            totalBudget,
            totalStaff,
            totalCarbon,
            starValueR,
            starValueTA,
          };
        });
      }

      if (orgName) {
        // Replace ' with '' so it will work in WHERE sql clauses.
        getOrgDescription(orgNameForSql);
        getOrgChartData(orgNameForSql);
      }
    }
    getData();
  }, [organizationContributions, getOrgDescription, getOrgChartData, setTotals]);

  const handleTotalValues = (key: TotalTypes) => {
    if (totals[key]) {
      const value = Number(totals[key]);
      if (value > 1000) {
        const rawValue = Number(value.toFixed(0));
        return <span>{rawValue.toLocaleString()}</span>;
      } else {
        const absoluteValue = value.toPrecision(4);
        return <span>{absoluteValue}</span>;
      }
    }
    return <span>{selectedLanguage['No Data']}</span>;
  };

  return (
    <div className='SidePanel'>
      <div className='side-panel-header-result'>
        <div className='return-btn' onClick={togglePanels}>
          <div className='return-to-main'>
            <span>
              <AiFillCaretLeft />
            </span>
            <h4>{selectedLanguage['Return to Results']}</h4>
          </div>
        </div>
        <div className='member-information'>
          <h1>
            {countryName ? `${organizationContributions.orgName}, ${countryName}` : organizationContributions.orgName}
          </h1>
          <span className='border'></span>
          <span className='contribution-total'>
            {selectedLanguage['Total number of contributions']}: {orgsCount}
          </span>
        </div>
      </div>
      <div className='organizations-wrapper'>
        <div className='description'>
          <p>{organizationDescription}</p>

          <div className='total-description-container'>
            <div className='total-description'>
              <p>{selectedLanguage['STAR Threat Abatement Score']}</p>
              <div className='mean'>{handleTotalValues('starValueTA')}</div>
            </div>
            <div className='total-description'>
              <p>{selectedLanguage['STAR Restoration Score']}</p>
              <div className='mean'>{handleTotalValues('starValueR')}</div>
            </div>
            <div className='total-description'>
              <p>{selectedLanguage['Total Climate Change Mitigation']}</p>
              <div className='mean'>{handleTotalValues('totalCarbon')}</div>
            </div>

            <div className='total-description'>
              <p>{selectedLanguage['Total Budget']}</p>
              <div className='mean'>{handleTotalValues('totalBudget')}</div>
            </div>
            <div className='total-description'>
              <p>{selectedLanguage['Total Budget needed']}</p>
              <div className='mean'>{handleTotalValues('budgetNeeded')}</div>
            </div>
            <div className='total-description'>
              <p>{selectedLanguage['Total number of Staff']}</p>
              <div className='mean'>{handleTotalValues('totalStaff')}</div>
            </div>
            <div className='total-description'>
              <p>{selectedLanguage['Total number of Beneficiaries']}</p>
              <div className='mean'>{handleTotalValues('totalBeneficiaries')}</div>
            </div>
          </div>
          <TabbedCharts chartData={orgChartData} busy={busy} />
        </div>
        <DisplayContributions />
      </div>
    </div>
  );
};

export default SidePanelOrganization;
