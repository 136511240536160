import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AiFillCaretLeft } from 'react-icons/ai';
import {
  ChartContentType,
  contributionAttachmentsSelector,
  setContributionAttachments,
  setOrgsInContributionList,
  setOrganizationData,
  setOrgsCount,
  setSelectedOrganizationName,
  setVisiblePanel,
  VisiblePanel,
} from '../../../store/slices/sidePanelSlice';
import {
  languageSelector,
  setCountryLevelData,
  setRegionLevelData,
  setUserContributions,
  singleContributionSelector,
  userDataSelector,
} from '../../../store/slices/appSlice';
import { mapController } from '../../../controllers/MapController';
import ChartPanels from '../../chartPanels/ChartPanels';
import { setChartData, setVisibleChartPanel, visibleChartPanelSelector } from '../../../store/slices/chartSlice';
import { queryDataStatistics } from '../../../utils/fetchData';
import { statisticsQueries } from '../../../configs/statisticsQueries';
import {
  contributionGlobalData,
  contributionToCountryData,
  contributionToRegionData,
  countryToRegionData,
  oneChartDataBuilder,
  regionGlobalData,
  threeChartDataBuilder,
  twoChartDataBuilder,
} from '../../../utils/chartDataBuilder';
import ColumnChart from '../../charts/ColumnChart';
import './styles/sidePanelContribution.scss';
import { StarRTATypes } from './types';
import { GLOBAL_VALUES } from '../../../configs/globalValues';
import { columnDataBuilder, handleAttributes, checkForMultipleOrgs } from './helpers/contributionHelpers';
import { setSelectedContribution, setSelectedCountryOrRegion } from '../../../store/slices/mapSlice';
import DisplayAllOrgs from '../../displayAllOrgs/DisplayAllOrgs';
import DisplayAttachments from '../../displayAttachments/DisplayAttachments';
import { TRANSLATIONS } from '../../../translations/translations';
import ChartTooltip from '../../tooltipComponent/ChartTooltiop';

const pieChartDefaultValues = {
  climateMitigation: [],
  restoration: [],
  threatAbatement: [],
};
const columnChartDefaultValues = {
  restorationData: {},
  threatData: {},
};

const SidePanelContribution = () => {
  const attributes = useSelector(singleContributionSelector);
  const userData = useSelector(userDataSelector);
  const visibleChartPanel = useSelector(visibleChartPanelSelector);
  const contributionAttachments = useSelector(contributionAttachmentsSelector);
  const [pieChartsData, setPieChartsData] = useState<any>(pieChartDefaultValues);
  const [columnChartsData, setColumnsChartsData] = useState<any>(columnChartDefaultValues);
  const dispatch = useDispatch();

  const language = useSelector(languageSelector);
  const selectedLanguage = TRANSLATIONS[language];

  useEffect(() => {
    if (Object.keys(attributes)?.length) {
      getStatisticsData();
    }
  }, [language, attributes]);

  useEffect(() => {
    dispatch(setChartData([]));
    if (attributes?.ownerOrgs) {
      const orgsData = checkForMultipleOrgs(attributes.ownerOrgs, attributes.partnerOrgs);
      dispatch(setOrgsInContributionList(orgsData));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attributes?.ownerOrgs, attributes?.partnerOrgs]);

  // Total Budget, Total Staff, Total Beneficiaries

  async function getStatisticsData() {
    const { carbonLookupCountry, starLookupCountry, regionTA, regionRestoration, regionCarbon } = statisticsQueries;
    const {
      beneficiaryCountries,
      beneficiaryRegions,
      starValueR,
      starValueTA,
      star_threatTypes_r,
      star_threatTypes_ta,
    } = handleAttributes(attributes);

    const splitCountries = beneficiaryCountries?.split('|');
    const splitRegions = beneficiaryRegions?.split('|');
    let contributionData: any = [];

    if (Object.keys(attributes).length) {
      const parseData = JSON.parse(attributes?.carbonValue);
      const carbonValue = parseData?.result?.totalCarbon;
      contributionData = [{ starValueR, starValueTA, carbonValue }];
    }

    if (star_threatTypes_r && star_threatTypes_ta) {
      const parseRestorion = JSON.parse(star_threatTypes_r) as StarRTATypes[];
      const parsethreats = JSON.parse(star_threatTypes_ta) as StarRTATypes[];

      const categoriesRestoration = parseRestorion.map((item) => item.label);
      const categoriesThreats = parsethreats.map((item) => item.label);

      const threat = columnDataBuilder(parsethreats);
      const restoration = columnDataBuilder(parseRestorion);

      const restorationData = { categories: categoriesRestoration, data: restoration };
      const threatData = { categories: categoriesThreats, data: threat };

      setColumnsChartsData({ restorationData, threatData });
    } else {
      setColumnsChartsData({
        restorationData: { categories: [], data: [] },
        threatData: { categories: [], data: [] },
      });
    }
    if (splitRegions.length > 1) {
      const contributionGlobalChartData = contributionGlobalData({ contributionData, GLOBAL_VALUES }, selectedLanguage);
      const updateData = oneChartDataBuilder({ contributionGlobalChartData, selectedLanguage });
      setPieChartsData(updateData);
    } else if (splitCountries.length === 1) {
      const where = `ISO3='${splitCountries[0]}'`;
      const lookupWhere = `ISO3='${splitCountries[0]}'`;

      // show 3 charts
      // contribution to country
      // country to region
      // region to global
      const [countryCarbonLookup, countryStarLookup] = await Promise.all([
        queryDataStatistics({ ...carbonLookupCountry, where }),
        queryDataStatistics({ ...starLookupCountry, where: lookupWhere }),
      ]);

      const country = splitCountries[0];
      const mapRegion = countryCarbonLookup[0]?.MapRegion;
      const countryName = countryCarbonLookup[0]?.Country;
      const regionWhereClause = `MapRegion='${mapRegion}'`;

      const [regionCarbonResponse, regionTAResponse, regionRestorationResponse] = await Promise.all([
        queryDataStatistics({ ...regionCarbon, where: regionWhereClause }),
        queryDataStatistics({ ...regionTA, where: regionWhereClause }),
        queryDataStatistics({ ...regionRestoration, where: regionWhereClause }),
      ]);

      const countryData = { countryCarbonLookup, countryStarLookup, country: countryName ?? country };
      const regionData = { regionCarbonResponse, regionTAResponse, regionRestorationResponse, region: mapRegion };

      const contributionCountryChartData = contributionToCountryData(
        {
          contributionData,
          countryData,
        },
        selectedLanguage
      );

      const countryRegionChartData = countryToRegionData({ countryData, regionData }, selectedLanguage);
      const regionGlobalChartData = regionGlobalData({ GLOBAL_VALUES, regionData }, selectedLanguage);

      const updateData = threeChartDataBuilder({
        contributionCountryChartData,
        countryRegionChartData,
        regionGlobalChartData,
        country: countryName ?? country,
        region: mapRegion,
        selectedLanguage,
      });

      setPieChartsData(updateData);
    } else if (splitCountries.length > 1) {
      // show 2 charts
      // contribution to region
      // region to global
      const regionWhereClause = `MapRegion='${beneficiaryRegions}'`;
      const [regionCarbonResponse, regionTAResponse, regionRestorationResponse] = await Promise.all([
        queryDataStatistics({ ...regionCarbon, where: regionWhereClause }),
        queryDataStatistics({ ...regionTA, where: regionWhereClause }),
        queryDataStatistics({ ...regionRestoration, where: regionWhereClause }),
      ]);

      const regionData = {
        regionCarbonResponse,
        regionTAResponse,
        regionRestorationResponse,
        region: beneficiaryRegions,
      };

      const contributionRegionChartData = contributionToRegionData({ contributionData, regionData }, selectedLanguage);
      const regionGlobalChartData = regionGlobalData({ regionData, GLOBAL_VALUES }, selectedLanguage);

      const updateData = twoChartDataBuilder({
        contributionRegionChartData,
        regionGlobalChartData,
        selectedLanguage,
      });
      setPieChartsData(updateData);
    }
  }

  const { name, ownerOrgs, budgetNeeded, totalStaff, totalBeneficiaries, totalBudget } = handleAttributes(attributes);

  const handleChartPanelToggle = (panel: ChartContentType) => {
    if (panel === visibleChartPanel) return;
    dispatch(setVisibleChartPanel(panel));
  };

  const handleTotalValues = (value: string) => {
    if (value) {
      const updatedValue = Number(Number(value).toFixed(3));
      return <span>{updatedValue.toLocaleString()}</span>;
    }
    return <span>{selectedLanguage['No Data']}</span>;
  };

  const handleOrganizationUpdates = () => {
    const splitOrgs = ownerOrgs?.split('|');
    if (splitOrgs.length === 1) {
      dispatch(setSelectedOrganizationName(splitOrgs[0]));
      const selectedOrg = userData.find((item) => item.organization === splitOrgs[0]);

      if (selectedOrg && selectedOrg?.organization) {
        let where = `ownerOrgs LIKE '%${splitOrgs[0]}%'`;

        if (selectedOrg?.partnerOrgs) {
          const partnerOrgs = selectedOrg?.partnerOrgs;
          where += ` OR partnerOrgs LIKE '%${partnerOrgs}%'`;
        }
        dispatch(setUserContributions(selectedOrg));
        dispatch(setOrgsCount(selectedOrg.orgCount));

        const params = {
          page: 0,
          count: 10,
          where,
          outFields: ['*'],
          orderByFields: ['name'],
          keepPrevious: false,
        };

        mapController.closePopup();
        mapController.queryOrgsByPage(params);
        mapController.queryMapExtent(where);
        mapController.filterLayer(where);
      }
    }
  };

  const togglePanels = (type: VisiblePanel) => {
    if (type === 'main-panel') {
      dispatch(setContributionAttachments([]));
      dispatch(setCountryLevelData({ country: '', countryISO3: '', data: [] }));
      dispatch(setRegionLevelData({ region: '', OBJECTID: null, data: [] }));
      dispatch(setOrganizationData([]));
      dispatch(setUserContributions({}));
      dispatch(setOrgsCount(0));
      dispatch(setVisiblePanel(type));
      dispatch(setSelectedContribution(null));
      dispatch(setSelectedCountryOrRegion(null));
      mapController.resetMapView();
    }
  };

  return (
    <div className='SidePanel'>
      <div className='side-panel-header-contribution'>
        <div className='return-btn'>
          <div className='return-to-main' onClick={() => togglePanels('main-panel')}>
            <span>
              <AiFillCaretLeft />
            </span>
            <h4>{selectedLanguage['Return to Results']}</h4>
          </div>
        </div>
        <div className='member-contribution'>
          <h1>{name}</h1>
        </div>
      </div>
      <div className='contribution-wrapper'>
        <DisplayAllOrgs />

        <div className='total-description-container'>
          <div className='total-description'>
            <p>{selectedLanguage['Total Budget']}</p>
            {handleTotalValues(totalBudget)}
          </div>
          <div className='total-description'>
            <p>{selectedLanguage['Total Budget needed']}</p>
            {handleTotalValues(budgetNeeded)}
          </div>
          <div className='total-description'>
            <p>{selectedLanguage['Total number of Staff']}</p>
            {handleTotalValues(totalStaff)}
          </div>
          <div className='total-description'>
            <p>{selectedLanguage['Total number of Beneficiaries']}</p>
            {handleTotalValues(totalBeneficiaries)}
          </div>
        </div>

        <div className='chart-panel-buttons'>
          <button
            className={visibleChartPanel === 'star-threat-abatement' ? 'active' : ''}
            onClick={() => handleChartPanelToggle('star-threat-abatement')}
          >
            {/* Threat abatement */}
            {selectedLanguage['Conservation contribution to biodiversity']}
            <ChartTooltip chartType='Conservation' />
          </button>
          <button
            className={visibleChartPanel === 'star-restoration' ? 'active' : ''}
            onClick={() => handleChartPanelToggle('star-restoration')}
          >
            {/* Restoration */}
            {selectedLanguage['Restoration contribution to biodiversity']}
            <ChartTooltip chartType='Restoration' />
          </button>
          <button
            className={visibleChartPanel === 'climate-mitigation' ? 'active' : ''}
            onClick={() => handleChartPanelToggle('climate-mitigation')}
          >
            {/* Climate Mitigation */}
            {selectedLanguage['Restoration contribution to climate change mitigation']}
            <ChartTooltip chartType='Climate change' />
          </button>
        </div>
        <div className='column-chart-wrapper'>
          {visibleChartPanel === 'star-restoration' && (
            <div className='column-chart'>
              <h3>
                {selectedLanguage['Potential contribution of restoration towards species extinction risk reduction']}
              </h3>
              <ColumnChart data={columnChartsData.restorationData} />
            </div>
          )}

          {visibleChartPanel === 'star-threat-abatement' && (
            <div className='column-chart'>
              <h3>
                {
                  selectedLanguage[
                    'Potential contribution of abating different threats towards species extinction risk reduction'
                  ]
                }
              </h3>
              <ColumnChart data={columnChartsData.threatData} />
            </div>
          )}
        </div>
        {visibleChartPanel === 'star-threat-abatement' && <ChartPanels chartData={pieChartsData.threatAbatement} />}
        {visibleChartPanel === 'star-restoration' && <ChartPanels chartData={pieChartsData.restoration} />}
        {visibleChartPanel === 'climate-mitigation' && <ChartPanels chartData={pieChartsData.climateMitigation} />}

        <DisplayAttachments data={contributionAttachments} />
      </div>
    </div>
  );
};

export default SidePanelContribution;
