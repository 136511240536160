export const regions = [
  {
    label: 'Africa',
    value: 'Africa',
  },
  {
    label: 'Asia',
    value: 'Asia',
  },
  {
    label: 'Europe',
    value: 'Europe',
  },
  {
    label: 'Oceania',
    value: 'Oceania',
  },
  {
    label: 'The Americas',
    value: 'The Americas',
  },
];
