import IUCNContenImg from '../../assets/IUCN_content_img_large.jpeg';
import contributionView from '../../assets/mapview.jpeg';
import contributionsList from '../../assets/entryapp.jpeg';
import keyBiodiversity from '../../assets/key-biodiversity-areas2.png';
import protectedAreas from '../../assets/protected-planet2.png';
import redList from '../../assets/IUCN_Red_List2.png';
import restorationBarometer from '../../assets/restoration-barometer2.png';
import treeLine from '../../assets/treeline.svg';
import { useDispatch, useSelector } from 'react-redux';
import { languageSelector, setVisiblewindow } from '../../store/slices/appSlice';
import { setIsMainPanelLoading } from '../../store/slices/sidePanelSlice';
import './styles/homeContent.scss';
import { TRANSLATIONS } from '../../translations/translations';
import { ENDPOINTS } from '../../configs/endpoints';

const { IUCN_TOOL_URL } = ENDPOINTS;

const HomeContent = () => {
  const dispatch = useDispatch();
  const language = useSelector(languageSelector);
  const selectedLanguage = TRANSLATIONS[language];

  const handleOnClick = () => {
    dispatch(setIsMainPanelLoading(true));
    dispatch(setVisiblewindow('map'));
  };

  const handleRedirectUser = () => {
    window.open(IUCN_TOOL_URL);
  };
  return (
    <main className='home-content'>
      <div className='home-content-top-description'>
        <div className='top-description-wrapper'>
          <h2>{selectedLanguage['What progress are conservation actions making towards global goals for nature?']}</h2>
          <div className='text-img'>
            {
              selectedLanguage[
                'The IUCN Programme Nature 2030, and other agreements have established targets to stem and ultimately reverse this loss. For many decades, the global conservation community has been implementing actions that contribute towards these global goals for nature. It is crucial that we collectively document and understand these contributions for nature, where and when they are being implemented, by who, and how much they are contributing towards global goals This IUCN Contributions for Nature platform, mandated by the IUCN Membership in Nature 2030 addresses these questions.'
              ]
            }
          </div>
          <div className='btn-wrapper'>
            <button onClick={handleOnClick}>{selectedLanguage['View Contributions']}</button>
            <button onClick={handleRedirectUser}>{selectedLanguage['Add Contributions']}</button>
          </div>
        </div>
        <img src={IUCNContenImg} alt='Examples of application at contribution level' />
      </div>
      <section className='home-content-middle-tree'>
        <div className='img-wrapper'>
          <div className='tree-bg-img'></div>
          <div className='paint-texture-bg'></div>
        </div>
        <div className='middle-content-wrapper'>
          <div className='middle-tree-content'>
            <div className='left-side'>
              <div className='border-top'></div>
              <h2>
                {
                  selectedLanguage[
                    'See what the conservation community is contributing towards global goals for nature.'
                  ]
                }
              </h2>
              <p>
                {
                  selectedLanguage[
                    'Use the Contributions for Nature platform to view contributions being made or planned by IUCN constituents - including non-governmental organisations, indigenous peoples organisations, governments, state agencies, and regional and local governments, as well as the IUCN Commissions, Committees, and Secretariat. You can also use the platform to quantify these contributions in terms of their potential delivery of global goals for biodiversity conservation and climate change mitigation. This can be achieved through the use of the Species Threat Abatement and Restoration Metric (STAR) and the Restoration Barometer.'
                  ]
                }
              </p>

              <div className='learn-more-btn'>
                <button onClick={handleOnClick}>{selectedLanguage['View Contributions']}</button>
              </div>
            </div>
            <div className='right-side'>
              <img src={contributionView} alt='screenshot of private application to edit contribution' />
            </div>
          </div>
          <div className='blue-egde-texture'></div>
        </div>
      </section>
      <section className='home-content-middle-four'>
        <div className='left-side'>
          <img src={contributionsList} alt='an example of private application with all contributions' />
        </div>
        <div className='right-side'>
          <div className='border-top'></div>

          <h2>{selectedLanguage[`Document your Organisation's Land Contributions`]}</h2>
          <p>
            {
              selectedLanguage[
                'The Contributions for Nature platform allows IUCN constituents to add contributions by entering spatial data describing their current or planned conservation or restoration actions, along with associated data on types of threats being mitigated and types of actions underway, timeframes, and level of investment.'
              ]
            }
            <span className='space'></span>
            {
              selectedLanguage[
                'Currently, only IUCN Members (including in National and Regional Committees), Commissions and Secretariat can add contributions to the platform.'
              ]
            }
          </p>
          <a href='https://www.iucn.org/about/union/members/how-become-member-iucn' rel='noreferrer' target='_blank'>
            {selectedLanguage[`Learn more about joining our Union`]}
          </a>
          <div className='learn-more-btn'>
            <button onClick={handleRedirectUser}>{selectedLanguage[`Add Contributions`]}</button>
          </div>
        </div>
      </section>
      <section className='home-content-middle-five'>
        <div className='members-img-wrapper'>
          <h2>
            {selectedLanguage['The Contributions for Nature platform draws from the following datasets and platforms']}
          </h2>
          <div className='platforms-imgs-container'>
            <a href='https://www.keybiodiversityareas.org/' target='_blank' rel='noreferrer'>
              <img src={keyBiodiversity} alt='Key Biodiversity Areas: keep nature thriving' />
            </a>
            <a href='https://www.protectedplanet.net/en' target='_blank' rel='noreferrer'>
              <img src={protectedAreas} alt="Discover the world's protected areas" />
            </a>
            <a href='https://www.iucnredlist.org/' target='_blank' rel='noreferrer'>
              <img src={redList} alt='' />
            </a>
            <a href='https://restorationbarometer.org/' target='_blank' rel='noreferrer'>
              <img src={restorationBarometer} alt='The IUCN Red List of Threatened Species' />
            </a>
          </div>
        </div>
        <div className='right-side'>
          <img src={treeLine} alt='' />
        </div>
      </section>
    </main>
  );
};

export default HomeContent;
