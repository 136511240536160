import { useDispatch, useSelector } from 'react-redux';
import {
  selectedContributionSelector,
  SelectedCountryOrRegionTypes,
  setSelectedContribution,
  setSelectedCountryOrRegion,
} from '../../../store/slices/mapSlice';
import { mapController } from '../../../controllers/MapController';
import {
  isCountryLoadingSelector,
  setIsContributionLoading,
  setVisiblePanel,
} from '../../../store/slices/sidePanelSlice';
import AsyncSelectComponent from '../../selectComponent/AsyncSelectComponent';
import { languageSelector, setSingleContribution } from '../../../store/slices/appSlice';
import './index.scss';
import { TRANSLATIONS } from '../../../translations/translations';

const SearchContributionWidget = () => {
  const selectedContribution = useSelector(selectedContributionSelector);
  const isCountryLoading = useSelector(isCountryLoadingSelector);
  const dispatch = useDispatch();

  const language = useSelector(languageSelector);
  const selectedLanguage = TRANSLATIONS[language];

  const handleSelectChange = async (value: SelectedCountryOrRegionTypes | null) => {
    // update dropdown value
    dispatch(setSelectedContribution(value));
    if (value && value?.value) {
      dispatch(setIsContributionLoading(true));
      const params = {
        where: `OBJECTID = ${value.value}`,
        outFields: ['*'],
        returnGeometry: true,
        num: 0,
        goToLocation: true,
        openPopup: true,
      };

      const response = await mapController.queryFeatures(params);

      if (response?.length) {
        dispatch(setSingleContribution(response[0]));
        dispatch(setVisiblePanel('contribution-panel'));

        dispatch(setIsContributionLoading(false));

        // reset country/region selection if any
        dispatch(setSelectedCountryOrRegion(null));
      }
    } else {
      dispatch(setVisiblePanel('main-panel'));
      mapController.resetMapView();
    }
  };

  const constructData = (data: any) => {
    if (!data.length) return [];
    return data.map((item: any) => {
      return {
        label: item.name,
        value: item.OBJECTID,
      };
    });
  };

  const loadOptions = async (value: string) => {
    // fetch data method
    const queryString = value
      .split(' ')
      .map((item) => `name LIKE '%${item}%'`)
      .join(' AND ');

    const params = {
      where: queryString,
      outFields: ['OBJECTID', 'name'],
      returnGeometry: false,
      num: 50,
      goToLocation: false,
    };
    const response = await mapController.queryFeatures(params);

    return constructData(response);
  };

  return (
    <div className='SearchContribution'>
      <div className='select-text'>
        <h4>{selectedLanguage['Search for contribution name']}:</h4>
        <AsyncSelectComponent
          placeholder={selectedLanguage['select contribution']}
          value={selectedContribution}
          loadOptions={loadOptions}
          onChange={handleSelectChange}
          disabled={isCountryLoading}
        />
      </div>
    </div>
  );
};

export default SearchContributionWidget;
