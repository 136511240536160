import { useDispatch, useSelector } from 'react-redux';
import {
  isTokenGeneratedSelector,
  setGeneratedToken,
  setIsTokenGenerated,
  setVisiblewindow,
  visibleWindowSelector,
} from '../store/slices/appSlice';
import HomePage from './homepage/HomePage';
import Login from './login/Login';
import MapView from './mapview/Mapview';
import MaintanancePage from './homepage/MaintenancePage';
import { useEffect } from 'react';
import { urlParamParser } from './publicApp/sidePanels/helpers/utils';
import { generateToken } from '../utils/utils';
import Loader from './loaders/GlobalLoader';
import { globalLoaderSelector, setGlobalLoader } from '../store/slices/loaderSlice';

const App = () => {
  const visibleWindow = useSelector(visibleWindowSelector);
  const isTokenGenerated = useSelector(isTokenGeneratedSelector);
  const globalLoader = useSelector(globalLoaderSelector);

  const dispatch = useDispatch();

  useEffect(() => {
    async function getToken() {
      const token = await generateToken();
      if (token) {
        dispatch(setGeneratedToken(token));
        dispatch(setIsTokenGenerated(true));
      }
    }
    // check for params from url if any
    if (window.location.search) {
      urlParamParser();
      dispatch(setVisiblewindow('map'));
      dispatch(setGlobalLoader(true));
    }
    getToken();
  }, []);

  return (
    <div>
      {globalLoader && <Loader />}

      {visibleWindow === 'homepage' && <HomePage />}
      {visibleWindow === 'map' && isTokenGenerated && <MapView />}
      {visibleWindow === 'login' && <Login />}
      {visibleWindow === 'maintenance-page' && <MaintanancePage />}
    </div>
  );
};

export default App;
