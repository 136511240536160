import { createFilter } from 'react-select';
import AsyncSelect from 'react-select/async';
import './styles/selectComponent.scss';

interface Props {
  value: null | any;
  onChange: Function;
  loadOptions: (e: any) => void;
  placeholder?: string;
  disabled: boolean;
}

const AsyncSelectComponent = ({ onChange, loadOptions, value, placeholder, disabled }: Props) => {
  return (
    <div className='SelectComponent'>
      <AsyncSelect
        className='custom-select-container'
        classNamePrefix={'custom-select'}
        value={value}
        placeholder={placeholder}
        isDisabled={disabled}
        onChange={(e: any) => onChange(e)}
        filterOption={createFilter({ ignoreAccents: false })}
        isMulti={false}
        cacheOptions
        isClearable={true}
        loadOptions={loadOptions}
      />
    </div>
  );
};

export default AsyncSelectComponent;
