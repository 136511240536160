import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import HomeHeader from './HomeHeader';
import HomeNavbar from './HomeNavbar';
import HomeContent from './HomeContent';
import Footer from './Footer';
import { setGeneratedToken } from '../../store/slices/appSlice';
import { generateToken } from '../../utils/utils';

const HomePage = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (!window.location.search) {
      // there are no params in the url
      async function getToken() {
        const token = await generateToken();
        if (token) {
          dispatch(setGeneratedToken(token));
        }
      }
      getToken();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <HomeNavbar />
      <HomeHeader />
      <HomeContent />
      <Footer />
    </div>
  );
};

export default HomePage;
