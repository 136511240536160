import SmoothCollapse from 'react-smooth-collapse';
import { TiArrowSortedDown, TiArrowSortedUp } from 'react-icons/ti';
import { useDispatch, useSelector } from 'react-redux';
import { ChartContentType } from '../../store/slices/sidePanelSlice';
import { GlobalDataTypes } from '../publicApp/sidePanels/types';
import TotalStatsReport from '../totalStatsReport/TotalStatsReport';
import PieChartWithoutWrapper from '../charts/PieChartWithoutWrapper';
import './styles/memberTypeDropdown.scss';
import './styles/regionStatsDropdown.scss';
import { languageSelector } from '../../store/slices/appSlice';
import { TRANSLATIONS } from '../../translations/translations';
import ChartTooltip from '../tooltipComponent/ChartTooltiop';
import { setVisibleChartRegion } from '../../store/slices/chartSlice';

interface Props {
  handleExpandedFilter: () => void;
  expanedFilters: boolean;
  statsData: GlobalDataTypes;
  chartData: any;
  visibleChart: ChartContentType;
  countryName: string;
  iso_a3?: string;
}

const RegionStatsDropdown = ({
  handleExpandedFilter,
  expanedFilters,
  statsData,
  chartData,
  visibleChart,
  countryName,
}: Props) => {
  const dispatch = useDispatch();
  const language = useSelector(languageSelector);
  const selectedLanguage = TRANSLATIONS[language];

  const handleChartToDisplay = (chartType: ChartContentType) => {
    dispatch(setVisibleChartRegion(chartType));
  };

  const handleChartToRender = (chartType: ChartContentType, key: string) => {
    return (
      visibleChart === chartType &&
      chartData[key] &&
      chartData[key].length &&
      chartData[key].map((data: any, index: number) => {
        return (
          <div className='charts' key={index}>
            <p>{data.label}</p>
            <PieChartWithoutWrapper data={data.chartData} />
          </div>
        );
      })
    );
  };

  return (
    <div className='filter-dropdown region-level'>
      <div className='toggle-btn-wrapper'>
        <button onClick={handleExpandedFilter}>
          {selectedLanguage['Regional Report']}
          <span>{expanedFilters ? <TiArrowSortedUp /> : <TiArrowSortedDown />}</span>
        </button>
      </div>
      <div className='collapse-wrapper'>
        <SmoothCollapse expanded={expanedFilters}>
          <div className='country-stats'>
            <TotalStatsReport statsData={statsData} />
            <div className='subpanel-chart-buttons'>
              <button
                className={
                  visibleChart === 'star-threat-abatement' ? 'active no-left-border tab-buttons' : 'tab-buttons'
                }
                onClick={() => handleChartToDisplay('star-threat-abatement')}
              >
                {selectedLanguage['Conservation contribution to biodiversity']}
                <ChartTooltip chartType='Conservation' />
              </button>
              <button
                className={visibleChart === 'star-restoration' ? 'active tab-buttons' : 'tab-buttons'}
                onClick={() => handleChartToDisplay('star-restoration')}
              >
                {selectedLanguage['Restoration contribution to biodiversity']}
                <ChartTooltip chartType='Restoration' />
              </button>
              <button
                className={visibleChart === 'climate-mitigation' ? 'active tab-buttons' : 'tab-buttons'}
                onClick={() => handleChartToDisplay('climate-mitigation')}
              >
                {selectedLanguage['Restoration contribution to climate change mitigation']}
                <ChartTooltip chartType='Climate change' />
              </button>
            </div>
            <div className='charts-container'>
              {handleChartToRender('star-threat-abatement', 'threatAbatement')}
              {handleChartToRender('star-restoration', 'restoration')}
              {handleChartToRender('climate-mitigation', 'climateMitigation')}
            </div>
          </div>
        </SmoothCollapse>
      </div>
    </div>
  );
};

export default RegionStatsDropdown;
