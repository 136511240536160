import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

type CountryType = 'region' | 'country';
export type SelectedLayersIdsType = 'approved-contributions' | 'country-layer' | 'wdpa' | '';

export interface SelectedCountryOrRegionTypes {
  value: string;
  label: string;
}

interface StateTypes {
  mapLoaded: boolean;
  toggleCountry: CountryType;
  clikcedCountry: string;
  displayCountryLayer: boolean;
  selectedCountryOrRegion: SelectedCountryOrRegionTypes | null;
  countryOptions: SelectedCountryOrRegionTypes[];
  selectedContribution: SelectedCountryOrRegionTypes | null;
  selectedMapLayers: SelectedLayersIdsType[];
  strMapExtent: string;
}

const initialState: StateTypes = {
  mapLoaded: false,
  toggleCountry: 'country',
  clikcedCountry: '',
  displayCountryLayer: true,
  selectedCountryOrRegion: null,
  countryOptions: [],
  selectedContribution: null,
  selectedMapLayers: ['approved-contributions'], // contributions is the default layer
  strMapExtent: '',
};

export const mapSlice = createSlice({
  name: 'mapSlice',
  initialState,
  reducers: {
    setMapLoaded: (state, action: PayloadAction<boolean>) => {
      state.mapLoaded = action.payload;
    },
    setToggleCountry: (state, action: PayloadAction<CountryType>) => {
      state.toggleCountry = action.payload;
    },
    setClickedCountry: (state, action: PayloadAction<string>) => {
      state.clikcedCountry = action.payload;
    },
    setDisplayCountryLayer: (state, action: PayloadAction<boolean>) => {
      state.displayCountryLayer = action.payload;
    },
    setSelectedCountryOrRegion: (state, action: PayloadAction<SelectedCountryOrRegionTypes | null>) => {
      state.selectedCountryOrRegion = action.payload;
    },
    setCountryOptions: (state, action: PayloadAction<SelectedCountryOrRegionTypes[]>) => {
      state.countryOptions = action.payload;
    },
    setSelectedContribution: (state, action: PayloadAction<SelectedCountryOrRegionTypes | null>) => {
      state.selectedContribution = action.payload;
    },
    setSelectedMapLayers: (state, action) => {
      state.selectedMapLayers = action.payload;
    },
    setStrMapExtent: (state, action) => {
      state.strMapExtent = action.payload;
    },
  },
});

export const {
  setMapLoaded,
  setToggleCountry,
  setClickedCountry,
  setDisplayCountryLayer,
  setSelectedCountryOrRegion,
  setCountryOptions,
  setSelectedContribution,
  setSelectedMapLayers,
  setStrMapExtent,
} = mapSlice.actions;

export const mapLoadedSelector = (state: RootState) => state.mapSlice.mapLoaded;
export const toggleCountrySelector = (state: RootState) => state.mapSlice.toggleCountry;
export const clikcedCountrySelector = (state: RootState) => state.mapSlice.clikcedCountry;
export const displayCountryLayerSelector = (state: RootState) => state.mapSlice.displayCountryLayer;
export const selectedCountryOrRegionSelector = (state: RootState) => state.mapSlice.selectedCountryOrRegion;
export const countryOptionsSelector = (state: RootState) => state.mapSlice.countryOptions;
export const selectedContributionSelector = (state: RootState) => state.mapSlice.selectedContribution;
export const selectedMapLayersSelector = (state: RootState) => state.mapSlice.selectedMapLayers;
export const strMapExtentSelector = (state: RootState) => state.mapSlice.strMapExtent;

export default mapSlice.reducer;
