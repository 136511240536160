import { useEffect, useState } from 'react';
import SmoothCollapse from 'react-smooth-collapse';
import { TiArrowSortedDown, TiArrowSortedUp } from 'react-icons/ti';
import config from '../../configs/config';
import { mapController } from '../../controllers/MapController';
import { useDispatch, useSelector } from 'react-redux';
import { languageSelector } from '../../store/slices/appSlice';
import { TRANSLATIONS } from '../../translations/translations';
import { SelectedLayersIdsType, selectedMapLayersSelector, setSelectedMapLayers } from '../../store/slices/mapSlice';
import './styles/layerWidgetDropdown.scss';
const { layersConfig } = config;

export interface IDefaultLayerConfigTypes {
  id: SelectedLayersIdsType | string;
  showInWidgetList: boolean;
  name: string;
  url: string;
  visible: boolean;
  opacity: number;
  popupTemplate: any;
  definitionExpression: string;
  renderer: any;
}

export interface ILayerConfig extends IDefaultLayerConfigTypes {
  checked: boolean;
}

const LayerWidgetDropdown = () => {
  const [toggle, setToggle] = useState(false);
  const [layerConfigCopy, setLayerConfigCopy] = useState<ILayerConfig[]>([]);

  const language = useSelector(languageSelector);
  const selectedMapLayer = useSelector(selectedMapLayersSelector);
  const selectedLanguage = TRANSLATIONS[language];
  const dispatch = useDispatch();

  const handleLayerSelection = (event: any) => {
    const id = event.target.id;

    mapController.toggleLayerOpacity({ id, checked: event.target.checked });
    const updateSelectedProp = layerConfigCopy.map((layer) => {
      if (layer.id === id) {
        return {
          ...layer,
          checked: event.target.checked,
        };
      }
      return layer;
    });
    const filterMapLayers = updateSelectedProp
      .filter((item) => item.showInWidgetList && item.checked)
      .map((item) => item.id);

    dispatch(setSelectedMapLayers(filterMapLayers));
    setLayerConfigCopy(updateSelectedProp);
  };

  useEffect(() => {
    const updateSelectedProp = layersConfig.map((layer) => {
      const id = layer.id as SelectedLayersIdsType;
      if (selectedMapLayer.includes(id)) {
        mapController.toggleLayerOpacity({ id: layer.id, checked: true });

        return {
          ...layer,
          checked: true,
        };
      }
      mapController.toggleLayerOpacity({ id: layer.id, checked: false });

      return {
        ...layer,
        checked: false,
      };
    });

    setLayerConfigCopy(updateSelectedProp);
  }, [selectedMapLayer]);

  return (
    <div className='filter-dropdown layers-widget'>
      <div className='toggle-btn-wrapper'>
        <button onClick={() => setToggle(!toggle)}>
          {selectedLanguage['Additional Layers']}
          <div className='left-side'>
            <span className='arrow-icon'>{toggle ? <TiArrowSortedUp /> : <TiArrowSortedDown />}</span>
          </div>
        </button>
      </div>
      <div className='collapse-wrapper collapse-list'>
        <SmoothCollapse expanded={toggle}>
          <div className='layer-list'>
            {layerConfigCopy.map((layer) => {
              if (layer.showInWidgetList) {
                return (
                  <label key={layer.id}>
                    <input
                      type='checkbox'
                      id={layer.id}
                      checked={layer.checked}
                      name={layer.name}
                      onChange={handleLayerSelection}
                    />
                    <span>{layer.name}</span>
                  </label>
                );
              }
              return null;
            })}
          </div>
        </SmoothCollapse>
      </div>
    </div>
  );
};

export default LayerWidgetDropdown;
