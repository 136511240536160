import { FaFacebookF, FaTwitter, FaYoutube } from 'react-icons/fa';
import { AiFillInstagram } from 'react-icons/ai';
import { CgArrowLongRight } from 'react-icons/cg';
import './styles/footer.scss';
import { useSelector } from 'react-redux';
import { languageSelector } from '../../store/slices/appSlice';
import { TRANSLATIONS } from '../../translations/translations';

const Footer = () => {
  const language = useSelector(languageSelector);
  const selectedLanguage = TRANSLATIONS[language];

  return (
    <footer className='footer'>
      <div className='footer-wrapper'>
        <div className='footer-links'>
          <div className='colums'>
            <h3>{selectedLanguage['Commissions']}</h3>
            <nav>
              <ul>
                <li>
                  <a href='https://www.iucn.org/cem' rel='noreferrer' target='_blank'>
                    {selectedLanguage['Commission on Ecosystem Management (CEM)']}
                  </a>
                </li>
                <li>
                  <a href='https://www.iucn.org/cec' rel='noreferrer' target='_blank'>
                    {selectedLanguage['Commission on Education and Communication (CEC)']}
                  </a>
                </li>
                <li>
                  <a href='https://www.iucn.org/ceesp' rel='noreferrer' target='_blank'>
                    {selectedLanguage['Commission on Environmental, Economic and Social Policy (CEESP)']}
                  </a>
                </li>
                <li>
                  <a href='https://www.iucn.org/ssc' rel='noreferrer' target='_blank'>
                    {selectedLanguage['Species Survival Commission (SSC)']}
                  </a>
                </li>
                <li>
                  <a href='https://www.iucn.org/wcel' rel='noreferrer' target='_blank'>
                    {selectedLanguage['World Commission on Environmental Law (WCEL)']}
                  </a>
                </li>
                <li>
                  <a href='https://www.iucn.org/wcpa' rel='noreferrer' target='_blank'>
                    {selectedLanguage['World Commission on Protected Areas (WCPA)']}
                  </a>
                </li>
                <li>
                  <a href='https://www.iucn.org/about' rel='noreferrer' target='_blank'>
                    {selectedLanguage['About IUCN']}
                  </a>
                </li>
              </ul>
            </nav>
          </div>
          <div className='colums contact-column'>
            <h3 className='contact'>
              <a href='https://www.iucn.org/contact' rel='noreferrer' target='_blank'>
                {selectedLanguage['Contact']}
                <span className='next-icon'>
                  <CgArrowLongRight />
                </span>
              </a>
            </h3>
            <div className='address'>
              <h4>{selectedLanguage['Headquarters']}</h4>
              <address className='address-wrapper'>
                <span>Rue Mauverney 28 1196 Gland Switerland</span>
                <a className='phone-number' href='tel:+41229990000'>
                  +41 22 999000
                </a>
                <a href='tel:+41229990000'>+41 22 999002 (Fax)</a>
              </address>
            </div>
            <div className='border'></div>
            <nav className='media-links'>
              <h3>{selectedLanguage['Follow Us']}</h3>
              <ul>
                <li>
                  <a href='https://www.facebook.com/iucn.org' rel='noreferrer' target='_blank'>
                    <FaFacebookF />
                  </a>
                </li>
                <li>
                  <a href='https://twitter.com/IUCN/' rel='noreferrer' target='_blank'>
                    <FaTwitter />
                  </a>
                </li>
                <li>
                  <a href='https://www.youtube.com/user/IUCN/' rel='noreferrer' target='_blank'>
                    <FaYoutube />
                  </a>
                </li>
                <li>
                  <a href='https://www.instagram.com/iucn_congress/' rel='noreferrer' target='_blank'>
                    <AiFillInstagram />
                  </a>
                </li>
              </ul>
            </nav>
            <h3 className='blog'>
              <a href='https://www.iucn.org/crossroads-blog' rel='noreferrer' target='_blank'>
                {selectedLanguage['Crossroads Blog']}
                <span className='next-icon'>
                  <CgArrowLongRight />
                </span>
              </a>
            </h3>
          </div>
        </div>
        <span className='iucn-rights'>
          {selectedLanguage['©2022 IUCN,International Union for Conservations of Nature']}
        </span>
      </div>
    </footer>
  );
};

export default Footer;
