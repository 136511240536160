import { LAYER_IDS } from '../../../../configs/layersConfig';
import { mapController } from '../../../../controllers/MapController';
import {
  LanguageType,
  setCountryLevelData,
  setLanguage,
  setRegionLevelData,
  setShowHeaderRightBorder,
  setSingleContribution,
  setUserContributions,
} from '../../../../store/slices/appSlice';
import {
  CHART_IDS,
  setVisibleChartCountry,
  setVisibleChartOrg,
  setVisibleChartPanel,
  setVisibleChartRegion,
} from '../../../../store/slices/chartSlice';
import { setGlobalLoader } from '../../../../store/slices/loaderSlice';
import {
  setDisplayCountryLayer,
  setSelectedContribution,
  setSelectedCountryOrRegion,
  setSelectedMapLayers,
  setStrMapExtent,
} from '../../../../store/slices/mapSlice';
import {
  ChartContentType,
  SIDE_PANEL_IDS,
  setIsMainPanelLoading,
  setOrgsCount,
  setVisiblePanel,
} from '../../../../store/slices/sidePanelSlice';
import store from '../../../../store/store';
import { queryDataByFilter } from '../../../../utils/utils';
import { EXCLUDED_REGIONS } from '../../../mapview/createNodes/DropdownWidgetsWrapper';

export interface IQueryParams {
  where: string;
  outFields: string[];
  returnGeometry: boolean;
  num: number;
  goToLocation: boolean;
  openPopup: boolean;
}

const URL_PARAM_KEYS = {
  LANG: 'lang',
  SELECTED_MAP_LAYERS: 'selectedMapLayers',
  REGION_NAME: 'regionName',
  REGION_OBJECT_ID: 'regionObjectID',
  COUNTRY_NAME: 'countryName',
  COUNTRY_ISO: 'countryISO',
  ORG_NAME: 'orgName',
  ORG_COUNT: 'orgCount',
  PARTNER_ORGS: 'partnerOrgs',
  CONTRIBUTION_OBJECT_ID: 'contributionObjectID',
  CONTRIBUTION_NAME: 'contributionName',
  REGION_CHART: 'regionChart',
  COUNTRY_CHART: 'countryChart',
  ORG_CHART: 'orgChart',
  CONTRIBUTION_CHART: 'contributionChart',
  MAP_EXTENT: 'mapExtent',
};

let RETRIES = 0;
const MAX_RETRIES = 5;
const TWO_SECONDS = 2000;

// recursive function to check if layer is loaded and ready before querying,
//if not then wait for 2 seconds and check again
const checkIfLayerIsLoaded = async (params: IQueryParams) => {
  let foundLayer = mapController.findLayerById(LAYER_IDS.APPROVED_CONTRIBUTIONS);

  // if it has reached the max retries then stop
  if (RETRIES === MAX_RETRIES) {
    console.error('Max retries reached');
    store.dispatch(setGlobalLoader(false));
    return false;
  }

  if (foundLayer) {
    const response = await mapController.queryFeatures(params);

    if (response?.length) {
      store.dispatch(setVisiblePanel(SIDE_PANEL_IDS.CONTRIBUTION_PANEL));
      store.dispatch(setSingleContribution(response[0]));
      store.dispatch(setGlobalLoader(false));
    } else {
      store.dispatch(setGlobalLoader(false));
    }
    return true;
  } else {
    RETRIES++;
    const callBack = () => checkIfLayerIsLoaded(params);

    // Use setTimeout to schedule the next check after 2 seconds
    setTimeout(callBack, TWO_SECONDS);
  }
};

const resetRegionParams = (params: URLSearchParams) => {
  params.delete(URL_PARAM_KEYS.REGION_NAME);
  params.delete(URL_PARAM_KEYS.REGION_OBJECT_ID);
  params.delete(URL_PARAM_KEYS.REGION_CHART);
};

const resetCountryParams = (params: URLSearchParams) => {
  params.delete(URL_PARAM_KEYS.COUNTRY_NAME);
  params.delete(URL_PARAM_KEYS.COUNTRY_ISO);
  params.delete(URL_PARAM_KEYS.COUNTRY_CHART);
};

const resetOrgParams = (params: URLSearchParams) => {
  params.delete(URL_PARAM_KEYS.ORG_NAME);
  params.delete(URL_PARAM_KEYS.ORG_COUNT);
  params.delete(URL_PARAM_KEYS.PARTNER_ORGS);
  params.delete(URL_PARAM_KEYS.ORG_CHART);
};

const resetContributionParams = (params: URLSearchParams) => {
  params.delete(URL_PARAM_KEYS.CONTRIBUTION_OBJECT_ID);
  params.delete(URL_PARAM_KEYS.CONTRIBUTION_NAME);
  params.delete(URL_PARAM_KEYS.CONTRIBUTION_CHART);
};

const countryParamBulider = (params: URLSearchParams) => {
  const { countryLevelData } = store.getState().appSlice;
  const { visibleChartCountry } = store.getState().chartSlice;

  const { visiblePanel } = store.getState().sidePanelSlice;
  if (countryLevelData?.country && visiblePanel === SIDE_PANEL_IDS.COUNTRY_PANEL) {
    params.set(URL_PARAM_KEYS.COUNTRY_NAME, countryLevelData.country);
    params.set(URL_PARAM_KEYS.COUNTRY_ISO, countryLevelData.countryISO3);

    // if country is selected, then remove everything else from the url

    // reset region
    resetRegionParams(params);

    // // reset contribution
    resetContributionParams(params);

    // // reset org
    resetOrgParams(params);

    // only append chart if user selects other than default
    if (visibleChartCountry !== CHART_IDS.STAR_THREAT_ABATEMENT) {
      params.set(URL_PARAM_KEYS.COUNTRY_CHART, visibleChartCountry);
    } else {
      params.delete(URL_PARAM_KEYS.COUNTRY_CHART);
    }
  } else {
    params.delete(URL_PARAM_KEYS.COUNTRY_NAME);
    params.delete(URL_PARAM_KEYS.COUNTRY_ISO);
  }
};

const regionParamBulider = (params: URLSearchParams) => {
  const { regionLevelData } = store.getState().appSlice;
  const { visibleChartRegion } = store.getState().chartSlice;

  const { visiblePanel } = store.getState().sidePanelSlice;
  if (regionLevelData?.region && visiblePanel === SIDE_PANEL_IDS.REGION_PANEL) {
    params.set(URL_PARAM_KEYS.REGION_NAME, regionLevelData.region);
    params.set(URL_PARAM_KEYS.REGION_OBJECT_ID, String(regionLevelData.OBJECTID));

    // if region is selected, then remove everything else from the url
    // reset country
    resetCountryParams(params);

    // // reset contribution
    resetContributionParams(params);

    // // reset org
    resetOrgParams(params);

    // only append chart if user selects other than default
    if (visibleChartRegion !== CHART_IDS.STAR_THREAT_ABATEMENT) {
      params.set(URL_PARAM_KEYS.REGION_CHART, visibleChartRegion);
    } else {
      params.delete(URL_PARAM_KEYS.REGION_CHART);
    }
  } else {
    params.delete(URL_PARAM_KEYS.REGION_NAME);
    params.delete(URL_PARAM_KEYS.REGION_OBJECT_ID);
  }
};

const organzationParamBulider = (params: URLSearchParams) => {
  const selectedOrg = store.getState().appSlice.userContributions;

  const { visibleChartOrg } = store.getState().chartSlice;
  const { visiblePanel } = store.getState().sidePanelSlice;

  if (selectedOrg?.orgName && visiblePanel === SIDE_PANEL_IDS.ORGANIZATION_PANEL) {
    params.set(URL_PARAM_KEYS.ORG_NAME, selectedOrg.orgName);
    params.set(URL_PARAM_KEYS.PARTNER_ORGS, selectedOrg.partnerOrgs);
    params.set(URL_PARAM_KEYS.ORG_COUNT, String(selectedOrg.orgCount));

    // if organization is selected, then remove everything else from the url

    // reset region
    resetRegionParams(params);

    // // reset country
    resetCountryParams(params);

    // // reset contribution
    resetContributionParams(params);

    if (visibleChartOrg !== CHART_IDS.STAR_THREAT_ABATEMENT) {
      params.set(URL_PARAM_KEYS.ORG_CHART, visibleChartOrg);
    } else {
      params.delete(URL_PARAM_KEYS.ORG_CHART);
    }
  } else {
    params.delete(URL_PARAM_KEYS.ORG_NAME);
    params.delete(URL_PARAM_KEYS.ORG_COUNT);
    params.delete(URL_PARAM_KEYS.PARTNER_ORGS);
  }
};

const contributionParamBulider = (params: URLSearchParams) => {
  const { selectedContribution } = store.getState().mapSlice;
  const { visibleChartPanel } = store.getState().chartSlice;

  const { visiblePanel } = store.getState().sidePanelSlice;

  if (selectedContribution?.value && visiblePanel === SIDE_PANEL_IDS.CONTRIBUTION_PANEL) {
    params.set(URL_PARAM_KEYS.CONTRIBUTION_OBJECT_ID, selectedContribution.value);
    params.set(URL_PARAM_KEYS.CONTRIBUTION_NAME, selectedContribution.label);
    // if contribution is selected, then remove everything else from the url

    // reset region
    resetRegionParams(params);

    // // reset country
    resetCountryParams(params);

    // // reset org
    resetOrgParams(params);

    // only append chart if user selects other than default
    if (visibleChartPanel !== CHART_IDS.STAR_THREAT_ABATEMENT) {
      params.set(URL_PARAM_KEYS.CONTRIBUTION_CHART, visibleChartPanel);
    } else {
      params.delete(URL_PARAM_KEYS.CONTRIBUTION_CHART);
    }
  } else {
    params.delete(URL_PARAM_KEYS.CONTRIBUTION_OBJECT_ID);
    params.delete(URL_PARAM_KEYS.CONTRIBUTION_NAME);
  }
};

const mapExtentParamBulider = (params: URLSearchParams) => {
  const selectedOrg = store.getState().appSlice.userContributions;
  const { selectedContribution, strMapExtent } = store.getState().mapSlice;
  const { regionLevelData, countryLevelData } = store.getState().appSlice;

  if (
    !regionLevelData?.region &&
    !countryLevelData?.country &&
    !selectedOrg?.orgName &&
    !selectedContribution?.value &&
    strMapExtent
  ) {
    params.set(URL_PARAM_KEYS.MAP_EXTENT, strMapExtent);
  } else {
    params.delete(URL_PARAM_KEYS.MAP_EXTENT);
  }
};

export const urlParamBuilder = () => {
  const { selectedMapLayers } = store.getState().mapSlice;
  const { language } = store.getState().appSlice;

  const pathUrl = window.location.href;

  const url = new URL(pathUrl);
  const params = new URLSearchParams(url.search);

  if (language) {
    params.set(URL_PARAM_KEYS.LANG, language);
  }

  if (selectedMapLayers?.length) {
    params.set(URL_PARAM_KEYS.SELECTED_MAP_LAYERS, selectedMapLayers.join(','));
  } else {
    params.delete(URL_PARAM_KEYS.SELECTED_MAP_LAYERS);
  }

  // region
  regionParamBulider(params);

  // country
  countryParamBulider(params);

  // organization
  organzationParamBulider(params);

  // contribution
  contributionParamBulider(params);

  // map extent
  mapExtentParamBulider(params);

  window.history.replaceState({}, '', `${url.pathname}?${params}`);
};

// method to parse url params and set the state based on the url params keys
export const urlParamParser = async () => {
  const urlParams = new URLSearchParams(window.location.search);

  store.dispatch(setShowHeaderRightBorder(true));
  if (urlParams.get(URL_PARAM_KEYS.LANG)) {
    const lang = urlParams.get(URL_PARAM_KEYS.LANG) as LanguageType;
    store.dispatch(setLanguage(lang));
  }

  if (urlParams.get(URL_PARAM_KEYS.SELECTED_MAP_LAYERS)) {
    const lang = urlParams.get(URL_PARAM_KEYS.SELECTED_MAP_LAYERS) as LanguageType;
    store.dispatch(setSelectedMapLayers(lang.split(',')));
  }

  if (urlParams.get(URL_PARAM_KEYS.REGION_NAME)) {
    const selectedRegion = {
      label: urlParams.get(URL_PARAM_KEYS.REGION_NAME) as string,
      value: urlParams.get(URL_PARAM_KEYS.REGION_NAME) as string,
    };
    store.dispatch(setSelectedCountryOrRegion(selectedRegion));
    store.dispatch(
      setRegionLevelData({
        region: selectedRegion.label,
        OBJECTID: Number(urlParams.get(URL_PARAM_KEYS.REGION_OBJECT_ID)),
        data: [],
      })
    );

    const params = { where: `MapRegion = '${selectedRegion.value}'` };

    store.dispatch(setIsMainPanelLoading(true));
    store.dispatch(setVisiblePanel(SIDE_PANEL_IDS.REGION_PANEL));
    store.dispatch(setDisplayCountryLayer(false));

    const property = EXCLUDED_REGIONS[selectedRegion.value];

    if (property) {
      await mapController.queryCountryBasedOnRegionException(property.where, LAYER_IDS.COUNTRY_LAYER);
    }
    await mapController.handleQueryRegion(params, LAYER_IDS.REGION_LAYER, !property);
    store.dispatch(setIsMainPanelLoading(false));
  }

  if (urlParams.get(URL_PARAM_KEYS.COUNTRY_NAME)) {
    store.dispatch(setIsMainPanelLoading(true));
    store.dispatch(setVisiblePanel(SIDE_PANEL_IDS.COUNTRY_PANEL));
    store.dispatch(setDisplayCountryLayer(true));

    const countryISO = urlParams.get(URL_PARAM_KEYS.COUNTRY_ISO);

    const params = { where: `ISO3 = '${countryISO}'` };

    const selectedCountry = {
      label: urlParams.get(URL_PARAM_KEYS.COUNTRY_NAME) as string,
      value: countryISO as string,
    };
    store.dispatch(
      setCountryLevelData({ country: selectedCountry.label, countryISO3: selectedCountry.value, data: [] })
    );

    store.dispatch(setSelectedCountryOrRegion(selectedCountry));

    await mapController.handleQueryCountry(params, LAYER_IDS.COUNTRY_LAYER);
    store.dispatch(setIsMainPanelLoading(false));
  }

  if (urlParams.get(URL_PARAM_KEYS.ORG_NAME)) {
    const orgData = {
      organization: urlParams.get(URL_PARAM_KEYS.ORG_NAME),
      memberType: '',
      orgName: urlParams.get(URL_PARAM_KEYS.ORG_NAME),
      orgCount: urlParams.get(URL_PARAM_KEYS.ORG_COUNT),
      partnerOrgs: urlParams.get(URL_PARAM_KEYS.PARTNER_ORGS),
      mainOwnerOrgs: '',
      data: [],
    } as any;
    let where = `beneficiaryCountries IS NOT NULL AND ownerOrgs LIKE '%${orgData.organization}%'`;

    if (orgData.partnerOrgs) {
      where += ` OR partnerOrgs LIKE '%${orgData.partnerOrgs}%'`;
    }
    store.dispatch(setIsMainPanelLoading(true));

    store.dispatch(setUserContributions(orgData));
    store.dispatch(setOrgsCount(orgData.orgCount));
    store.dispatch(setVisiblePanel(SIDE_PANEL_IDS.ORGANIZATION_PANEL));

    await mapController.queryMapExtent(where);
    mapController.filterLayer(where);
    await queryDataByFilter(where);
  }

  if (urlParams.get(URL_PARAM_KEYS.CONTRIBUTION_NAME)) {
    const objectID = urlParams.get(URL_PARAM_KEYS.CONTRIBUTION_OBJECT_ID) as string;
    const label = urlParams.get(URL_PARAM_KEYS.CONTRIBUTION_NAME) as string;
    store.dispatch(setGlobalLoader(true));
    store.dispatch(setSelectedContribution({ value: objectID, label: label }));

    const params = {
      where: `OBJECTID = ${objectID}`,
      outFields: ['*'],
      returnGeometry: true,
      num: 0,
      goToLocation: true,
      openPopup: true,
    };

    checkIfLayerIsLoaded(params);
  }

  // set seledted chart
  if (urlParams.get(URL_PARAM_KEYS.REGION_CHART)) {
    const selectedChart = urlParams.get(URL_PARAM_KEYS.REGION_CHART) as ChartContentType;
    store.dispatch(setVisibleChartRegion(selectedChart));
  }

  if (urlParams.get(URL_PARAM_KEYS.COUNTRY_CHART)) {
    const selectedChart = urlParams.get(URL_PARAM_KEYS.COUNTRY_CHART) as ChartContentType;
    store.dispatch(setVisibleChartCountry(selectedChart));
  }
  if (urlParams.get(URL_PARAM_KEYS.ORG_CHART)) {
    const selectedChart = urlParams.get(URL_PARAM_KEYS.ORG_CHART) as ChartContentType;
    store.dispatch(setVisibleChartOrg(selectedChart));
  }
  if (urlParams.get(URL_PARAM_KEYS.CONTRIBUTION_CHART)) {
    const selectedChart = urlParams.get(URL_PARAM_KEYS.CONTRIBUTION_CHART) as ChartContentType;
    store.dispatch(setVisibleChartPanel(selectedChart));
  }

  if (urlParams.get(URL_PARAM_KEYS.MAP_EXTENT)) {
    // add extent to map
    store.dispatch(setGlobalLoader(true));

    const strExtent = urlParams.get(URL_PARAM_KEYS.MAP_EXTENT) as string;
    store.dispatch(setStrMapExtent(strExtent));

    mapController.addStrExtentToMap(strExtent);
    store.dispatch(setGlobalLoader(false));
  }
};
