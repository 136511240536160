import { ENDPOINTS } from './endpoints';

export const statisticsQueries = {
  globalLookup: {
    where: '1=1',
    url: ENDPOINTS.STAR_LOOKUP,
    statistics: [
      {
        statisticType: 'sum',
        onStatisticField: 'Score_R',

        outStatisticFieldName: 'global_score_R_total',
      },
      {
        statisticType: 'sum',
        onStatisticField: 'Score_TA',

        outStatisticFieldName: 'global_score_TA_total',
      },
    ],
    groupByFieldsForStatistics: null,
  },

  globalAggregate: {
    where: '1=1',
    url: ENDPOINTS.CFN_AGGREGATIONS,
    statistics: [
      {
        statisticType: 'sum',
        onStatisticField: 'starValueR',
        outStatisticFieldName: 'aggregate_score_R_total',
      },
      {
        statisticType: 'sum',
        onStatisticField: 'starValueTA',
        outStatisticFieldName: 'aggregate_score_TA_total',
      },
      {
        statisticType: 'sum',
        onStatisticField: 'carbon_value',
        outStatisticFieldName: 'aggregate_carbon_total',
      },
    ],
    // groupByFieldsForStatistics: ['continent'],
    groupByFieldsForStatistics: [''],
  },

  continentLookup: {
    where: '',
    url: ENDPOINTS.STAR_LOOKUP,
    statistics: [
      {
        statisticType: 'sum',
        onStatisticField: 'Score_R',

        outStatisticFieldName: 'continent_score_R_total',
      },
      {
        statisticType: 'sum',
        onStatisticField: 'Score_TA',

        outStatisticFieldName: 'continent_score_TA_total',
      },
      // {
      //   statisticType: 'sum',
      //   onStatisticField: 'carbon_value',
      //   outStatisticFieldName: 'continent_carbon_total',
      // },
    ],
    groupByFieldsForStatistics: [],
  },

  continentAggregate: {
    where: '',
    url: ENDPOINTS.CFN_AGGREGATIONS,
    statistics: [
      {
        statisticType: 'sum',
        onStatisticField: 'starValueR',
        outStatisticFieldName: 'continent_score_R_total',
      },
      {
        statisticType: 'sum',
        onStatisticField: 'starValueTA',
        outStatisticFieldName: 'continent_score_TA_total',
      },
      {
        statisticType: 'sum',
        onStatisticField: 'carbon_value',
        outStatisticFieldName: 'continent_carbon_total',
      },
    ],
    groupByFieldsForStatistics: [],
  },

  countryAggregate: {
    where: '',
    url: ENDPOINTS.CFN_AGGREGATIONS,
    statistics: [
      {
        statisticType: 'sum',
        onStatisticField: 'starValueR',
        outStatisticFieldName: 'country_score_R_total',
      },
      {
        statisticType: 'sum',
        onStatisticField: 'starValueTA',
        outStatisticFieldName: 'country_score_TA_total',
      },
      {
        statisticType: 'sum',
        onStatisticField: 'carbon_value',
        outStatisticFieldName: 'country_carbon_total',
      },
    ],
    groupByFieldsForStatistics: ['MapRegion'],
  },

  countryLookup: {
    where: '',
    url: ENDPOINTS.STAR_LOOKUP,
    statistics: [
      {
        statisticType: 'sum',
        onStatisticField: 'Score_R',

        outStatisticFieldName: 'country_score_R_total',
      },
      {
        statisticType: 'sum',
        onStatisticField: 'Score_TA',
        outStatisticFieldName: 'country_score_TA_total',
      },
    ],
    groupByFieldsForStatistics: [],
  },

  countrySecondaryDataLookup: {
    url: ENDPOINTS.APPROVED_CONTRIBUTIONS_LAYER,
    where: (country: string) => `beneficiaryCountries LIKE '%${country}%'`,
    statistics: [
      {
        onStatisticField: 'budgetNeeded',
        outStatisticFieldName: 'budget_needed',
        statisticType: 'sum',
      },
      {
        onStatisticField: 'totalBeneficiaries',
        outStatisticFieldName: 'total_beneficiaries',
        statisticType: 'sum',
      },
      {
        onStatisticField: 'totalBudget',
        outStatisticFieldName: 'total_budget',
        statisticType: 'sum',
      },
      {
        onStatisticField: 'totalStaff',
        outStatisticFieldName: 'total_staff',
        statisticType: 'sum',
      },
    ],
  },
  carbonLookupCountry: {
    url: ENDPOINTS.CARBON_COUNTRY_LOOKUP,
    statistics: [
      {
        onStatisticField: 'FLR_Total_FLR_Potential__TCO2e_',
        outStatisticFieldName: 'country_carbon_total',
        statisticType: 'sum',
      },
    ],
    groupByFieldsForStatistics: ['MapRegion', 'Country'],
  },
  starLookupCountry: {
    url: ENDPOINTS.STAR_LOOKUP,
    statistics: [
      {
        onStatisticField: 'Score_TA',
        outStatisticFieldName: 'country_TA_total',
        statisticType: 'sum',
      },
      {
        onStatisticField: 'Score_R',
        outStatisticFieldName: 'country_R_total',
        statisticType: 'sum',
      },
    ],
    groupByFieldsForStatistics: [''],
  },
  regionTA: {
    url: ENDPOINTS.REGION_TA_LOOKUP,
    statistics: [
      {
        onStatisticField: 'Score',
        outStatisticFieldName: 'region_TA_total',
        statisticType: 'sum',
      },
    ],
    groupByFieldsForStatistics: [''],
  },
  regionRestoration: {
    url: ENDPOINTS.REGION_RESTORATION_LOOKUP,
    statistics: [
      {
        onStatisticField: 'Score',
        outStatisticFieldName: 'region_Restoration_total',
        statisticType: 'sum',
      },
    ],
    groupByFieldsForStatistics: [''],
  },
  regionCarbon: {
    url: ENDPOINTS.REGION_CARBON_LOOKUP,
    statistics: [
      {
        onStatisticField: 'Total',
        outStatisticFieldName: 'region_carbon_total',
        statisticType: 'sum',
      },
    ],
    groupByFieldsForStatistics: [''],
  },
  mainPanel: {
    statistics: [
      {
        statisticType: 'count',
        onStatisticField: 'OBJECTID',
        outStatisticFieldName: 'orgs_count',
      },
    ] as any,
    groupByFieldsForStatistics: ['ownerOrgs', 'organizationMemberType', 'partnerOrgs'],
    orderByFields: ['ownerOrgs'],
  },
};
