export const mapConfig = (selectedLanguage: any) => {
  return {
    contributionTemplateOptions: {
      content: [
        {
          type: 'fields',
          fieldInfos: [
            {
              fieldName: 'name',
              label: selectedLanguage['Name'],
            },
            {
              fieldName: 'ownerOrgs',
              label: selectedLanguage['IUCN Constituent'],
            },
            {
              fieldName: 'organizationMemberType',
              label: selectedLanguage['IUCN Constituent type'],
            },
            {
              fieldName: 'startDate',
              label: selectedLanguage['Start Date'],
              format: { dateFormat: 'short-date' },
            },
            {
              fieldName: 'completionDate',
              label: selectedLanguage['End Date'],
              format: { dateFormat: 'short-date' },
            },
            {
              fieldName: 'conservationTypes',
              label: selectedLanguage['Threats to be Abated'],
            },
            {
              fieldName: 'conservationActions',
              label: selectedLanguage['Conservation Actions'],
            },
            {
              fieldName: 'flrTypes',
              label: selectedLanguage['Restoration Actions'],
            },
            {
              fieldName: 'description',
              label: selectedLanguage['Description'],
            },
            {
              fieldName: 'starValueTA',
              label: selectedLanguage['Threat Abatement'],
            },
            {
              fieldName: 'starValueR',
              label: selectedLanguage['Restoration'],
            },
          ],
        },
      ],
    },
  };
};
