import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

export const SIDE_PANEL_IDS = {
  MAIN_PANEL: 'main-panel',
  ORGANIZATION_PANEL: 'organization-panel',
  CONTRIBUTION_PANEL: 'contribution-panel',
  MEMBER_PANEL: 'member-panel',
  COUNTRY_PANEL: 'country-panel',
  REGION_PANEL: 'region-panel',
} as const;

export type VisiblePanel =
  | 'main-panel'
  | 'organization-panel'
  | 'contribution-panel'
  | 'member-panel'
  | 'country-panel'
  | 'region-panel';
export type ChartContentType = 'star-threat-abatement' | 'star-restoration' | 'climate-mitigation';

export interface AttachmentTypes {
  name: string;
  url: string;
  type: string;
}

export interface OrgsInContributionTypes {
  label: string;
  isPartner: boolean;
}

interface StateTypes {
  returnToResults: boolean;
  visiblePanel: VisiblePanel;
  isMemberpanelVisible: boolean;
  countryLevelVisibleChart: ChartContentType;
  organizationVisibleChart: ChartContentType;
  expandedFilters: boolean;
  globalExpanded: boolean;
  isCountryLoading: boolean;
  isContributionLoading: boolean;
  isMainPanelLoading: boolean;
  orgsCount: number;
  organizattionData: any;
  selectedOrganizationName: string;
  isSearchingActive: boolean;
  contributionAttachments: AttachmentTypes[];
  orgsInContributionList: OrgsInContributionTypes[];
}

const initialState: StateTypes = {
  returnToResults: false,
  visiblePanel: SIDE_PANEL_IDS.MAIN_PANEL,
  isMemberpanelVisible: true,
  countryLevelVisibleChart: 'star-threat-abatement',
  organizationVisibleChart: 'star-threat-abatement',
  expandedFilters: false,
  globalExpanded: true,
  isCountryLoading: false,
  isContributionLoading: false,
  isMainPanelLoading: false,
  orgsCount: 0,
  organizattionData: [],
  selectedOrganizationName: '',
  isSearchingActive: false,
  contributionAttachments: [],
  orgsInContributionList: [],
};

export const sidePanelSlice = createSlice({
  name: 'sidePanelSlice',
  initialState,
  reducers: {
    setReturnToResults: (state, action: PayloadAction<boolean>) => {
      state.returnToResults = action.payload;
    },
    setVisiblePanel: (state, action: PayloadAction<VisiblePanel>) => {
      state.visiblePanel = action.payload;
    },
    setIsMemberPanelVisible: (state, action: PayloadAction<boolean>) => {
      state.isMemberpanelVisible = action.payload;
    },
    setChartContentType: (state, action: PayloadAction<ChartContentType>) => {
      state.countryLevelVisibleChart = action.payload;
    },
    setExpandedFilters: (state, action: PayloadAction<boolean>) => {
      state.expandedFilters = action.payload;
    },
    setGlobalExpanded: (state, action: PayloadAction<boolean>) => {
      state.globalExpanded = action.payload;
    },
    setIsCountryLoading: (state, action: PayloadAction<boolean>) => {
      state.isCountryLoading = action.payload;
    },
    setIsContributionLoading: (state, action: PayloadAction<boolean>) => {
      state.isContributionLoading = action.payload;
    },
    setIsMainPanelLoading: (state, action: PayloadAction<boolean>) => {
      state.isMainPanelLoading = action.payload;
    },
    setOrgsCount: (state, action: PayloadAction<number>) => {
      state.orgsCount = action.payload;
    },
    setOrganizationData: (state, action: PayloadAction<any>) => {
      const current = state.organizattionData;
      if (action.payload.keepPrevious) {
        state.organizattionData = [...current, ...action.payload.data];
      } else {
        state.organizattionData = action.payload.data;
      }
    },
    setSelectedOrganizationName: (state, action: PayloadAction<string>) => {
      state.selectedOrganizationName = action.payload;
    },
    setIsSearchingActive: (state, action: PayloadAction<boolean>) => {
      state.isSearchingActive = action.payload;
    },
    setContributionAttachments: (state, action: PayloadAction<AttachmentTypes[]>) => {
      state.contributionAttachments = action.payload;
    },
    setOrgsInContributionList: (state, action: PayloadAction<OrgsInContributionTypes[]>) => {
      state.orgsInContributionList = action.payload;
    },
  },
});

export const {
  setReturnToResults,
  setVisiblePanel,
  setIsMemberPanelVisible,
  setChartContentType,
  setExpandedFilters,
  setGlobalExpanded,
  setIsCountryLoading,
  setIsContributionLoading,
  setIsMainPanelLoading,
  setOrgsCount,
  setOrganizationData,
  setSelectedOrganizationName,
  setIsSearchingActive,
  setContributionAttachments,
  setOrgsInContributionList,
} = sidePanelSlice.actions;

export const returnToResultsSelector = (state: RootState) => state.sidePanelSlice.returnToResults;
export const visiblePanelsSelector = (state: RootState) => state.sidePanelSlice.visiblePanel;
export const isMemberPanelVisibleSelector = (state: RootState) => state.sidePanelSlice.isMemberpanelVisible;
export const countryLevelVisibleChartSelector = (state: RootState) => state.sidePanelSlice.countryLevelVisibleChart;
export const globalExpandedSelector = (state: RootState) => state.sidePanelSlice.globalExpanded;
export const expandedFiltersSelector = (state: RootState) => state.sidePanelSlice.expandedFilters;
export const isCountryLoadingSelector = (state: RootState) => state.sidePanelSlice.isCountryLoading;
export const isContributionLoadingSelector = (state: RootState) => state.sidePanelSlice.isContributionLoading;
export const isMainPanelLoadingSelector = (state: RootState) => state.sidePanelSlice.isMainPanelLoading;
export const orgsCountSelector = (state: RootState) => state.sidePanelSlice.orgsCount;
export const organizattionDataSelector = (state: RootState) => state.sidePanelSlice.organizattionData;
export const selectedOrganizationNameSelector = (state: RootState) => state.sidePanelSlice.selectedOrganizationName;
export const isSearchingActiveSelector = (state: RootState) => state.sidePanelSlice.isSearchingActive;
export const contributionAttachmentsSelector = (state: RootState) => state.sidePanelSlice.contributionAttachments;
export const orgsInContributionListSelector = (state: RootState) => state.sidePanelSlice.orgsInContributionList;

export default sidePanelSlice.reducer;
