export const es = {
  Home: 'Inicio',
  'About IUCN': 'Acerca UICN',
  'Add Contributions': 'Añadir Contribuciones',
  'View Contributions': 'Ver Contribuciones',
  'IUCN logo': 'IUCN logo',

  'Share url': 'Compartir URL',
  Copied: 'Copiado',

  //
  'Welcome to the IUCN': 'Bienvenido a la Plataforma de Contribuciones para la Naturaleza de la UICN',
  'Contributions for Nature Platform': 'Plataforma de Contribuciones para la Naturaleza',

  // documents
  'Data Entry Module - Guidance': 'Módulo de entrada de datos - Guía',
  'Validation Module - Guidance': 'Módulo de validación - Guía',
  'Frequently Asked Questions (FAQs)': 'Preguntas frecuentas',
  'Terms and Conditions': 'Términos y condiciones',

  //
  'What progress are conservation actions making towards global goals for nature?':
    '¿Qué progresos están haciendo las acciones de conservación hacia los objetivos globales para la naturaleza?',
  'The IUCN Programme Nature 2030, and other agreements have established targets to stem and ultimately reverse this loss. For many decades, the global conservation community has been implementing actions that contribute towards these global goals for nature. It is crucial that we collectively document and understand these contributions for nature, where and when they are being implemented, by who, and how much they are contributing towards global goals This IUCN Contributions for Nature platform, mandated by the IUCN Membership in Nature 2030 addresses these questions.':
    'El Programa Naturaleza 2030 de la UICN y otros acuerdos han establecido objetivos para frenar y, en última instancia, invertir esta pérdida. Durante muchas décadas, la comunidad conservacionista mundial ha llevado a cabo acciones que contribuyen a estos objetivos globales para la naturaleza. Es crucial que documentemos y entendamos colectivamente estas contribuciones para la naturaleza, dónde y cuándo se están implementando, por quién, y en qué medida están contribuyendo a los objetivos globales. Esta plataforma de Contribuciones para la Naturaleza de la UICN, encomendada por la Membresía de la UICN en Naturaleza 2030, aborda estas cuestiones.',

  'See what the conservation community is contributing towards global goals for nature.':
    'Vea lo que la comunidad conservacionista está aportando a los objetivos globales de la naturaleza.',
  'Use the Contributions for Nature platform to view contributions being made or planned by IUCN constituents - including non-governmental organisations, indigenous peoples organisations, governments, state agencies, and regional and local governments, as well as the IUCN Commissions, Committees, and Secretariat. You can also use the platform to quantify these contributions in terms of their potential delivery of global goals for biodiversity conservation and climate change mitigation. This can be achieved through the use of the Species Threat Abatement and Restoration Metric (STAR) and the Restoration Barometer.':
    'Utilice la plataforma Contribuciones para la Naturaleza para ver las contribuciones realizadas o previstas por los constituyentes de la UICN -incluyendo organizaciones no gubernamentales, organizaciones de pueblos indígenas, gobiernos, organismos estatales y gobiernos regionales y locales, así como las Comisiones, Comités y Secretaría de la UICN. También puede utilizar la plataforma para cuantificar estas contribuciones en términos de su potencial para alcanzar los objetivos mundiales de conservación de la biodiversidad y mitigación del cambio climático. Esto puede lograrse mediante el uso de la Métrica de Reducción de la Amenaza de Especies y Restauración (STAR) y el Barómetro de Restauración.',

  "Document your Organisation's Land Contributions": 'Documente las contribuciones de su organización a la Tierra',
  'The Contributions for Nature platform allows IUCN constituents to add contributions by entering spatial data describing their current or planned conservation or restoration actions, along with associated data on types of threats being mitigated and types of actions underway, timeframes, and level of investment.':
    'La plataforma Contribuciones para la Naturaleza permite a los constituyentes de la UICN añadir contribuciones introduciendo datos espaciales que describan sus acciones de conservación o restauración actuales o previstas, junto con datos asociados sobre los tipos de amenazas que se están mitigando y los tipos de acciones en curso, los plazos y el nivel de inversión.',
  'Currently, only IUCN Members (including in National and Regional Committees), Commissions and Secretariat can add contributions to the platform.':
    'Actualmente, sólo los miembros de la UICN (incluidos los comités nacionales y regionales), las Comisiones y la Secretaría pueden añadir contribuciones a la plataforma.',
  'The Contributions for Nature platform draws from the following datasets and platforms':
    'La plataforma Contribuciones para la Naturaleza se basa en los siguientes conjuntos de datos y plataformas',

  // footer

  'Commission on Ecosystem Management (CEM)': 'Comisión Mundial de Derecho Ambiental (CMDA)',
  'Commission on Education and Communication (CEC)': 'Comisión Mundial de Áreas Protegidas (CMAP)',
  'Commission on Environmental, Economic and Social Policy (CEESP)':
    'Comisión para la Supervivencia de las Especies (CSE)',
  'Species Survival Commission (SSC)': 'Educación y Comunicación (CEC)',
  'World Commission on Environmental Law (WCEL)': 'Gestión de Ecosistemas (CGE)',
  'World Commission on Protected Areas (WCPA)': 'Política Ambiental Económica y Social (CPAES)',
  Contact: 'Contacto',
  Headquarters: 'Sede Central',
  'Follow Us': 'Síguenos',
  'Crossroads Blog': 'Blog Crossroads',
  '©2022 IUCN,International Union for Conservations of Nature':
    '©2022 UICN, Unión Internacional para la Conservación de la Naturaleza',

  // main panel
  'Search & Filter Contributions': 'Buscar y Filtrar Contribuciones',
  'You will only see filters for which we have data': 'Sólo verá los filtros de los que tenemos datos',
  'Search Constituents': 'Buscar constituyentes',

  'Quick Global Stats': 'Estadísticas globales',
  'Close Statistics': 'Cerras Estadísticas',
  'Expand Statistics': 'Expandir Estadísticas',
  'Conservation contribution to biodiversity': 'Contribución a la conservación de la biodiversidad',
  'Restoration contribution to biodiversity': 'Contribución de la restauración a la biodiversidad',
  'Restoration contribution to climate change mitigation':
    'Contribución de la restauración a la mitigación del cambio climático',
  'Filter by IUCN constituent type': 'Filtrar por tipo de constituyente de la UICN',
  'View Records': 'Ver Registros',
  'No results found!': 'No se han encontrado resultados',

  // org panel
  'No Data': 'No hay datos para mostrar',
  'Return to Results': 'Volver a Resultados',
  'Total number of contributions': 'Cantidad total de contribuciones',
  'STAR Threat Abatement Score': 'Puntuación de reducción de las amenazas STAR',
  'STAR Restoration Score': 'Puntaje de la Restauración STAR',
  'Total Climate Change Mitigation': 'Mitigación total del cambio climático',
  'Total Budget': 'Presupuesto Total',
  'Total Budget needed': 'Presupuesto Total Necesario',
  'Total number of Staff': 'Número total de personal',
  'Total number of Beneficiaries': 'Número total de beneficiarios',
  'No description available': 'No hay descripción disponible',

  Contributions: 'Contribuciones',
  'Search for contribution name': 'Buscar por Nombre de Contribución',
  'No contributions found!': 'No se han encontrado contribuciones',
  Loading: 'Cargando',

  //country panel
  'National Report': 'Informe nacional',
  total: 'total',

  /// region panel
  'Regional Report': 'Informe regional',

  //contribution level
  'Select Organization': 'Selecciona Organizacion',
  Partner: 'socio',
  Owner: 'Dueño',

  // charts

  'Star Restoration': 'Restauración STAR',
  Global: 'Global',
  Contribution: 'Contribucion',
  'Threat Abatement': 'Reducción de las amenazas',
  'Climate Mitigation': 'Mitigación del cambio climático',
  'Global total': 'Total mundial',

  // contribution charts
  Restoration: 'Restauración',
  'Contribution total': 'Contribución Total',
  'Contribution to Land region Climate Mitigation': 'Contribution to Land region Climate Mitigation',

  'Potential contribution of abating different threats towards species extinction risk reduction':
    'Contribución potencial de la reducción de diferentes amenazas a la reducción del riesgo de extinción de especies',

  'Potential contribution of restoration towards species extinction risk reduction':
    'Contribución potencial de la restauración a la reducción del riesgo de extinción de las especies ',
  //
  "Contribution's potential contribution towards climate change mitigation through restoration as a proportion of the total global total climate mitigation.":
    'Contribución potencial a la mitigación del cambio climático a través de la restauración como proporción del total de la mitigación climática global. ',
  'Contribution as a proportion of the global total potential contribution towards species extinction risk reduction by restoration.':
    'Contribución como proporción del total global de la contribución potencial a la reducción del riesgo de extinción de especies mediante la restauración.',
  //
  'Contribution as a proportion of the global total potential contribution towards species extinction risk reduction by abating threats.':
    'Contribución como proporción del total global de la contribución potencial a la reducción del riesgo de extinción de especies mediante la disminución de las amenazas.',
  'Contribution as a proportion of the global total potential contribution towards species extinction risk reduction by abating threats':
    'Contribución como proporción del total global de la contribución potencial a la reducción del riesgo de extinción de especies mediante la disminución de las amenazas.',
  'Potential contribution towards climate change mitigation through restoration as a proportion of the total':
    'la contribución potencial a la mitigación del cambio climático a través de la restauración como proporción del total de',
  'potential contribution': 'contribución potencial',
  'Potential contribution towards climate change mitigation through restoration as a proportion of the total global potential contribution':
    'contribución potencial a la mitigación del cambio climático a través de la restauración como proporción de la contribución potencial global total',
  'Contribution as a proportion of': 'Contribución como proporción de',
  'total potential contribution towards species extinction risk reduction by restoration.':
    'potencial total de la contribución a la reducción del riesgo de extinción de las especies por restauración',
  'Potential contribution towards species extinction risk reduction through restoration as a proportion of the total global potential contribution':
    'contribución potencial a la reducción del riesgo de extinción de las especies a través de la restauración, como proporción de la contribución potencial global total',
  'total potential contribution towards species extinction risk reduction by abating threat':
    'contribución potencial total a la reducción del riesgo de extinción de especies mediante la disminución de las amenazas',
  'Potential contribution towards species extinction risk reduction by abating threats as a proportion of the total global potential contribution':
    'contribución potencial de las especies a la reducción del riesgo de extinción mediante la disminución de las amenazas como proporción de la contribución potencial total mundial',
  "Contribution's potential contribution towards climate change mitigation through restoration as a proportion of the total":
    'Contribución potencial a la mitigación del cambio climático a través de la restauración como proporción del total ',
  'Potential contribution towards species extinction risk reduction through restoration as a proportion of the potential contribution from':
    'Contribución potencial a la reducción del riesgo de extinción de especies a través de la restauración, como proporción de la contribución potencial de',
  'Potential contribution towards species extinction risk reduction by abating threats as a proportion of the potential contribution from':
    'Contribución potencial a la reducción del riesgo de extinción de especies mediante la disminución de las amenazas como proporción de la contribución potencial de',
  overall: 'en general',

  // layer widget dropdown
  'Additional Layers': 'Capas Adicionales',
  // select country/region
  'Select by': 'Seleccionar por',
  Region: 'Región',
  Continent: 'Continente',
  Country: 'País',
  'Land region': 'Región terrestre ',
  'select country': 'Seleccionar país',
  'select land region': 'Seleccione la región terrestre ',
  'select region': 'Seleccionar region',
  'select continent': 'Seleccione continente',
  'select contribution': 'Seleccionar contribucion',

  // column chart
  '% Contribution to Project': '% de Contribución al Proyecto',
  Percent: 'Porcentaje',
  'Threat Type': 'Tipo de amenaza ',
  'No data available': 'No hay datos disponibles',
  'Percentage value': 'Porcentaje',
  'Absolute value': 'Valor absoluto',

  // global chart modal content
  Method: 'Método',
  'Methods for generating Conservation and Restoration contributions towards biodiversity goals Contributions towards biodiversity goals are generated using the Species Threat Abatement and Restoration (STAR) Metric, which quantifies the potential reduction in global species extinction risk that can be achieved by acting through threat abatement or restoration in a specific area (Mair et al., 2021).':
    'Métodos para generar las contribuciones de conservación y restauración a los objetivos de biodiversidad. Las contribuciones hacia los objetivos de biodiversidad se generan utilizando la Métrica de Reducción de Amenazas y Restauración de Especies (STAR), que cuantifica la reducción potencial del riesgo global de extinción de especies que puede lograrse actuando mediante la reducción de amenazas o la restauración en un área específica (Mair et al., 2021).',

  'Data Sources': 'Fuentes de datos',

  "Method: STAR scores are calculated for species of amphibians, birds and mammals for which current or historical area of habitat occurs in the Area of Interest. Species assessed as Near Threatened, Vulnerable, Endangered or Critically Endangered on the IUCN Red List of Threatened Species receive non-zero weightings in calculating the STAR score. For each 5x5 km grid square, the STAR Threat Abatement (STAR TA) score for a species is assessed based on the proportion of current range represented by the grid square, and the species extinction risk. Adding STAR scores across all species gives the total STAR score for the square. Adding all the STAR scores across all the squares in a particular defined area indicates that site's relative conservation contribution towards biodiversity goals.":
    'Método: Las puntuaciones STAR se calculan para las especies de anfibios, aves y mamíferos para las que existe una superficie actual o histórica de hábitat en el Área de Interés. Las especies evaluadas como Casi Amenazadas, Vulnerables, En Peligro o En Peligro Crítico en la Lista Roja de Especies Amenazadas de la UICN reciben ponderaciones distintas de cero en el cálculo de la puntuación STAR. Para cada cuadrícula de 5x5 km, la puntuación STAR de reducción de la amenaza (STAR TA) de una especie se evalúa en función de la proporción del área de distribución actual representada por la cuadrícula y el riesgo de extinción de la especie. Sumando las puntuaciones STAR de todas las especies se obtiene la puntuación STAR total del cuadrado. La suma de todas las puntuaciones STAR de todos los cuadrados de una zona determinada indica la contribución relativa de ese lugar a la conservación de los objetivos de biodiversidad.',

  'Restoration contributions towards biodiversity goals (STAR R) are calculated similarly to STAR Threat Abatement Scores, but for areas formerly occupied by a species. They indicate the potential of restoration within a grid cell to reduce species extinction risk. A global weighting of 0.29 is applied to STAR Restoration scores based on studies of restoration success, to discount for the time taken for restoration and its probability of success.':
    ' Las contribuciones de restauración hacia los objetivos de biodiversidad (STAR R) se calculan de forma similar a las puntuaciones STAR de reducción de amenazas, pero para las áreas anteriormente ocupadas por una especie. Indican el potencial de la restauración dentro de una cuadrícula para reducir el riesgo de extinción de las especies. Se aplica una ponderación global de 0,29 a las puntuaciones STAR de restauración, basada en estudios sobre el éxito de la restauración, para descontar el tiempo que se necesita para la restauración y su probabilidad de éxito. ',

  'Pie charts visualizing the STAR TA and STAR R contributions in a site as a proportion of the maximum potential contribution that could be generated in terrestrial United Nations Statistical Division M49 Geographic Regions and Countries.':
    'Gráficos circulares que visualizan las contribuciones de STAR TA y STAR R en un sitio como proporción de la contribución potencial máxima que podría generarse en regiones geográficas y países terrestres de la División Estadística de las Naciones Unidas M49.',

  'Data used to compute STAR': 'Datos utilizados para calcular STAR',

  'The underlying STAR data is produced from the IUCN Red List of Threatened Species (iucnredlist.org)':
    'Los datos subyacentes de STAR se obtienen de la Lista Roja de Especies Amenazadas de la UICN (iucnredlist.org)',

  "Method: STAR scores are calculated for species of amphibians, birds and mammals for which current or historical area of habitat occurs in the Area of Interest. Species assessed as Near Threatened, Vulnerable, Endangered or Critically Endangered on the IUCN Red List of Threatened Species receive non-zero weightings in calculating the STAR score. For each 5x5 km grid square, the STAR Threat Abatement (STAR TA) score for a species is assessed based on the proportion of current range represented by the grid square, and the species extinction risk. Adding STAR scores across all species gives the total STAR score for the square. Adding all the STAR scores across all the squares in a particular defined area indicates that site's relative  conservation contribution towards biodiversity goals.":
    'Método: Las puntuaciones STAR se calculan para las especies de anfibios, aves y mamíferos para las que existe una superficie actual o histórica de hábitat en el Área de Interés. Las especies evaluadas como Casi Amenazadas, Vulnerables, En Peligro o En Peligro Crítico en la Lista Roja de Especies Amenazadas de la UICN reciben ponderaciones distintas de cero en el cálculo de la puntuación STAR. Para cada cuadrícula de 5x5 km, la puntuación STAR de reducción de la amenaza (STAR TA) de una especie se evalúa en función de la proporción del área de distribución actual representada por la cuadrícula y el riesgo de extinción de la especie. Sumando las puntuaciones STAR de todas las especies se obtiene la puntuación STAR total del cuadrado. La suma de todas las puntuaciones STAR de todos los cuadrados de una zona determinada indica la contribución relativa de ese lugar a la conservación de los objetivos de biodiversidad.',

  'Methods for generating Restoration contributions towards climate change mitigation goals Contributions towards carbon sequestration goals are generated through the IUCN- Winrock FLR removal database, which provides the potential impacts of climate mitigation based on the global carbon dioxide (CO2) removal rates for the selective FLR intervention activities. The carbon sequestration potential of restoration activities iscalculated by multiplying the restoration area and region-specific carbon removal factors.':
    'Métodos para generar las contribuciones de la restauración a los objetivos de mitigación del cambio climático. Las contribuciones hacia los objetivos de secuestro de carbono se generan a través de la base de datos de eliminación de IUCN- Winrock FLR, que proporciona los impactos potenciales de la mitigación del clima basados en las tasas globales de eliminación de dióxido de carbono (CO2) para las actividades de intervención de FLR selectiva. El potencial de secuestro de carbono de las actividades de restauración se calcula multiplicando el área de restauración y los factores de eliminación de carbono específicos de la región.',

  'The carbon removal factors are derived from the Global FLR CO2 removal database, developed by IUCN in partnership with Winrock international. This database offers potential CO2 removals from specific FLR actions (i.e. natural regeneration, planted forests and woodlots, agroforestry and mangrove restoration) at both national and subnational scales.':
    'Los factores de eliminación de carbono se derivan de la base de datos de eliminación de Global FLR CO2, desarrollada por la UICN en colaboración con Winrock international. Esta base de datos ofrece las potenciales eliminaciones de CO2 de acciones específicas de FLR (es decir, regeneración natural, bosques plantados y arboledas, agrosilvicultura y restauración de manglares) tanto a escala nacional como subnacional.',

  'The carbon removal factors are derived from the Global FLR CO2 removal database, developed by IUCN in partnership with Winrock international. This database offers potential CO2 removals from specific FLR actions (i.e. natural regeneration, planted forests and woodlots, agroforestry and mangrove restoration) at both national and subnational scales. The carbon removal rate (i.e removal factor) database was developed by reviewing the literature on biomass accumulation rates from FLR activities across the globe, using published and scientifically validated data. All these collected data points are converted into spatial points and also overlaid on the global Koppen-Geiger Climate classification to determine the climate of the FLR sites. The aboveground biomass curve was developed by Chapman Richards growth model applied by collating data points over 144 studies whereby the belowground biomass was estimated as a function of the calculated aboveground biomass. For each FLR activity, the sum of the aboveground and belowground biomass growth rates are presented as total tons C ha-1 year-1 and then converted to annual removal factors as carbon dioxide (tons CO2 ha-1 year-1) by multiplying the tons of C by the factor 3.66 (i.e., 44 g CO2 over 12 g C).':
    'La base de datos de la tasa de remoción de carbono (es decir, el factor de remoción) se desarrolló revisando la literatura sobre las tasas de acumulación de biomasa de las actividades de FLR en todo el mundo, utilizando datos publicados y científicamente validados. Todos estos puntos de datos recopilados se convirtieron en puntos espaciales y también se superpusieron a la clasificación climática global de Koppen-Geiger para determinar el clima de los sitios de FLR. La curva de biomasa sobre el suelo se desarrolló mediante el modelo de crecimiento de Chapman Richards aplicado al cotejar los puntos de datos sobre 144 estudios, por lo que la biomasa bajo el suelo se estimó en función de la biomasa sobre el suelo calculada. Para cada actividad de FLR, la suma de las tasas de crecimiento de la biomasa aérea y subterránea se presenta como toneladas totales de C ha-1 año-1 y luego se convierte en factores de remoción anual como dióxido de carbono (toneladas de CO2 ha-1 año-1) multiplicando las toneladas de C por el factor 3,66 (es decir, 44 g de CO2 sobre 12 g de C).',

  'Pie charts visualizing the carbon sequestration contributions in a site as a proportion to countries FLR basedrestoration commitments potential of climate mitigation. The Bonn Challenge and RIO conventions pledges from 115 countries are used for this pie chart.':
    'Gráficos circulares que visualizan las contribuciones de secuestro de carbono en un sitio como proporción del potencial de mitigación climática de los compromisos de restauración basados en la FLR de los países. Para este gráfico circular se han utilizado las promesas del Desafío de Bonn y de los convenios de la RIO de 115 países.',

  'Bernal, Blanca, Lara T. Murray, and Timothy RH Pearson. "Global carbon dioxide removal rates from forest landscape restoration activities." Carbon balance and management 13.1 (2018): 1-13.':
    'Bernal, Blanca, Lara T. Murray, and Timothy RH Pearson. "Global carbon dioxide removal rates from forest landscape restoration activities." Carbon balance and management 13.1 (2018): 1-13.', //TODO: missing

  'Data used to compute Pie Charts': 'Datos utilizados para calcular gráficos circulares',

  'Sewell, Annelies, Stefan van der Esch, and Hannah Löwenhardt. "Goals and Commitments for the Restoration Decade." Sewell, Annelies, H. Löwenhardt, and S. Van der Esch. "Technical Note on Methodology for the Global Restoration Commitments Database." PBL Netherlands Environmental Assessment Agency, The Hague (2020).':
    'Sewell, Annelies, Stefan van der Esch, and Hannah Löwenhardt. "Goals and Commitments for the Restoration Decade." Sewell, Annelies, H. Löwenhardt, and S. Van der Esch. "Technical Note on Methodology for the Global Restoration Commitments Database." PBL Netherlands Environmental Assessment Agency, The Hague (2020).', //TODO: missing

  // map footer

  'The designations employed and the presentation of material on this map do not imply the expression of any opinion whatsoever on the part of IUCN concerning the legal status of any country, territory, city or area or its authorities, or concerning the delimitation of its frontiers or boundaries.':
    'Las designaciones empleadas y la presentación del material en este mapa no implican la expresión de ninguna opinión por parte de la UICN sobre el estatus legal de ningún país, territorio, ciudad o área o sus autoridades, o sobre la delimitación de sus fronteras o límites.',
  'Powered by Esri': 'Desarrollado por ESRI',
  'Send Feedback': 'Enviar comentarios',

  // countryChartDataBuilder
  'Threat Abatement Land region-Continent': 'Reducción de las amenazas Región-Continente',
  'Threat Abatement Continent-Global': 'Reducción de las amenazas Continente-Global',
  'Restoration Land region - Continent': 'Restauración Área Terrestre-Continente',
  'Restoration Continent-Global': 'Restauración Continente-Global',
  'Climate Mitigation Land region - Continent': 'Mitigación del clima Área Terrestre - Continente',
  'Climate Mitigation Continent-Global': 'Mitigación del clima Continente-Global ',

  'Climate Mitigation Contribution-Land region': 'Contribución a la mitigación del clima - Área Terrestre',
  'Restoration Contribution-Land region': 'Contribución a la restauración- Área Terrestre',
  'Threat Abatement Contribution-Land region': 'Contribución a la reducción de las amenazas - Área terrestre',

  'Land region to continent Climate Mitigation':
    'Región terrestre del continente a la mitigación del cambio climático ',

  'Continent to global Climate Mitigation': 'Continente a mitigación del cambio climatico global ',
  // fetchOrgChartData
  'Global Comparison': 'Comparación global',
  'Regional Comparison': 'Comparación regional',
  'Land region Comparison': 'Comparación de región terrestre',

  'Total Staff': 'Total del personal',
  'Total Beneficiares': 'Total de beneficiarios ',
  'Total Budget Needed': 'Presupuesto Total Necesario',

  // popup template
  Name: 'Nombre',
  'IUCN Constituent': 'Contituyente UICN',
  'IUCN Constituent type': 'Tipo de constituyente UICN',
  'Start Date': 'Fecha de Inicio',
  'End Date': 'Fecha de Finalización',
  'Threats to be Abated': 'Amenazas por Reducir',
  'Conservation Actions': 'Acciones de Conservación',
  'Restoration Actions': 'Acciones de Restauración',
  Description: 'Descripción',
  //
  'Partner(s)': 'Socio(s)',

  //temp login
  'International Union for Conservation of Nature': 'International Union for Conservation of Nature',
  'Use your IUCN credentials to login.': 'Use your IUCN credentials to login.',
  'User Name': 'User Name',
  Password: 'Password',
  Login: 'Acceso',
};
