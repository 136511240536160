import PieChartWithoutWrapper from '../charts/PieChartWithoutWrapper';
import './chartPanels.scss';

interface Props {
  chartData: any;
}

const ChartPanels = ({ chartData }: Props) => {
  return (
    <div className='charts-wrapper'>
      {chartData.data &&
        !!chartData.data.length &&
        chartData.data.map((data: any, index: number) => (
          <div className='pie-chart' key={index}>
            <h3>
              {chartData.header[index]}
              <span>{chartData.subHeader[index]}</span>
            </h3>
            <div className='chart'>
              <PieChartWithoutWrapper data={data.data} />
            </div>
          </div>
        ))}
    </div>
  );
};

export default ChartPanels;
