import Select, { createFilter } from 'react-select';
import './styles/selectComponent.scss';

interface Props {
  isMulti: boolean;
  options: any;
  value: any;
  styles?: any;
  onChange: Function;
  isDisabled: boolean;
  isClearable?: boolean;
  placeholder?: string;
}

const SelectComponent = ({
  isMulti,
  options,
  value,
  onChange,
  styles,
  isDisabled,
  isClearable = true,
  placeholder,
}: Props) => {
  return (
    <div className='SelectComponent'>
      <Select
        className='custom-select-container'
        classNamePrefix={'custom-select'}
        isMulti={isMulti}
        options={options}
        styles={styles}
        filterOption={createFilter({ ignoreAccents: false })}
        maxMenuHeight={200}
        minMenuHeight={200}
        value={value}
        onChange={(e: any) => onChange(e)}
        isClearable={isClearable}
        isDisabled={isDisabled}
        placeholder={placeholder}
      />
    </div>
  );
};

export default SelectComponent;
