import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '@material-ui/lab/Pagination';
import { AiFillCaretLeft } from 'react-icons/ai';
import {
  countryLevelVisibleChartSelector,
  setOrgsCount,
  setSelectedOrganizationName,
  setVisiblePanel,
} from '../../../store/slices/sidePanelSlice';
import {
  countryLevelDataSelector,
  languageSelector,
  setCountryLevelData,
  setRegionLevelData,
  setUserContributions,
} from '../../../store/slices/appSlice';
import { letters } from '../../../configs/lettersData';
import './styles/sidePanel.scss';
import './styles/sidePanelCountry.scss';

import CountryStatsDropdown from '../../Dropdowns/CountryStatsDropdown';
import { mapController } from '../../../controllers/MapController';
import { countryChartDataBuilder } from '../../../utils/countryLevelChartData';
import { getFeatures, queryDataStatistics } from '../../../utils/fetchData';
import { statisticsQueries } from '../../../configs/statisticsQueries';
import { GLOBAL_VALUES } from '../../../configs/globalValues';
import { ENDPOINTS } from '../../../configs/endpoints';
import { getCarbonTotal, getTotalByProp } from '../../../utils/helperMethods';
import ShowRecordsComponent from '../../showRecordsComponent/ShowRecordsComponent';
import { GlobalDataTypes } from './types';
import { setSelectedCountryOrRegion } from '../../../store/slices/mapSlice';
import { TRANSLATIONS } from '../../../translations/translations';
import { visibleChartCountrySelector } from '../../../store/slices/chartSlice';
const { regionTA, regionRestoration, regionCarbon, countryLookup, carbonLookupCountry, countryAggregate } =
  statisticsQueries;

const globalDataDefaultValues = {
  carbonValue: 0,
  starValueRTotal: 0,
  starValueTATotal: 0,
  totalBudget: 0,
  totalBudgetNeeded: 0,
  totalBeneficiaries: 0,
  totalStaff: 0,
};

const SidePanelCountry = () => {
  const [expanedFilters, setExpandedFilters] = useState(true);
  const [dataCopy, setDataCopy] = useState<any>([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [lettersData, setLettersData] = useState(letters);
  const [statsData, setStatsData] = useState<GlobalDataTypes>(globalDataDefaultValues);
  const [countryLevelChartData, setCountryLevelChartData] = useState<any>([]);
  const countryLevelData = useSelector(countryLevelDataSelector);
  const countryLevelVisibleChart = useSelector(countryLevelVisibleChartSelector); // todo: remove variable

  const visibleChartCountry = useSelector(visibleChartCountrySelector);

  const language = useSelector(languageSelector);
  const selectedLanguage = TRANSLATIONS[language];

  const dispatch = useDispatch();

  const returnCountryStats = (data: any) => {
    const mainData = {
      contributionCarbonTotal: 0,
      contributionSTARRTotal: 0,
      contributionSTARTATotal: 0,
    };
    if (!data?.length) {
      return mainData;
    }
    const { country_carbon_total, country_score_R_total, country_score_TA_total } = data[0];

    return {
      contributionCarbonTotal: country_carbon_total,
      contributionSTARRTotal: country_score_R_total,
      contributionSTARTATotal: country_score_TA_total,
    };
  };

  const getData = async () => {
    const country = countryLevelData?.country;
    const countryISO3 = countryLevelData?.countryISO3;
    const queryParams = {
      url: ENDPOINTS.APPROVED_CONTRIBUTIONS_LAYER,
      queryParams: {
        where: `beneficiaryCountries = '${countryISO3}'`,
        returnGeometry: false,
        outFields: [
          'totalBudget',
          'budgetNeeded',
          'totalBeneficiaries',
          'totalStaff',
          'starValueR',
          'starValueTA',
          'carbonValue',
        ],
      },
    };

    // contribution ( contribution )
    // country  ( lookup )
    // region ( lookup )
    // global ( lookup )

    // contribution - country
    // country - region
    // region - global

    // TODO: leave commented out countryDataResponse method for now until we confirm this is the correct way to get the data
    const [countryAggregationResponse, countryDataResponse, countryCarbonResponse, getCountryStats] = await Promise.all(
      [
        await queryDataStatistics({ ...countryAggregate, where: `iso3='${countryISO3}'` }),
        await queryDataStatistics({ ...countryLookup, where: `ISO3='${countryISO3}'` }),
        await queryDataStatistics({ ...carbonLookupCountry, where: `ISO3='${countryISO3}'` }),
        await getFeatures(queryParams),
      ]
    );

    // gets data from aggregate table
    const { contributionCarbonTotal, contributionSTARTATotal } = returnCountryStats(countryAggregationResponse);

    const mapRegion = countryCarbonResponse[0]?.MapRegion;

    const where = `MapRegion='${mapRegion}'`;

    const [regionCarbonResponse, regionTAResponse, regionRestorationResponse] = await Promise.all([
      queryDataStatistics({ ...regionCarbon, where }),
      queryDataStatistics({ ...regionTA, where }),
      queryDataStatistics({ ...regionRestoration, where }),
    ]);

    // gets data from contribution table
    const contributionData = {
      contributionCarbonTotal: getCarbonTotal(getCountryStats),
      contributionSTARRTotal: getTotalByProp(getCountryStats, 'starValueR'),
      contributionSTARTATotal: getTotalByProp(getCountryStats, 'starValueTA'),
    };

    const countryData = {
      data: [...countryAggregationResponse, ...countryCarbonResponse, ...countryDataResponse],

      country,
    };

    const regionData = {
      regionCarbonResponse,
      regionTAResponse,
      regionRestorationResponse,
      region: mapRegion,
    };

    const statsValues = {
      carbonValue: contributionCarbonTotal,
      starValueRTotal: contributionData.contributionSTARRTotal,
      starValueTATotal: contributionSTARTATotal,
      totalBudget: getTotalByProp(getCountryStats, 'totalBudget'),
      totalBudgetNeeded: getTotalByProp(getCountryStats, 'budgetNeeded'),
      totalBeneficiaries: getTotalByProp(getCountryStats, 'totalBeneficiaries'),
      totalStaff: getTotalByProp(getCountryStats, 'totalStaff'),
    };
    const countryLevelChartPieData = await countryChartDataBuilder(
      { contributionData, countryData, regionData, GLOBAL_VALUES },
      selectedLanguage
    );

    setCountryLevelChartData(countryLevelChartPieData);
    setStatsData(statsValues);
  };

  const handlePagesCount = () => {
    const usersPerPage = 10;
    const pagesVisited = pageNumber * usersPerPage;
    const displayUsers = countryLevelData.data.slice(pagesVisited, pagesVisited + usersPerPage);
    const pagesCount = Math.ceil(countryLevelData.data.length / usersPerPage);

    setPageCount(pagesCount);
    setDataCopy(displayUsers);
  };

  const handleExpandedFilter = () => {
    setExpandedFilters(!expanedFilters);
  };

  const handleRecordSelection = (data: any) => {
    dispatch(setOrgsCount(data.orgCount));
    dispatch(setSelectedOrganizationName(data.organization));

    let where = `beneficiaryCountries LIKE '%${countryLevelData.countryISO3}%'`;

    if (data?.partnerOrgs) {
      const partnerOrgs = data?.partnerOrgs;
      where += ` AND partnerOrgs LIKE '%${partnerOrgs}%'`;
    } else {
      where += `AND ownerOrgs LIKE '%${data.organization}%'`;
    }

    mapController.filterLayer(where);
    mapController.queryMapExtent(where);

    const params = {
      page: 0,
      count: 10,
      where,
      outFields: ['*'],
      orderByFields: ['name'],
      keepPrevious: false,
    };

    mapController.queryOrgsByPage(params);
    dispatch(setUserContributions(data));
    dispatch(setVisiblePanel('organization-panel'));
  };

  const handleFilterByLetter = (data: any) => {
    if (!data.selected) {
      const filterResult = countryLevelData.data.filter(
        (userData: any) => userData.organization[0].toLowerCase() === data.label.toLowerCase()
      );
      setDataCopy(filterResult);
    } else {
      setDataCopy(countryLevelData.data);
    }

    const updateLettersData = lettersData.map((letter: any) => {
      if (data.id === letter.id) {
        return {
          ...letter,
          selected: !letter.selected,
        };
      }
      return {
        ...letter,
        selected: false,
      };
    });
    setLettersData(updateLettersData);
  };

  const handlePageChange = (event: any, page: any) => {
    // TODO: leave event for now, as function is expecting 2 parameters
    const value = page - 1;
    setPageNumber(value);
  };

  const handleGoBack = () => {
    dispatch(setVisiblePanel('main-panel'));
    dispatch(setCountryLevelData({ country: '', countryISO3: '', data: [] }));
    dispatch(setRegionLevelData({ region: '', OBJECTID: null, data: [] }));
    dispatch(setSelectedCountryOrRegion(null));
    mapController.resetMapView();
  };

  useEffect(() => {
    if (countryLevelData.country) {
      setDataCopy(countryLevelData.data);
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryLevelData, selectedLanguage]);

  useEffect(() => {
    if (countryLevelData.country) {
      handlePagesCount();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryLevelData, pageNumber]);

  return (
    <div className='SidePanel'>
      <div className='side-panel-header'>
        <div className='return-btn-wrapper'>
          <div className='return-to-results' onClick={handleGoBack}>
            <span>
              <AiFillCaretLeft />
            </span>
            <h4>{selectedLanguage['Return to Results']}</h4>
          </div>
        </div>
        <h4 className='country-name'>{countryLevelData.country}</h4>
        <div className='border-bottom'></div>
      </div>
      <div className='auto-height country-level-height'>
        <CountryStatsDropdown
          handleExpandedFilter={handleExpandedFilter}
          expanedFilters={expanedFilters}
          statsData={statsData}
          countryLevelChartData={countryLevelChartData}
          countryLevelVisibleChart={visibleChartCountry}
          countryName={countryLevelData.country}
        />

        <div className='side-panel-filters'>
          {lettersData.map((letter: any, index: number) => (
            <p className={letter.selected ? 'selected' : ''} onClick={() => handleFilterByLetter(letter)} key={index}>
              {letter.label}
            </p>
          ))}
        </div>
        <ShowRecordsComponent data={dataCopy} handleRecordSelection={handleRecordSelection} />
      </div>

      <div>
        <Pagination
          classes={{ root: 'pagination-wrapper' }}
          onChange={handlePageChange}
          count={pageCount}
          variant='outlined'
          shape='rounded'
        />
      </div>
    </div>
  );
};

export default SidePanelCountry;
