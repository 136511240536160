import { getRemainingValue, removeDecimalsFromNumber } from './helperMethods';

export function regionChartDataBuilder(params: any, selectedLanguage: any) {
  const { contributionData, regionData, globalData } = params;
  const { regionCarbonResponse, regionTAResponse, regionRestorationResponse, region } = regionData;

  const threatAbatement = [
    {
      id: 'star-threat-abatement',
      label: `${region}'s ${selectedLanguage['Potential contribution towards species extinction risk reduction by abating threats as a proportion of the total global potential contribution']}`,
      chartData: [
        {
          name: selectedLanguage['Contribution'],
          y: removeDecimalsFromNumber(contributionData?.contributionSTARTATotal),
        },
        {
          name: region || selectedLanguage['No Data'],
          y: getRemainingValue(contributionData?.contributionSTARTATotal, regionTAResponse[0]?.region_TA_total).y,
          rawValue: getRemainingValue(contributionData?.contributionSTARTATotal, regionTAResponse[0]?.region_TA_total)
            .rawValue,
        },
      ],
    },
    {
      id: 'star-threat-abatement',
      label: `${region}'s ${selectedLanguage['Potential contribution towards species extinction risk reduction by abating threats as a proportion of the total global potential contribution']}`,
      chartData: [
        {
          name: region || selectedLanguage['No Data'],
          y: removeDecimalsFromNumber(regionTAResponse[0]?.region_TA_total),
        },
        {
          name: selectedLanguage['Global'],
          y: getRemainingValue(regionTAResponse[0]?.region_TA_total, globalData.THREAT_ABATEMENT).y,
          rawValue: getRemainingValue(regionTAResponse[0]?.region_TA_total, globalData.THREAT_ABATEMENT).rawValue,
        },
      ],
    },
  ];

  const restoration = [
    {
      id: 'star-restoration',
      label: `${region}'s ${selectedLanguage['Potential contribution towards species extinction risk reduction by abating threats as a proportion of the total global potential contribution']}`,
      chartData: [
        {
          name: selectedLanguage['Contribution'],
          y: removeDecimalsFromNumber(contributionData?.contributionSTARRTotal),
        },
        {
          name: region || selectedLanguage['No Data'],
          y: getRemainingValue(
            contributionData?.contributionSTARRTotal,
            regionRestorationResponse[0]?.region_Restoration_total
          ).y,
          rawValue: getRemainingValue(
            contributionData?.contributionSTARRTotal,
            regionRestorationResponse[0]?.region_Restoration_total
          ).rawValue,
        },
      ],
    },
    {
      id: 'star-restoration',
      label: `${region}'s ${selectedLanguage['Potential contribution towards species extinction risk reduction by abating threats as a proportion of the total global potential contribution']}`,
      chartData: [
        {
          name: region || selectedLanguage['No Data'],
          y: removeDecimalsFromNumber(regionRestorationResponse[0]?.region_Restoration_total),
        },
        {
          name: selectedLanguage['Global'],
          y: getRemainingValue(regionRestorationResponse[0]?.region_Restoration_total, globalData.STAR_RESTORATION).y,
          rawValue: getRemainingValue(
            regionRestorationResponse[0]?.region_Restoration_total,
            globalData.STAR_RESTORATION
          ).rawValue,
        },
      ],
    },
  ];
  const climateMitigation = [
    {
      id: 'climate-mitigation',
      label: `${region}'s ${selectedLanguage['Potential contribution towards species extinction risk reduction by abating threats as a proportion of the total global potential contribution']}`,
      chartData: [
        {
          name: selectedLanguage['Contribution'],
          y: removeDecimalsFromNumber(contributionData?.contributionCarbonTotal),
        },
        {
          name: region || selectedLanguage['No Data'],
          y: getRemainingValue(contributionData?.contributionCarbonTotal, regionCarbonResponse[0]?.region_carbon_total)
            .y,
          rawValue: getRemainingValue(
            contributionData?.contributionCarbonTotal,
            regionCarbonResponse[0]?.region_carbon_total
          ).rawValue,
        },
      ],
    },
    {
      id: 'climate-mitigation',
      label: `${region}'s ${selectedLanguage['Potential contribution towards species extinction risk reduction by abating threats as a proportion of the total global potential contribution']}`,
      chartData: [
        {
          name: region || selectedLanguage['No Data'],
          y: removeDecimalsFromNumber(regionCarbonResponse[0]?.region_carbon_total),
        },
        {
          name: selectedLanguage['Global'],
          y: getRemainingValue(regionCarbonResponse[0]?.region_carbon_total, globalData.CLIMATE_MITIGATION).y,
          rawValue: getRemainingValue(regionCarbonResponse[0]?.region_carbon_total, globalData.CLIMATE_MITIGATION)
            .rawValue,
        },
      ],
    },
  ];
  return {
    threatAbatement,
    restoration,
    climateMitigation,
  };
}
