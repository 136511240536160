export const letters = [
  {
    id: '1',
    selected: false,
    label: 'A',
  },
  {
    id: '2',
    selected: false,
    label: 'B',
  },
  {
    id: '03',
    selected: false,
    label: 'C',
  },
  {
    id: '04',
    selected: false,
    label: 'D',
  },
  {
    id: '05',
    selected: false,
    label: 'E',
  },
  {
    id: '06',
    selected: false,
    label: 'F',
  },
  {
    id: '07',
    selected: false,
    label: 'G',
  },
  {
    id: '08',
    selected: false,
    label: 'H',
  },
  {
    id: '09',
    selected: false,
    label: 'I',
  },
  {
    id: '010',
    selected: false,
    label: 'J',
  },
  {
    id: '011',
    selected: false,
    label: 'K',
  },
  {
    id: '012',
    selected: false,
    label: 'L',
  },
  {
    id: '013',
    selected: false,
    label: 'M',
  },
  {
    id: '014',
    selected: false,
    label: 'N',
  },
  {
    id: '015',
    selected: false,
    label: 'O',
  },
  {
    id: '016',
    selected: false,
    label: 'P',
  },
  {
    id: '017',
    selected: false,
    label: 'Q',
  },
  {
    id: '018',
    selected: false,
    label: 'R',
  },
  {
    id: '019',
    selected: false,
    label: 'S',
  },
  {
    id: '020',
    selected: false,
    label: 'T',
  },
  {
    id: '021',
    selected: false,
    label: 'U',
  },
  {
    id: '022',
    selected: false,
    label: 'V',
  },
  {
    id: '023',
    selected: false,
    label: 'W',
  },
  {
    id: '024',
    selected: false,
    label: 'X',
  },
  {
    id: '025',
    selected: false,
    label: 'Y',
  },
  {
    id: '026',
    selected: false,
    label: 'Z',
  },
];
