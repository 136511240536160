import { loadModules } from 'esri-loader';
import store from '../store/store';

export const queryDataStatistics = async (params: any) => {
  const [Query, execute, esriConfig] = await loadModules([
    'esri/rest/support/Query',
    'esri/rest/query/executeQueryJSON',
    'esri/config',
  ]);

  const { generatedToken } = store.getState().appSlice;
  const { statistics, url, groupByFieldsForStatistics } = params;

  esriConfig.apiKey = generatedToken;

  const query = new Query() as __esri.Query;
  query.outFields = ['*'];
  query.outStatistics = statistics;
  query.groupByFieldsForStatistics = groupByFieldsForStatistics;
  query.where = params.where;
  query.returnGeometry = false;

  try {
    const queryResult = (await execute.executeQueryJSON(url, query)) as __esri.FeatureSet;
    const attributes = queryResult.features.map((feature) => feature.attributes);

    return attributes;
  } catch (error) {
    console.log('eror ', error);
    return [];
  }
};

interface GetFeaturesParams {
  queryParams: __esri.QueryProperties;
  url: string;
}

export const getFeatures = async ({ queryParams, url }: GetFeaturesParams) => {
  const [Query, execute, esriConfig] = await loadModules([
    'esri/rest/support/Query',
    'esri/rest/query/executeQueryJSON',
    'esri/config',
  ]);
  const { generatedToken } = store.getState().appSlice;

  esriConfig.apiKey = generatedToken;
  const query = new Query({
    ...queryParams,
  }) as __esri.Query;

  try {
    const queryResult = (await execute.executeQueryJSON(url, query)) as __esri.FeatureSet;
    const attributes = queryResult.features.map((feature) => feature.attributes);

    return attributes;
  } catch (error) {
    console.log('getFeatures error:', error);
    return [];
  }
};
