import { useRef, useLayoutEffect } from 'react';
import Highcharts from 'highcharts';
import { useSelector } from 'react-redux';
import { languageSelector } from '../../store/slices/appSlice';
import { TRANSLATIONS } from '../../translations/translations';

interface Props {
  data?: { name: string; y: number }[];
  numberOfChartsInSet?: number; //
}

/*
  Using Highcharts here without HighchartsReact wrapper as there was as bug
  with the react wrapper in which the wrapper was mutating the original data object
  and not just using the values passed so that when swtiching back to the first chart, it's 
  values were over-written with the last charts. Very odd behaviour.
*/

const PieChartWithoutWrapper = ({ data, numberOfChartsInSet = 1 }: Props) => {
  const divRef = useRef<HTMLDivElement>(null);

  const language = useSelector(languageSelector);
  const selectedLanguage = TRANSLATIONS[language];

  useLayoutEffect(() => {
    if (divRef.current) {
      (Highcharts as any).chart(divRef.current, {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie',
          height: 290,
          width: 200,
        },
        title: {
          text: '',
        },
        tooltip: {
          pointFormat: '{series.name}: <b>{point.percentage:.0f}%</b>',
          formatter: function (this: any) {
            const value = this.y as number;
            const updatedValue = parseInt(this?.point?.rawValue || this.y);
            const percentValue = `${selectedLanguage['Percentage value']} <b>${this.point.percentage.toFixed(0)}%</b>`;
            const rawValue = `${selectedLanguage['Absolute value']} <b>${updatedValue?.toLocaleString()}</b>`;
            const precisionValue = `${selectedLanguage['Absolute value']} <b>${value.toPrecision(4)}</b>`;
            return `${this.key}</br> ${value > 1000 ? rawValue : precisionValue} </br>${percentValue} `;
          },
          valueDecimals: 0,
        },
        accessibility: {},
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            animation: true,
            size: numberOfChartsInSet > 2 ? '85%' : '95%',
            startAngle: 0,
            endAngle: 360,
            dataLabels: {
              alignTo: 'plotEdges',
              connectorShape: 'straight',
              crookDistance: '10%',
              connectorPadding: 0,
              backgroundColor: '#ffffffee',
              borderRadius: 6,
              distance: 5,

              formatter: function (): string {
                // Elide names with more than 4 words. Ampersands (&) are considered words in this scenario to reduce complexity.
                // TS Ignore required here, but this.point is guaranteed to exist per documentation.
                // @ts-ignore
                const words = this.point.name.split(' ');
                const outLabel =
                  // @ts-ignore
                  words.length > 4 ? `${words[0]} ${words[1]} ${words[2]} ${words[3]} ...` : this.point.name;
                return outLabel;
              },
              enabled: true,
              overflow: 'allow',
              crop: false,
            },
          },
        },
        series: [
          {
            name: selectedLanguage['Total'],
            colorByPoint: true,
            data,
          },
        ],
        colors: ['#C1E0F5', '#037FB0'],
        credits: {
          text: '',
        },
      });
    }
  }, [data, selectedLanguage, numberOfChartsInSet]);

  return (
    <>
      <div ref={divRef}></div>
    </>
  );
};

export default PieChartWithoutWrapper;
