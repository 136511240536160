import { useState } from 'react';
import IUCNLogoEN from '../../assets/IUCN_logo_white.webp';
import IUCNLogoES from '../../assets/IUCN_logo_es.jpeg';
import IUCNLogoFR from '../../assets/IUCN_logo_fr.jpeg';
import SmoothCollapse from 'react-smooth-collapse';
import { GiHamburgerMenu } from 'react-icons/gi';
import { useDispatch, useSelector } from 'react-redux';
import { languageSelector, setShowHeaderRightBorder, setVisiblewindow } from '../../store/slices/appSlice';
import LangaugeSwitcher from '../LanguageSwitcher';
import config from '../../configs/config';
import SmallDevicesSlidePane from './SmallDevicesSlidePane';
import { TRANSLATIONS } from '../../translations/translations';
import './styles/homeNavbar.scss';
import { documentList } from '../../configs/documentsConfig';
import { setIsMainPanelLoading } from '../../store/slices/sidePanelSlice';
import { ENDPOINTS } from '../../configs/endpoints';

const { IUCN_TOOL_URL } = ENDPOINTS;

const HomeNavbar = () => {
  const language = useSelector(languageSelector);
  const [isOpen, setIsOpen] = useState(false);
  const [expandDocuments, setExpandDocuments] = useState(false);
  const dispatch = useDispatch();

  const selectedLanguage = TRANSLATIONS[language];
  const DOCUMENTS_LIST = documentList(selectedLanguage);

  const handleOnClick = () => {
    dispatch(setIsMainPanelLoading(true));
    dispatch(setVisiblewindow('map'));
    dispatch(setShowHeaderRightBorder(true));
  };

  const renderLogo = () => {
    if (language === 'en') {
      return IUCNLogoEN;
    } else if (language === 'es') {
      return IUCNLogoES;
    } else {
      return IUCNLogoFR;
    }
  };

  const toggleDocumentDropdown = () => {
    setExpandDocuments(!expandDocuments);
  };

  return (
    <nav className='home-navbar'>
      <div className='home-navbar-wrapper'>
        <div className='logo-wrapper'>
          <img src={renderLogo()} alt={selectedLanguage['IUCN logo']} />
        </div>
        <div className='header-links'>
          <ul>
            <li>
              <button onClick={toggleDocumentDropdown}>Documents</button>
            </li>
            <li>
              <a href={IUCN_TOOL_URL} target='_blank' rel='noreferrer'>
                {selectedLanguage['Add Contributions']}
              </a>
            </li>
          </ul>
          <div className='launch-app-btn'>
            <button onClick={handleOnClick}>{selectedLanguage['View Contributions']}</button>
          </div>
          <div>
            <LangaugeSwitcher {...config.languageConfig} />
          </div>
        </div>
        <button onClick={() => setIsOpen(!isOpen)} className='slide-pane-btn'>
          <GiHamburgerMenu />
        </button>
      </div>

      <SmallDevicesSlidePane isOpen={isOpen} setIsOpen={setIsOpen} handleOnClick={handleOnClick} />
      <div className='smooth-dropdown-wrapper'>
        <SmoothCollapse expanded={expandDocuments}>
          <div className='expand-documents-container'>
            <div className='document-links-list'>
              {DOCUMENTS_LIST.map((item) => (
                <a key={item.id} target='_blank' rel='noreferrer' href={item.url}>
                  {item.label}
                </a>
              ))}
            </div>
          </div>
        </SmoothCollapse>
      </div>
    </nav>
  );
};

export default HomeNavbar;
