import Pagination from '@mui/material/Pagination';
import { useCallback, useEffect, useState } from 'react';
import { FcRight } from 'react-icons/fc';
import { useDispatch, useSelector } from 'react-redux';
import _debounce from 'lodash/debounce';
import _throttle from 'lodash/throttle';
import { ENDPOINTS } from '../../configs/endpoints';
import { mapController } from '../../controllers/MapController';
import {
  countryLevelDataSelector,
  languageSelector,
  regionLevelDataSelector,
  setSingleContribution,
  userContributionsSelector,
} from '../../store/slices/appSlice';
import {
  orgsCountSelector,
  organizattionDataSelector,
  selectedOrganizationNameSelector,
  setVisiblePanel,
} from '../../store/slices/sidePanelSlice';
import { getFeatures } from '../../utils/fetchData';
import { TRANSLATIONS } from '../../translations/translations';
import { setSelectedContribution } from '../../store/slices/mapSlice';

const PAGE_SIZE = 10;

const DisplayContributions = () => {
  const dispatch = useDispatch();

  const orgsCount = useSelector(orgsCountSelector);
  const organizationData = useSelector(organizattionDataSelector);
  const selectedOrganizationName = useSelector(selectedOrganizationNameSelector);
  const selectedOrgData = useSelector(userContributionsSelector);
  const countryLevelData = useSelector(countryLevelDataSelector);
  const regionLevelData = useSelector(regionLevelDataSelector);
  const [contributionCountries, setContributionCountries] = useState<any>([]);
  const [pageCount, setPageCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [orgDataCopy, setUserDataCopy] = useState<any>([]);
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [isDataFeaching, setIsDataFeaching] = useState(false);

  const language = useSelector(languageSelector);
  const selectedLanguage = TRANSLATIONS[language];

  const callbackDebounce = useCallback(_debounce(debounceHelperFn, 50), []);
  const callbackThrottle = useCallback(_throttle(throttleHelperFn, 500), []);

  async function debounceHelperFn(selectedOrganizationName: string, value: string, partner: string | null) {
    await mapController.querySearchContribution(selectedOrganizationName, value, partner);
  }

  async function throttleHelperFn(params: any) {
    await mapController.queryOrgsByPage(params);
  }

  useEffect(() => {
    if (organizationData && organizationData.length) {
      handlePagesCount();
    } else {
      setUserDataCopy([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationData, pageNumber]);

  const getData = useCallback(
    async (queryParams) => {
      const response = await getFeatures(queryParams);
      setContributionCountries(response);
    },
    [orgDataCopy]
  );

  useEffect(() => {
    const getIso3 = orgDataCopy
      ?.map((item: any) => item?.beneficiaryCountries)
      .join("','")
      .split('|')
      .join("','");
    const where = `ISO3 IN ('${getIso3}')`;

    const queryParams = {
      url: ENDPOINTS.WORLD_COUNTRY_LOOKUP,
      queryParams: {
        where,
        returnGeometry: false,
        outFields: ['ISO3', 'Display_Name'],
      },
    };

    if (getIso3 && getIso3?.length) {
      getData(queryParams);
    }
  }, [getData, orgDataCopy]);

  function handlePagesCount() {
    const pagesCount = Math.ceil(orgsCount / PAGE_SIZE);

    setPageCount(pagesCount);
    setUserDataCopy(organizationData);
  }

  function createQueryString() {
    let queryString = `ownerOrgs IS NOT NULL AND beneficiaryCountries IS NOT NULL`;

    if (selectedOrgData?.partnerOrgs) {
      queryString += ` AND partnerOrgs LIKE '%${selectedOrgData.partnerOrgs}%'`;
    } else {
      queryString += `AND ownerOrgs LIKE '%${selectedOrganizationName}%'`;
    }
    if (countryLevelData.countryISO3) {
      queryString += ` AND beneficiaryCountries LIKE '%${countryLevelData.countryISO3}%'`;
    }
    if (regionLevelData.region) {
      queryString += ` AND beneficiaryRegions LIKE '%${regionLevelData.region}%'`;
    }
    return queryString;
  }

  useEffect(() => {
    if (pageNumber >= 1) {
      setIsDataFeaching(true);
      async function fetchData() {
        const queryString = createQueryString();
        const params = {
          page: (pageNumber - 1) * PAGE_SIZE,
          count: PAGE_SIZE,
          where: queryString,
          outFields: ['*'],
          orderByFields: ['name'],
          keepPrevious: false,
        };
        callbackThrottle(params);
        setIsDataFeaching(false);
      }
      fetchData();
    }
  }, [pageNumber, countryLevelData.country]);

  const handleSearch = async (event: any) => {
    const { value } = event.target;
    if (value) {
      setIsDataFeaching(true);
      if (!isSearchActive) {
        setIsSearchActive(true);
      }
      setPageNumber(1);
      callbackDebounce(selectedOrganizationName, value, selectedOrgData?.partnerOrgs);
      setIsDataFeaching(false);
    } else {
      setIsSearchActive(false);

      let where = `ownerOrgs IS NOT NULL AND beneficiaryCountries IS NOT NULL`;

      if (selectedOrgData?.partnerOrgs) {
        where += `AND partnerOrgs LIKE '%${selectedOrgData?.partnerOrgs}%'`;
      } else {
        where += `AND ownerOrgs LIKE '%${selectedOrganizationName}%'`;
      }
      const params = {
        page: 0,
        count: PAGE_SIZE,
        itemPerPage: PAGE_SIZE,
        where,
        outFields: ['*'],
        orderByFields: ['name'],
        keepPrevious: false,
      };
      mapController.queryOrgsByPage(params);
    }
  };

  const handleContributionResult = async (contribution: any) => {
    const id = contribution.OBJECTID;
    const params = {
      where: `OBJECTID = ${id}`,
      outFields: ['*'],
    };

    await mapController.handleQueryAttachments(id);
    const queryResponse = await mapController.queryData(params);
    const contributionData = queryResponse?.length ? queryResponse[0] : null;

    dispatch(setSelectedContribution({ label: contribution.name, value: contribution.OBJECTID }));

    dispatch(setSingleContribution(contributionData));
    dispatch(setVisiblePanel('contribution-panel'));
  };

  const handlePageChange = (event: any, page: any) => {
    setPageNumber(page);
  };

  const handleCountryNameRender = (beneficiaryCountries: string) => {
    if (beneficiaryCountries) {
      const countryName = contributionCountries
        .filter((item: any) => beneficiaryCountries.includes(item.ISO3))
        .map((item: any) => item.Display_Name);

      if (!countryName.length) {
        return selectedLanguage['No country name found'];
      }

      if (countryName.length === 1) {
        return countryName[0];
      } else {
        return countryName.join('|');
      }
    }
  };

  return (
    <div className='result-contributions'>
      <div className='search-input-field'>
        <h4>{selectedLanguage['Contributions']}:</h4>
        <div className='search-contributions'>
          <label htmlFor='search'>
            <input
              type='text'
              id='search'
              onChange={handleSearch}
              name='search'
              placeholder={selectedLanguage['Search for contribution name']}
            />
          </label>
        </div>
      </div>

      {!!orgDataCopy.length &&
        orgDataCopy.map((contribution: any, index: number) => {
          const { name, beneficiaryCountries } = contribution;
          return (
            <div key={index} className='contribution' onClick={() => handleContributionResult(contribution)}>
              <p>
                {name}
                <span>{handleCountryNameRender(beneficiaryCountries)}</span>
              </p>
              <span className='icon'>
                <FcRight />
              </span>
            </div>
          );
        })}
      {!orgDataCopy.length && (
        <div>
          {!isDataFeaching && organizationData && !organizationData.length && (
            <p className='no-results-message'>{selectedLanguage['No contributions found!']}</p>
          )}
          {isDataFeaching && <p className='no-results-message'>{selectedLanguage['Loading']}...</p>}
        </div>
      )}
      <div>
        <Pagination
          classes={{ root: 'pagination-wrapper' }}
          onChange={handlePageChange}
          count={pageCount}
          variant='outlined'
          page={pageNumber}
          shape='rounded'
          disabled={isSearchActive || orgsCount <= 10}
        />
      </div>
    </div>
  );
};

export default DisplayContributions;
