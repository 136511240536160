import { useEffect } from 'react';
import { useRef } from 'react';
import { useSelector } from 'react-redux';
import { mapController } from '../../controllers/MapController';
import Header from '../header/Header';
import PublicApp from '../publicApp/PublicApp';
import SkeletonLoader from '../SkeletonLoader/SkeletonLoader';
import config from '../../configs/config';
import './mapview.scss';
import { isMainPanelLoadingSelector } from '../../store/slices/sidePanelSlice';
import { isTokenGeneratedSelector, languageSelector } from '../../store/slices/appSlice';
import { TRANSLATIONS } from '../../translations/translations';
import GlobalModal from '../modals/GlobalModal';

const MapView = () => {
  const mapViewRef = useRef(null);
  const setIsMainPanelLoading = useSelector(isMainPanelLoadingSelector);
  const { skeletonConfig } = config;

  const language = useSelector(languageSelector);
  const isTokenGenerated = useSelector(isTokenGeneratedSelector);

  const selectedLanguage = TRANSLATIONS[language];

  useEffect(() => {
    if (isTokenGenerated) {
      mapController.initializeMap(mapViewRef);
    }
  }, [mapViewRef, isTokenGenerated]);

  return (
    <div id='mapview-container'>
      <Header />
      <div id='mapview' ref={mapViewRef}>
        <p className='disclaimer-text'>
          {
            selectedLanguage[
              'The designations employed and the presentation of material on this map do not imply the expression of any opinion whatsoever on the part of IUCN concerning the legal status of any country, territory, city or area or its authorities, or concerning the delimitation of its frontiers or boundaries.'
            ]
          }
          <span>{selectedLanguage['Powered by Esri']}</span>
          <a href='mailto:contributionsplatform@iucn.org'>{selectedLanguage['Send Feedback']}</a>
        </p>
      </div>
      {!setIsMainPanelLoading && <PublicApp />}
      {setIsMainPanelLoading && <SkeletonLoader data={skeletonConfig.mainPanel} />}
      <GlobalModal />
    </div>
  );
};

export default MapView;
