import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import config from '../../configs/config';

export type LanguageType = 'en' | 'es' | 'fr';

const getSelectedLanguage = (): LanguageType => {
  const key = config.languageConfig.localStorageKey;
  const language = localStorage.getItem(key) as LanguageType;
  if (language) {
    return language;
  } else {
    // if not language is especified, return and set the default language
    localStorage.setItem(key, config.languageConfig.defaultLanguage);
    return config.languageConfig.defaultLanguage;
  }
};

export type VisibleWindow = 'homepage' | 'map' | 'login' | 'maintenance-page';

interface CountryLevelTypes {
  country: string;
  countryISO3: string;
  data: any;
}

interface RegionLevelTypes {
  region: string;
  OBJECTID: number | null; // todo: get object id but not sure if is needed
  data: any;
}

interface StateTypes {
  language: LanguageType;
  visibleWindow: VisibleWindow;
  constributions: any[];
  userData: any[];
  userContributions: any;
  singleContribution: any;
  memberTypes: any;
  generatedToken: string;
  clickedCountryContributions: any;
  countryLevelData: CountryLevelTypes;
  regionLevelData: RegionLevelTypes;
  organizationDescription: string;
  isGlobalModalOpen: boolean;
  globalModalContent: any;
  isTokenGenerated: boolean;
  showHeaderRightBorder: boolean;
}

const defaultCountryData = {
  country: '',
  countryISO3: '',
  data: [],
};

const defaultRegionData = {
  region: '',
  OBJECTID: null,
  data: [],
};

const initialState: StateTypes = {
  language: getSelectedLanguage(),
  visibleWindow: 'homepage',
  constributions: [],
  userData: [],
  userContributions: {},
  singleContribution: {},
  memberTypes: {},
  generatedToken: '',
  clickedCountryContributions: [],
  countryLevelData: defaultCountryData,
  regionLevelData: defaultRegionData,
  organizationDescription: '',
  isGlobalModalOpen: false,
  globalModalContent: {},
  isTokenGenerated: false,
  showHeaderRightBorder: false,
};

export const appSlice = createSlice({
  name: 'appSlice',
  initialState,
  reducers: {
    // latest methods
    setLanguage: (state, action: PayloadAction<LanguageType>) => {
      state.language = action.payload;
    },
    setVisiblewindow: (state, action: PayloadAction<VisibleWindow>) => {
      state.visibleWindow = action.payload;
    },
    setContributions: (state, action: PayloadAction<any>) => {
      state.constributions = action.payload;
    },
    setUserData: (state, action: PayloadAction<any>) => {
      state.userData = action.payload;
    },
    setUserContributions: (state, action: PayloadAction<any>) => {
      state.userContributions = action.payload;
    },
    setSingleContribution: (state, action: PayloadAction<any>) => {
      state.singleContribution = action.payload;
    },
    setMemberTypes: (state, action: PayloadAction<any>) => {
      state.memberTypes = action.payload;
    },
    setGeneratedToken: (state, action: PayloadAction<string>) => {
      state.generatedToken = action.payload;
    },
    setClickedCountryContributions: (state, action: PayloadAction<any>) => {
      state.clickedCountryContributions = action.payload;
    },
    setCountryLevelData: (state, action: PayloadAction<CountryLevelTypes>) => {
      state.countryLevelData = action.payload;
    },
    setRegionLevelData: (state, action: PayloadAction<RegionLevelTypes>) => {
      state.regionLevelData = action.payload;
    },
    setOrganizationDescription: (state, action: PayloadAction<string>) => {
      state.organizationDescription = action.payload;
    },
    setIsGlobalModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isGlobalModalOpen = action.payload;
    },
    setGlobalModalContent: (state, action: PayloadAction<any>) => {
      state.globalModalContent = action.payload;
    },
    setIsTokenGenerated: (state, action: PayloadAction<boolean>) => {
      state.isTokenGenerated = action.payload;
    },
    setShowHeaderRightBorder: (state, action: PayloadAction<boolean>) => {
      state.showHeaderRightBorder = action.payload;
    },
  },
});

export const {
  setLanguage,
  setContributions,
  setVisiblewindow,
  setUserData,
  setUserContributions,
  setSingleContribution,
  setMemberTypes,
  setGeneratedToken,
  setClickedCountryContributions,
  setCountryLevelData,
  setRegionLevelData,
  setOrganizationDescription,
  setIsGlobalModalOpen,
  setGlobalModalContent,
  setIsTokenGenerated,
  setShowHeaderRightBorder,
} = appSlice.actions;

export const languageSelector = (state: RootState) => state.appSlice.language;
export const visibleWindowSelector = (state: RootState) => state.appSlice.visibleWindow;
export const constributionsSelector = (state: RootState) => state.appSlice.constributions;
export const userDataSelector = (state: RootState) => state.appSlice.userData;
export const userContributionsSelector = (state: RootState) => state.appSlice.userContributions;
export const singleContributionSelector = (state: RootState) => state.appSlice.singleContribution;
export const memberTypesSelector = (state: RootState) => state.appSlice.memberTypes;
export const generatedTokenSelector = (state: RootState) => state.appSlice.generatedToken;
export const clickedCountryContributionsSelector = (state: RootState) => state.appSlice.clickedCountryContributions;
export const countryLevelDataSelector = (state: RootState) => state.appSlice.countryLevelData;
export const regionLevelDataSelector = (state: RootState) => state.appSlice.regionLevelData;
export const organizationDescriptionSelector = (state: RootState) => state.appSlice.organizationDescription;
export const isGlobalModalOpenSelector = (state: RootState) => state.appSlice.isGlobalModalOpen;
export const globalModalContentSelector = (state: RootState) => state.appSlice.globalModalContent;
export const isTokenGeneratedSelector = (state: RootState) => state.appSlice.isTokenGenerated;
export const showHeaderRightBorderSelector = (state: RootState) => state.appSlice.showHeaderRightBorder;

export default appSlice.reducer;
