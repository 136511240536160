import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

interface IState {
  globalLoader: boolean;
}
const initialState: IState = {
  globalLoader: false,
};

export const loaderSlice = createSlice({
  name: 'loaderSlice',
  initialState,
  reducers: {
    setGlobalLoader: (state, action: PayloadAction<boolean>) => {
      state.globalLoader = action.payload;
    },
  },
});

export const { setGlobalLoader } = loaderSlice.actions;

export const globalLoaderSelector = (state: RootState) => state.loaderSlice.globalLoader;

export default loaderSlice.reducer;
