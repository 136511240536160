export const en = {
  Home: 'Home',
  'About IUCN': 'About IUCN',
  'Add Contributions': 'Add Contributions',
  'View Contributions': 'View Contributions',
  'IUCN logo': 'IUCN logo',
  'Share url': 'Share URL',
  Copied: 'Copied',

  /**
   * Maintenance Page Starts Here
   */
  'The data underlying the IUCN Contributions for Nature Platform are currently being updated. Please check back soon for updated results. Please contact':
    'The data underlying the IUCN Contributions for Nature Platform are currently being updated. Please check back soon for updated results. Please contact',
  'if you have any questions.': 'if you have any questions.',
  'Go Back': 'Go Back',
  /**
   * Maintenance Page Ends Here
   */

  //
  'Welcome to the IUCN': 'Welcome to the IUCN',
  'Contributions for Nature Platform': 'Contributions for Nature Platform',

  // documents
  'Data Entry Module - Guidance': 'Data Entry Module - Guidance',
  'Validation Module - Guidance': 'Validation Module - Guidance',
  'Frequently Asked Questions (FAQs)': 'Frequently Asked Questions (FAQs)',
  'Terms and Conditions': 'Terms and Conditions',
  //
  'What progress are conservation actions making towards global goals for nature?':
    'What progress are conservation actions making towards global goals for nature?',
  'The IUCN Programme Nature 2030, and other agreements have established targets to stem and ultimately reverse this loss. For many decades, the global conservation community has been implementing actions that contribute towards these global goals for nature. It is crucial that we collectively document and understand these contributions for nature, where and when they are being implemented, by who, and how much they are contributing towards global goals This IUCN Contributions for Nature platform, mandated by the IUCN Membership in Nature 2030 addresses these questions.':
    'The IUCN Programme Nature 2030, and other agreements have established targets to stem and ultimately reverse this loss. For many decades, the global conservation community has been implementing actions that contribute towards these global goals for nature. It is crucial that we collectively document and understand these contributions for nature, where and when they are being implemented, by who, and how much they are contributing towards global goals This IUCN Contributions for Nature platform, mandated by the IUCN Membership in Nature 2030 addresses these questions.',
  'See what the conservation community is contributing towards global goals for nature.':
    'See what the conservation community is contributing towards global goals for nature.',
  'Use the Contributions for Nature platform to view contributions being made or planned by IUCN constituents - including non-governmental organisations, indigenous peoples organisations, governments, state agencies, and regional and local governments, as well as the IUCN Commissions, Committees, and Secretariat. You can also use the platform to quantify these contributions in terms of their potential delivery of global goals for biodiversity conservation and climate change mitigation. This can be achieved through the use of the Species Threat Abatement and Restoration Metric (STAR) and the Restoration Barometer.':
    'Use the Contributions for Nature platform to view contributions being made or planned by IUCN constituents - including non-governmental organisations, indigenous peoples organisations, governments, state agencies, and regional and local governments, as well as the IUCN Commissions, Committees, and Secretariat. You can also use the platform to quantify these contributions in terms of their potential delivery of global goals for biodiversity conservation and climate change mitigation. This can be achieved through the use of the Species Threat Abatement and Restoration Metric (STAR) and the Restoration Barometer.',
  "Document your Organisation's Land Contributions": "Document your Organisation's Land Contributions",
  'The Contributions for Nature platform allows IUCN constituents to add contributions by entering spatial data describing their current or planned conservation or restoration actions, along with associated data on types of threats being mitigated and types of actions underway, timeframes, and level of investment.':
    'The Contributions for Nature platform allows IUCN constituents to add contributions by entering spatial data describing their current or planned conservation or restoration actions, along with associated data on types of threats being mitigated and types of actions underway, timeframes, and level of investment.',
  'Currently, only IUCN Members (including in National and Regional Committees), Commissions and Secretariat can add contributions to the platform.':
    'Currently, only IUCN Members (including in National and Regional Committees), Commissions and Secretariat can add contributions to the platform.',
  'The Contributions for Nature platform draws from the following datasets and platforms':
    'The Contributions for Nature platform draws from the following datasets and platforms',

  // footer

  'Commission on Ecosystem Management (CEM)': 'Commission on Ecosystem Management (CEM)',
  'Commission on Education and Communication (CEC)': 'Commission on Education and Communication (CEC)',
  'Environmental, Economic and Social Policy (CEESP)': 'Environmental, Economic and Social Policy (CEESP)',
  'Species Survival Commission (SSC)': 'Species Survival Commission (SSC)',
  'World Commission on Environmental Law (WCEL)': 'World Commission on Environmental Law (WCEL)',
  'World Commission on Protected Areas (WCPA)': 'World Commission on Protected Areas (WCPA)',
  Contact: 'Contact',
  Headquarters: 'Headquarters',

  'Follow Us': 'Follow Us',
  'Crossroads Blog': 'Crossroads Blog',
  '©2022 IUCN,International Union for Conservations of Nature':
    '©2022 IUCN,International Union for Conservations of Nature',

  // main panel
  'Search & Filter Contributions': 'Search & Filter Contributions',
  'You will only see filters for which we have data': 'You will only see filters for which we have data',
  'Search Constituents': 'Search Constituents',

  'Quick Global Stats': 'Quick Global Stats',
  'Close Statistics': 'Close Statistics',
  'Expand Statistics': 'Expand Statistics',
  'Conservation contribution to biodiversity': 'Conservation contribution to biodiversity',
  'Restoration contribution to biodiversity': 'Restoration contribution to biodiversity',
  'Restoration contribution to climate change mitigation': 'Restoration contribution to climate change mitigation',
  'Filter by IUCN constituent type': 'Filter by IUCN constituent type',
  'View Records': 'View Records',
  'No results found!': 'No results found!',

  // org panel
  'No Data': 'No Data',
  'Return to Results': 'Return to Results',
  'Total number of contributions': 'Total number of contributions',
  'STAR Threat Abatement Score': 'STAR Threat Abatement Score',
  'STAR Restoration Score': 'STAR Restoration Score',
  'Total Climate Change Mitigation': 'Total Climate Change Mitigation',
  'Total Budget': 'Total Budget',
  'Total Budget needed': 'Total Budget needed',
  'Total number of Staff': 'Total number of Staff',
  'Total number of Beneficiaries': 'Total number of Beneficiaries',
  'No description available': 'No description available',

  Contributions: 'Contributions',
  'Search for contribution name': 'Search for contribution name',
  'No contributions found!': 'No contributions found!',
  Loading: 'Loading',

  //country panel
  'National Report': 'National Report',
  total: 'total',

  /// region panel
  'Regional Report': 'Regional Report',

  //contribution level
  'Select Organization': 'Select Organization',
  Partner: 'Partner',
  Owner: 'Owner',

  // charts global charts

  'Star Restoration': 'Star Restoration',
  Global: 'Global',
  Contribution: 'Contribution',
  'Threat Abatement': 'Threat Abatement',
  'Climate Mitigation': 'Climate Mitigation',
  'Global total': 'Global total',

  // contribution charts
  Restoration: 'Restoration',
  'Contribution total': 'Contribution total',
  'Contribution to Land region Climate Mitigation': 'Contribution to Land region Climate Mitigation',

  'Potential contribution of abating different threats towards species extinction risk reduction':
    'Potential contribution of abating different threats towards species extinction risk reduction',
  'Potential contribution of restoration towards species extinction risk reduction':
    'Potential contribution of restoration towards species extinction risk reduction',
  //
  "Contribution's potential contribution towards climate change mitigation through restoration as a proportion of the total global total climate mitigation.":
    "Contribution's potential contribution towards climate change mitigation through restoration as a proportion of the total global total climate mitigation.",
  'Contribution as a proportion of the global total potential contribution towards species extinction risk reduction by restoration.':
    'Contribution as a proportion of the global total potential contribution towards species extinction risk reduction by restoration.',
  //
  'Contribution as a proportion of the global total potential contribution towards species extinction risk reduction by abating threats.':
    'Contribution as a proportion of the global total potential contribution towards species extinction risk reduction by abating threats.',
  'Contribution as a proportion of the global total potential contribution towards species extinction risk reduction by abating threats':
    'Contribution as a proportion of the global total potential contribution towards species extinction risk reduction by abating threats',
  'Potential contribution towards climate change mitigation through restoration as a proportion of the total':
    'Potential contribution towards climate change mitigation through restoration as a proportion of the total',
  'potential contribution': 'potential contribution',
  'Potential contribution towards climate change mitigation through restoration as a proportion of the total global potential contribution':
    'Potential contribution towards climate change mitigation through restoration as a proportion of the total global potential contribution',
  'Contribution as a proportion of': 'Contribution as a proportion of',
  'total potential contribution towards species extinction risk reduction by restoration.':
    'total potential contribution towards species extinction risk reduction by restoration.',
  'Potential contribution towards species extinction risk reduction through restoration as a proportion of the total global potential contribution':
    'Potential contribution towards species extinction risk reduction through restoration as a proportion of the total global potential contribution',
  'total potential contribution towards species extinction risk reduction by abating threat':
    'total potential contribution towards species extinction risk reduction by abating threat.',
  'Potential contribution towards species extinction risk reduction by abating threats as a proportion of the total global potential contribution':
    'Potential contribution towards species extinction risk reduction by abating threats as a proportion of the total global potential contribution',
  "Contribution's potential contribution towards climate change mitigation through restoration as a proportion of the total":
    "Contribution's potential contribution towards climate change mitigation through restoration as a proportion of the total",
  'Potential contribution towards species extinction risk reduction through restoration as a proportion of the potential contribution from':
    'Potential contribution towards species extinction risk reduction through restoration as a proportion of the potential contribution from',
  'Potential contribution towards species extinction risk reduction by abating threats as a proportion of the potential contribution from':
    'Potential contribution towards species extinction risk reduction by abating threats as a proportion of the potential contribution from',

  overall: 'overall',

  // layer widget dropdown
  'Additional Layers': 'Additional Layers',
  // select country/region
  'Select by': 'Select by',
  Region: 'Region',
  Continent: 'Continent',
  Country: 'Country',
  'Land region': 'Land region',
  'select country': 'select country',
  'select land region': 'select land region',
  'select region': 'select region',
  'select continent': 'select continent',
  // 'Search for contribution name': 'Search for contribution name'
  'select contribution': 'select contribution',

  // column chart
  '% Contribution to Project': '% Contribution to Project',
  Percent: 'Percent',
  'Threat Type': 'Threat Type',
  'No data available': 'No data available',
  'Percentage value': 'Percentage value',
  'Absolute value': 'Absolute value',

  // global chart modal content
  Method: 'Method',
  'Methods for generating Conservation and Restoration contributions towards biodiversity goals Contributions towards biodiversity goals are generated using the Species Threat Abatement and Restoration (STAR) Metric, which quantifies the potential reduction in global species extinction risk that can be achieved by acting through threat abatement or restoration in a specific area (Mair et al., 2021).':
    'Methods for generating Conservation and Restoration contributions towards biodiversity goals Contributions towards biodiversity goals are generated using the Species Threat Abatement and Restoration (STAR) Metric, which quantifies the potential reduction in global species extinction risk that can be achieved by acting through threat abatement or restoration in a specific area (Mair et al., 2021).',

  'Data Sources': 'Data Sources',

  "Method: STAR scores are calculated for species of amphibians, birds and mammals for which current or historical area of habitat occurs in the Area of Interest. Species assessed as Near Threatened, Vulnerable, Endangered or Critically Endangered on the IUCN Red List of Threatened Species receive non-zero weightings in calculating the STAR score. For each 5x5 km grid square, the STAR Threat Abatement (STAR TA) score for a species is assessed based on the proportion of current range represented by the grid square, and the species extinction risk. Adding STAR scores across all species gives the total STAR score for the square. Adding all the STAR scores across all the squares in a particular defined area indicates that site's relative conservation contribution towards biodiversity goals.":
    "Method: STAR scores are calculated for species of amphibians, birds and mammals for which current or historical area of habitat occurs in the Area of Interest. Species assessed as Near Threatened, Vulnerable, Endangered or Critically Endangered on the IUCN Red List of Threatened Species receive non-zero weightings in calculating the STAR score. For each 5x5 km grid square, the STAR Threat Abatement (STAR TA) score for a species is assessed based on the proportion of current range represented by the grid square, and the species extinction risk. Adding STAR scores across all species gives the total STAR score for the square. Adding all the STAR scores across all the squares in a particular defined area indicates that site's relative conservation contribution towards biodiversity goals.",

  'Restoration contributions towards biodiversity goals (STAR R) are calculated similarly to STAR Threat Abatement Scores, but for areas formerly occupied by a species. They indicate the potential of restoration within a grid cell to reduce species extinction risk. A global weighting of 0.29 is applied to STAR Restoration scores based on studies of restoration success, to discount for the time taken for restoration and its probability of success.':
    'Restoration contributions towards biodiversity goals (STAR R) are calculated similarly to STAR Threat Abatement Scores, but for areas formerly occupied by a species. They indicate the potential of restoration within a grid cell to reduce species extinction risk. A global weighting of 0.29 is applied to STAR Restoration scores based on studies of restoration success, to discount for the time taken for restoration and its probability of success.',

  'Pie charts visualizing the STAR TA and STAR R contributions in a site as a proportion of the maximum potential contribution that could be generated in terrestrial United Nations Statistical Division M49 Geographic Regions and Countries.':
    'Pie charts visualizing the STAR TA and STAR R contributions in a site as a proportion of the maximum potential contribution that could be generated in terrestrial United Nations Statistical Division M49 Geographic Regions and Countries.',

  'Data used to compute STAR': 'Data used to compute STAR',

  'The underlying STAR data is produced from the IUCN Red List of Threatened Species (iucnredlist.org)':
    'The underlying STAR data is produced from the IUCN Red List of Threatened Species (iucnredlist.org)',

  "Method: STAR scores are calculated for species of amphibians, birds and mammals for which current or historical area of habitat occurs in the Area of Interest. Species assessed as Near Threatened, Vulnerable, Endangered or Critically Endangered on the IUCN Red List of Threatened Species receive non-zero weightings in calculating the STAR score. For each 5x5 km grid square, the STAR Threat Abatement (STAR TA) score for a species is assessed based on the proportion of current range represented by the grid square, and the species extinction risk. Adding STAR scores across all species gives the total STAR score for the square. Adding all the STAR scores across all the squares in a particular defined area indicates that site's relative  conservation contribution towards biodiversity goals.":
    "Method: STAR scores are calculated for species of amphibians, birds and mammals for which current or historical area of habitat occurs in the Area of Interest. Species assessed as Near Threatened, Vulnerable, Endangered or Critically Endangered on the IUCN Red List of Threatened Species receive non-zero weightings in calculating the STAR score. For each 5x5 km grid square, the STAR Threat Abatement (STAR TA) score for a species is assessed based on the proportion of current range represented by the grid square, and the species extinction risk. Adding STAR scores across all species gives the total STAR score for the square. Adding all the STAR scores across all the squares in a particular defined area indicates that site's relative  conservation contribution towards biodiversity goals.",

  'Methods for generating Restoration contributions towards climate change mitigation goals Contributions towards carbon sequestration goals are generated through the IUCN- Winrock FLR removal database, which provides the potential impacts of climate mitigation based on the global carbon dioxide (CO2) removal rates for the selective FLR intervention activities. The carbon sequestration potential of restoration activities iscalculated by multiplying the restoration area and region-specific carbon removal factors.':
    'Methods for generating Restoration contributions towards climate change mitigation goals Contributions towards carbon sequestration goals are generated through the IUCN- Winrock FLR removal database, which provides the potential impacts of climate mitigation based on the global carbon dioxide (CO2) removal rates for the selective FLR intervention activities. The carbon sequestration potential of restoration activities iscalculated by multiplying the restoration area and region-specific carbon removal factors.',

  'The carbon removal factors are derived from the Global FLR CO2 removal database, developed by IUCN in partnership with Winrock international. This database offers potential CO2 removals from specific FLR actions (i.e. natural regeneration, planted forests and woodlots, agroforestry and mangrove restoration) at both national and subnational scales.':
    'The carbon removal factors are derived from the Global FLR CO2 removal database, developed by IUCN in partnership with Winrock international. This database offers potential CO2 removals from specific FLR actions (i.e. natural regeneration, planted forests and woodlots, agroforestry and mangrove restoration) at both national and subnational scales.',

  'The carbon removal factors are derived from the Global FLR CO2 removal database, developed by IUCN in partnership with Winrock international. This database offers potential CO2 removals from specific FLR actions (i.e. natural regeneration, planted forests and woodlots, agroforestry and mangrove restoration) at both national and subnational scales. The carbon removal rate (i.e removal factor) database was developed by reviewing the literature on biomass accumulation rates from FLR activities across the globe, using published and scientifically validated data. All these collected data points are converted into spatial points and also overlaid on the global Koppen-Geiger Climate classification to determine the climate of the FLR sites. The aboveground biomass curve was developed by Chapman Richards growth model applied by collating data points over 144 studies whereby the belowground biomass was estimated as a function of the calculated aboveground biomass. For each FLR activity, the sum of the aboveground and belowground biomass growth rates are presented as total tons C ha-1 year-1 and then converted to annual removal factors as carbon dioxide (tons CO2 ha-1 year-1) by multiplying the tons of C by the factor 3.66 (i.e., 44 g CO2 over 12 g C).':
    'The carbon removal factors are derived from the Global FLR CO2 removal database, developed by IUCN in partnership with Winrock international. This database offers potential CO2 removals from specific FLR actions (i.e. natural regeneration, planted forests and woodlots, agroforestry and mangrove restoration) at both national and subnational scales. The carbon removal rate (i.e removal factor) database was developed by reviewing the literature on biomass accumulation rates from FLR activities across the globe, using published and scientifically validated data. All these collected data points are converted into spatial points and also overlaid on the global Koppen-Geiger Climate classification to determine the climate of the FLR sites. The aboveground biomass curve was developed by Chapman Richards growth model applied by collating data points over 144 studies whereby the belowground biomass was estimated as a function of the calculated aboveground biomass. For each FLR activity, the sum of the aboveground and belowground biomass growth rates are presented as total tons C ha-1 year-1 and then converted to annual removal factors as carbon dioxide (tons CO2 ha-1 year-1) by multiplying the tons of C by the factor 3.66 (i.e., 44 g CO2 over 12 g C).',

  'Pie charts visualizing the carbon sequestration contributions in a site as a proportion to countries FLR basedrestoration commitments potential of climate mitigation. The Bonn Challenge and RIO conventions pledges from 115 countries are used for this pie chart.':
    'Pie charts visualizing the carbon sequestration contributions in a site as a proportion to countries FLR basedrestoration commitments potential of climate mitigation. The Bonn Challenge and RIO conventions pledges from 115 countries are used for this pie chart.',

  'Bernal, Blanca, Lara T. Murray, and Timothy RH Pearson. "Global carbon dioxide removal rates from forest landscape restoration activities." Carbon balance and management 13.1 (2018): 1-13.':
    'Bernal, Blanca, Lara T. Murray, and Timothy RH Pearson. "Global carbon dioxide removal rates from forest landscape restoration activities." Carbon balance and management 13.1 (2018): 1-13.',

  'Data used to compute Pie Charts': 'Data used to compute Pie Charts',

  'Sewell, Annelies, Stefan van der Esch, and Hannah Löwenhardt. "Goals and Commitments for the Restoration Decade." Sewell, Annelies, H. Löwenhardt, and S. Van der Esch. "Technical Note on Methodology for the Global Restoration Commitments Database." PBL Netherlands Environmental Assessment Agency, The Hague (2020).':
    'Sewell, Annelies, Stefan van der Esch, and Hannah Löwenhardt. "Goals and Commitments for the Restoration Decade." Sewell, Annelies, H. Löwenhardt, and S. Van der Esch. "Technical Note on Methodology for the Global Restoration Commitments Database." PBL Netherlands Environmental Assessment Agency, The Hague (2020).',

  // map footer

  'The designations employed and the presentation of material on this map do not imply the expression of any opinion whatsoever on the part of IUCN concerning the legal status of any country, territory, city or area or its authorities, or concerning the delimitation of its frontiers or boundaries.':
    'The designations employed and the presentation of material on this map do not imply the expression of any opinion whatsoever on the part of IUCN concerning the legal status of any country, territory, city or area or its authorities, or concerning the delimitation of its frontiers or boundaries.',
  'Powered by Esri': 'Powered by Esri',
  'Send Feedback': 'Send Feedback',

  // countryChartDataBuilder
  'Threat Abatement Land region-Continent': 'Threat Abatement Land region-Continent',
  'Threat Abatement Continent-Global': 'Threat Abatement Continent-Global',
  'Restoration Land region - Continent': 'Restoration Land region - Continent',
  'Restoration Continent-Global': 'Restoration Continent-Global',
  'Climate Mitigation Land region - Continent': 'Climate Mitigation Land region - Continent',
  'Climate Mitigation Continent-Global': 'Climate Mitigation Continent-Global',
  'Climate Mitigation Contribution-Land region': 'Climate Mitigation Contribution-Land region',
  'Restoration Contribution-Land region': 'Restoration Contribution-Land region',
  'Threat Abatement Contribution-Land region': 'Threat Abatement Contribution-Land region',

  'Land region to continent Climate Mitigation': 'Land region to continent Climate Mitigation',

  'Continent to global Climate Mitigation': 'Continent to global Climate Mitigation',

  // fetchOrgChartData
  'Global Comparison': 'Global Comparison',
  'Regional Comparison': 'Regional Comparison',
  'Land region Comparison': 'Land region Comparison',
  'Total Staff': 'Total Staff',
  'Total Beneficiares': 'Total Beneficiares',
  'Total Budget Needed': 'Total Budget Needed',

  // popup template
  Name: 'Name',
  'IUCN Constituent': 'IUCN Constituent',
  'IUCN Constituent type': 'IUCN Constituent type',
  'Start Date': 'Start Date',
  'End Date': 'End Date',
  'Threats to be Abated': 'Threats to be Abated',
  'Conservation Actions': 'Conservation Actions',
  'Restoration Actions': 'Restoration Actions',
  Description: 'Description',

  //
  'Partner(s)': 'Partner(s)',

  //temp login
  // leave this for future purposes when we need to add a login back
  'International Union for Conservation of Nature': 'International Union for Conservation of Nature',
  'Use your IUCN credentials to login.': 'Use your IUCN credentials to login.',
  'User Name': 'User Name',
  Password: 'Password',
  Login: 'Login',
};
