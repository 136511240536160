import { NumberTypes, StatisticsParamsTypes } from './types';

export const getStatisticsParms = (values: StatisticsParamsTypes) => {
  return {
    statistics: values.statistics,
    where: values.where,
    url: values.url,
    groupByFieldsForStatistics: values.groupByFieldsForStatistics,
  };
};

export const removeDecimalsFromNumber = (number: number | null | undefined) => {
  if (number === null || number === undefined) {
    return 0;
  }

  if (Number.isInteger(number)) {
    return number;
  }
  return number;
};

const handleNullValues = (value: NumberTypes) => {
  if (value === null || value === undefined) {
    return 0;
  }
  return value;
};

export const getTotalByProp = (data: any, key: string) => {
  return data.map((value: any) => handleNullValues(value[`${key}`])).reduce((a: number, b: number) => a + b, 0);
};

export const getCarbonTotal = (data: any) => {
  return data
    .map((values: any) => values.carbonValue)
    .filter((values: any) => values !== null)
    .map((values: any) => JSON.parse(values))
    .map((values: any) => values.result.totalCarbon)
    .reduce((a: number, b: number) => a + b, 0);
};

export const queryStringBuilder = (value: string) => {
  return `ownerOrgs IS NOT NULL AND beneficiaryCountries IS NOT NULL AND ${value}`;
};

/**
 *
 * @param valueOne expected to be the smaller than valueTwo (2129.86962325616)
 * @param valueTwo expected to be greater than valueOne (3823.94489503691)
 * @returns { y: valueOne - valueTwo, rawValue: valueTwo}
 * Note: we need to have access to the raw value to display as absolute value in tooltip chart
 */
export const getRemainingValue = (valueOne: NumberTypes, valueTwo: NumberTypes) => {
  return {
    y: handleNullValues(valueTwo) - handleNullValues(valueOne),
    rawValue: handleNullValues(valueTwo),
  };
};
