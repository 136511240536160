import { FaLongArrowAltRight } from 'react-icons/fa';
import './showRecordsComponent.scss';
import { useSelector } from 'react-redux';
import { languageSelector } from '../../store/slices/appSlice';
import { TRANSLATIONS } from '../../translations/translations';

interface Props {
  data: any;
  handleRecordSelection: (record: any) => void;
}

const ShowRecordsComponent = ({ data, handleRecordSelection }: Props) => {
  const language = useSelector(languageSelector);
  const selectedLanguage = TRANSLATIONS[language];

  return (
    <div className='side-panel-geometry-records'>
      {!!data.length &&
        data.map((data: any, index: number) => {
          const { orgName } = data;
          return (
            <div key={index}>
              {orgName && (
                <div className='geometry-information' onClick={() => handleRecordSelection(data)}>
                  <div className='organization-wrapper'>
                    <p className='org-name'>{orgName}</p>
                    <span>
                      {`${selectedLanguage['View Records']} (${data.orgCount})`}
                      <FaLongArrowAltRight />
                    </span>
                  </div>
                </div>
              )}
            </div>
          );
        })}
      {!data.length && <h3 className='not-results-found'>{selectedLanguage['No results found!']}</h3>}
    </div>
  );
};

export default ShowRecordsComponent;
