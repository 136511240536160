import ReactDOM from 'react-dom';
import Switch from 'react-switch';
import { Provider, useDispatch, useSelector } from 'react-redux';
import store from '../../../store/store';
import {
  countryOptionsSelector,
  displayCountryLayerSelector,
  selectedCountryOrRegionSelector,
  SelectedCountryOrRegionTypes,
  setDisplayCountryLayer,
  setSelectedCountryOrRegion,
} from '../../../store/slices/mapSlice';
import { mapController } from '../../../controllers/MapController';
import SelectComponent from '../../selectComponent/SelectComponent';
import { regions } from '../../../configs/regionsConfig';
import { LAYER_IDS } from '../../../configs/layersConfig';
import { isCountryLoadingSelector, setIsCountryLoading, setVisiblePanel } from '../../../store/slices/sidePanelSlice';
import './index.scss';
import SearchContributionWidget from './SearchContributionsWidget';
import { TRANSLATIONS } from '../../../translations/translations';
import { languageSelector, setCountryLevelData, setRegionLevelData } from '../../../store/slices/appSlice';

// we want to make an exception for the following regions so we can overwrite the map extent to show the region
export const EXCLUDED_REGIONS = {
  Oceania: {
    // australia and china
    where: "ISO3 IN ('AUS', 'CHN')",
  },
  'The Americas': {
    // mexico and brazil
    where: "ISO3 IN ('MEX', 'BRA')",
  },
  Europe: {
    // united kingdom and mongolia
    where: "ISO3 IN ('GBR', 'MNG')",
  },
} as any;

const DropdownWidgetsWrapper = () => {
  const displayCountryLayer = useSelector(displayCountryLayerSelector);
  const selectedCountryOrRegion = useSelector(selectedCountryOrRegionSelector);
  const countryOptions = useSelector(countryOptionsSelector);
  const isCountryLoading = useSelector(isCountryLoadingSelector);
  const dispatch = useDispatch();

  const language = useSelector(languageSelector);
  const selectedLanguage = TRANSLATIONS[language];

  const handleChange = (checked: boolean) => {
    dispatch(setSelectedCountryOrRegion(null));
    dispatch(setRegionLevelData({ region: '', OBJECTID: null, data: [] }));
    dispatch(setCountryLevelData({ country: '', countryISO3: '', data: [] }));
    dispatch(setDisplayCountryLayer(checked));
    mapController.toggleLayerVisibility(checked);
  };

  const handleSelectChange = (value: SelectedCountryOrRegionTypes | null) => {
    dispatch(setSelectedCountryOrRegion(value));

    if (value) {
      // display loading skeleton once country/region is selected
      dispatch(setIsCountryLoading(true));
      dispatch(setVisiblePanel('country-panel'));

      const queryString = displayCountryLayer ? 'ISO3' : 'MapRegion';
      const params = { where: `${queryString} = '${value.value}'` };

      if (displayCountryLayer) {
        mapController.handleQueryCountry(params, LAYER_IDS.COUNTRY_LAYER);
      } else {
        const property = EXCLUDED_REGIONS[value.value];
        if (property) {
          // if property exists in the list, we want to overwrite the map extent
          mapController.queryCountryBasedOnRegionException(property.where, LAYER_IDS.COUNTRY_LAYER);
        }

        mapController.handleQueryRegion(params, LAYER_IDS.REGION_LAYER, !property);
      }
    } else {
      dispatch(setVisiblePanel('main-panel'));
      dispatch(setRegionLevelData({ region: '', OBJECTID: null, data: [] }));
      dispatch(setCountryLevelData({ country: '', countryISO3: '', data: [] }));
      mapController.resetMapView();
    }
  };

  return (
    <div className='DropdownWidgetsWrapper'>
      <div className='switch-component'>
        <h4 className='view-by-text'>{selectedLanguage['Select by']}:</h4>
        <button
          disabled={isCountryLoading}
          onClick={() => handleChange(!displayCountryLayer)}
          className={!displayCountryLayer ? 'region selected' : 'region'}
        >
          {selectedLanguage['Continent']}
        </button>
        <Switch
          checkedIcon={false}
          uncheckedIcon={false}
          offColor='#fff'
          onColor='#fff'
          className='react-switch'
          offHandleColor='#003478'
          activeBoxShadow='0'
          onHandleColor='#003478'
          height={18}
          width={40}
          disabled={isCountryLoading}
          checked={displayCountryLayer}
          onChange={handleChange}
        />
        <button
          disabled={isCountryLoading}
          onClick={() => handleChange(!displayCountryLayer)}
          className={displayCountryLayer ? 'country selected' : 'country'}
        >
          {selectedLanguage['Land region']}
        </button>
      </div>
      <div className='select-region-country'>
        <SelectComponent
          isMulti={false}
          options={displayCountryLayer ? countryOptions : regions}
          value={selectedCountryOrRegion}
          onChange={handleSelectChange}
          isDisabled={isCountryLoading}
          placeholder={
            displayCountryLayer ? selectedLanguage['select land region'] : selectedLanguage['select continent']
          }
        />
      </div>
      <SearchContributionWidget />
    </div>
  );
};

export const dropdownWidgetsWrapper = (mapView: __esri.MapView, index: number) => {
  const node = document.createElement('div');
  mapView?.ui.add(node, { position: 'top-right', index });
  ReactDOM.render(
    <Provider store={store}>
      <DropdownWidgetsWrapper />
    </Provider>,
    node
  );
};
