import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-responsive-modal';
import { MdClose } from 'react-icons/md';

import 'react-responsive-modal/styles.css';
import './styles/globalModal.scss';
import {
  globalModalContentSelector,
  isGlobalModalOpenSelector,
  setIsGlobalModalOpen,
} from '../../store/slices/appSlice';

const GlobalModal = () => {
  const isGlobalModalOpen = useSelector(isGlobalModalOpenSelector);
  const globalModalContent = useSelector(globalModalContentSelector);
  const dispatch = useDispatch();

  const handleCloseModal = () => {
    dispatch(setIsGlobalModalOpen(!isGlobalModalOpen));
  };

  return (
    <div className='modal'>
      <Modal
        open={isGlobalModalOpen}
        onClose={handleCloseModal}
        closeOnOverlayClick={false}
        center
        showCloseIcon={false}
        classNames={{
          overlay: 'modal-overlay',
          modal: 'global-custom-modal',
        }}
      >
        <div className='global-custom-modal-wrapper'>
          <div className='global-custom-modal-header'>
            <h1>{globalModalContent.title}</h1>
            <button onClick={handleCloseModal}>
              <MdClose />
            </button>
          </div>
          <div className='description-wrapper'>{globalModalContent.content}</div>
        </div>
      </Modal>
    </div>
  );
};

export default GlobalModal;
