import { StarRTATypes } from '../types';
import i18n from '../../../../i18n';
import { OrgsInContributionTypes } from '../../../../store/slices/sidePanelSlice';

const colors = [
  '#3f6dd1',
  '#3f4dd1',
  '#5637ac',
  'purple',
  '#563186',
  '#3d235f',
  '#440154',
  '#270031',
  '#23053a',
  '#191025',
];

export const columnDataBuilder = (data: StarRTATypes[]) => {
  let getFirstTenValues;
  const sortData = data.sort((a, b) => a.percent - b.percent);
  if (data.length > 10) {
    getFirstTenValues = sortData.slice(0, 10);
  } else {
    getFirstTenValues = sortData;
  }

  return getFirstTenValues.map((item, index) => {
    const isOdd = index % 2 === 0;
    const labelLength = item.label.length;
    const smallLabel = labelLength * 6.5;
    const labelPosition = labelLength > 10 ? labelLength * 5.5 : smallLabel;

    return {
      color: colors[index],
      name: i18n.t(item.label),
      pointWidth: 150,
      data: [item.percent],
      dataLabels: {
        x: isOdd ? -labelPosition : labelPosition,
        align: isOdd ? 'left' : 'right',
      },
    };
  });
};

export const addPropsToData = (data: string[], isPartner: boolean) => {
  if (!data?.length) return [];
  return data.map((org) => {
    return {
      label: org,
      isPartner: isPartner,
    };
  });
};

export const checkForMultipleOrgs = (ownerOrgs: string, partnerOrgs: string | null) => {
  let orgsData: OrgsInContributionTypes[] = [];
  const splitOrgs = ownerOrgs?.split('|');

  const updateOrgsData = addPropsToData(splitOrgs, false);

  if (partnerOrgs) {
    const updatePartners = addPropsToData(partnerOrgs.split('|'), true);
    orgsData.push(...updatePartners);
  }
  orgsData.push(...updateOrgsData);
  return orgsData;
};
export const handleAttributes = (attributes: null | any) => {
  if (attributes) {
    const {
      name,
      ownerOrgs,
      budgetNeeded,
      totalStaff,
      totalBeneficiaries,
      totalBudget,
      beneficiaryCountries,
      beneficiaryRegions,
      starValueR,
      starValueTA,
      star_threatTypes_r,
      star_threatTypes_ta,
      partnerOrgs,
    } = attributes;
    return {
      name,
      ownerOrgs,
      budgetNeeded,
      totalStaff,
      totalBeneficiaries,
      totalBudget,
      beneficiaryCountries,
      beneficiaryRegions,
      starValueR,
      starValueTA,
      star_threatTypes_r,
      star_threatTypes_ta,
      partnerOrgs,
    };
  } else {
    return {
      name: '',
      ownerOrgs: '',
      budgetNeeded: '',
      totalStaff: '',
      totalBeneficiaries: '',
      totalBudget: '',
      beneficiaryCountries: '',
      beneficiaryRegions: '',
      starValueR: '',
      starValueTA: '',
      star_threatTypes_r: '',
      star_threatTypes_ta: '',
      partnerOrgs: '',
    };
  }
};
