import { useMemo } from 'react';
import { ChartContentType } from '../../store/slices/sidePanelSlice';
import { PieChartDataComponent } from '../../utils/types';
import PieChartWithoutWrapper from '../charts/PieChartWithoutWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { languageSelector } from '../../store/slices/appSlice';
import { TRANSLATIONS } from '../../translations/translations';
import ChartTooltip, { ChartTypes } from '../tooltipComponent/ChartTooltiop';
import { setVisibleChartOrg, visibleChartOrgSelector } from '../../store/slices/chartSlice';
import './tabbedCharts.scss';

interface TabbedChartsProps {
  chartData: Record<ChartContentType, any[] | PieChartDataComponent[]>;
  chartType?: ChartContentType;
  busy: boolean;
}

const TabbedCharts = ({ chartData, busy = false }: TabbedChartsProps) => {
  const language = useSelector(languageSelector);
  const visibleChartOrg = useSelector(visibleChartOrgSelector);
  const selectedLanguage = TRANSLATIONS[language];

  const dispatch = useDispatch();

  const IUCN_SECTIONS = useMemo(
    () => [
      {
        label: selectedLanguage['Conservation contribution to biodiversity'],
        chartType: 'star-threat-abatement' as ChartContentType,
        chartTooltiplabel: 'Conservation' as ChartTypes,
      },
      {
        label: selectedLanguage['Restoration contribution to biodiversity'],
        chartType: 'star-restoration' as ChartContentType,
        chartTooltiplabel: 'Restoration' as ChartTypes,
      },
      {
        label: selectedLanguage['Restoration contribution to climate change mitigation'],
        chartType: 'climate-mitigation' as ChartContentType,
        chartTooltiplabel: 'Climate change' as ChartTypes,
      },
    ],
    [selectedLanguage]
  );

  const createButtons = () =>
    IUCN_SECTIONS.map(({ label, chartType, chartTooltiplabel }) => (
      <button
        key={`tab-${chartType}`}
        className={visibleChartOrg === chartType ? 'active tab-button' : 'tab-button'}
        onClick={() => dispatch(setVisibleChartOrg(chartType))}
      >
        {label}
        <ChartTooltip chartType={chartTooltiplabel} />
      </button>
    ));

  return (
    <div className='tabbed-charts-container'>
      <div className='tab-buttons'>{createButtons()}</div>
      {busy && (
        <div className='loading-message'>
          <div>{selectedLanguage['Loading Data']}</div>
        </div>
      )}
      <div className='charts'>
        {chartData[visibleChartOrg] &&
          chartData[visibleChartOrg].map((data, index) => {
            const [part, whole, label] = data;
            return (
              <div className='chart' key={`chart-${visibleChartOrg}-${index}`}>
                <p>{label}</p>
                <PieChartWithoutWrapper data={[part, whole]} numberOfChartsInSet={chartData[visibleChartOrg].length} />
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default TabbedCharts;
