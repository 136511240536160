import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  setLanguage,
  languageSelector,
  LanguageType,
  showHeaderRightBorderSelector,
} from '../../store/slices/appSlice';
import { useTranslation } from 'react-i18next';
import './styles/LanguageSwitcher.scss';

interface LangaugeSwitcherProps {
  availableLanguages: Array<LanguageType>;
  localStorageKey: string;
}

const LangaugeSwitcher = ({ availableLanguages, localStorageKey }: LangaugeSwitcherProps) => {
  const dispatch = useDispatch();

  const language = useSelector(languageSelector);
  const showHeaderRightBorder = useSelector(showHeaderRightBorderSelector);
  const [currentLanguage, setCurrentLanguage] = useState(language);
  const { i18n } = useTranslation();

  const handleLanguageChange = (code: LanguageType) => {
    if (localStorageKey > '') {
      localStorage.setItem(localStorageKey, code);
    }
    setCurrentLanguage(code);
    dispatch(setLanguage(code));
  };

  useEffect(() => {
    i18n.changeLanguage(currentLanguage);
  }, [currentLanguage, i18n]);

  return (
    <div className={showHeaderRightBorder ? 'language-switcher show-right-border' : 'language-switcher'}>
      {availableLanguages.map((code) => (
        <button
          key={`language-${code}`}
          className={currentLanguage === code ? 'active' : 'normal'}
          onClick={() => handleLanguageChange(code)}
        >
          {code.toUpperCase()}
        </button>
      ))}
    </div>
  );
};

export default LangaugeSwitcher;
