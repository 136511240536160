import { usePopperTooltip } from 'react-popper-tooltip';
import { BsFillQuestionCircleFill } from 'react-icons/bs';
import 'react-popper-tooltip/dist/styles.css';
import './styles/tooltipComponent.scss';

interface Props {
  description: string;
  position?: 'top' | 'bottom' | 'left' | 'right';
}

const TooltipComponent = ({ description, position }: Props) => {
  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible } = usePopperTooltip({
    trigger: 'hover',
    placement: position ? position : 'top',
  });

  return (
    <div className='tooltip'>
      <span ref={setTriggerRef} className='tooltip-icon'>
        <BsFillQuestionCircleFill />
      </span>
      {visible && (
        <div ref={setTooltipRef} {...getTooltipProps({ className: 'tooltip-container' })}>
          <div {...getArrowProps({ className: 'tooltip-arrow' })} />
          <p>{description}</p>
        </div>
      )}
    </div>
  );
};

export default TooltipComponent;
