import axios from 'axios';
import config from '../configs/config';
import { mapController } from '../controllers/MapController';

export const generateToken = async (): Promise<string | null> => {
  try {
    const { data } = await axios.get(`${config.apiUrl}/auth/agol-token`);
    if (!!data && data.token) {
      return data.token;
    }
    return null; // Add a return statement for the case when no token is found
  } catch (error) {
    console.error('error: ', error);
    return null;
  }
};

export interface IQueryDataByFilter {
  page: number;
  count: number;
  outFields: string[];
  orderByFields: string[];
  keepPrevious: boolean;
}

export const queryDataByFilter = async (where: string, params?: IQueryDataByFilter | null) => {
  const { page = 0, count = 10, outFields = ['*'], orderByFields = ['name'], keepPrevious = false } = params || {};

  const query = {
    page,
    count,
    where,
    outFields,
    orderByFields,
    keepPrevious,
  };

  await mapController.queryOrgsByPage(query);
};
