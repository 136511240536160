import { useSelector } from 'react-redux';
import { languageSelector } from '../../store/slices/appSlice';
import { TRANSLATIONS } from '../../translations/translations';
import { GlobalDataTypes } from '../publicApp/sidePanels/types';
import './totalStatsReport.scss';

interface Props {
  statsData: GlobalDataTypes;
}
const TotalStatsReport = ({ statsData }: Props) => {
  const language = useSelector(languageSelector);
  const selectedLanguage = TRANSLATIONS[language];

  const {
    starValueTATotal,
    starValueRTotal,
    carbonValue,
    totalBudget,
    totalBudgetNeeded,
    totalBeneficiaries,
    totalStaff,
  } = statsData;

  const handleTotalValues = (value: number) => {
    if (value) {
      if (value > 1000) {
        const rawValue = Number(Number(value).toFixed(0));
        return <span>{rawValue.toLocaleString()}</span>;
      } else {
        const updatedValue = Number(value).toPrecision(4);
        return <span>{updatedValue}</span>;
      }
    }
    return <span>{selectedLanguage['No Data']}</span>;
  };

  return (
    <div className='stats-container'>
      <div className='stats-section'>
        <div className='subsection half-width'>
          <h5>{selectedLanguage['STAR Threat Abatement Score']}</h5>
          <div className='value-wrapper'>
            <div className='value'>
              {handleTotalValues(starValueTATotal)}
              <span className='subtext'>({selectedLanguage['total']})</span>
            </div>
          </div>
        </div>
        <div className='subsection half-width'>
          <h5>{selectedLanguage['STAR Restoration Score']}</h5>
          <div className='value-wrapper'>
            <div className='value'>
              {handleTotalValues(starValueRTotal)}
              <span className='subtext'>({selectedLanguage['total']})</span>
            </div>
          </div>
        </div>
        <div className='subsection half-width'>
          <h5>{selectedLanguage['Total Climate Change Mitigation']}</h5>
          <div className='value-wrapper'>
            <div className='value'>{handleTotalValues(carbonValue)}</div>
          </div>
        </div>
        <div className='subsection half-width'>
          <h5>{selectedLanguage['Total Budget']}</h5>
          <div className='value-wrapper'>
            <div className='value'>{handleTotalValues(totalBudget)}</div>
          </div>
        </div>
        <div className='subsection half-width'>
          <h5>{selectedLanguage['Total Budget Needed']}</h5>
          <div className='value-wrapper'>
            <div className='value'>{handleTotalValues(totalBudgetNeeded)}</div>
          </div>
        </div>
        <div className='subsection half-width'>
          <h5>{selectedLanguage['Total Beneficiares']}</h5>
          <div className='value-wrapper'>
            <div className='value'>{handleTotalValues(totalBeneficiaries)}</div>
          </div>
        </div>
        <div className='subsection half-width'>
          <h5>{selectedLanguage['Total Staff']}</h5>
          <div className='value-wrapper'>
            <div className='value'>
              <span>{handleTotalValues(totalStaff)}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TotalStatsReport;
