import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import logo from '../../assets/IUCN_logo_3.webp';
import loginImg from '../../assets/login-img.webp';
import { FormTypes } from './types';
import config from '../../configs/config';
import { languageSelector, setVisiblewindow, setGeneratedToken } from '../../store/slices/appSlice';
import LangaugeSwitcher from '../LanguageSwitcher';
import './login.scss';
import { TRANSLATIONS } from '../../translations/translations';
import { setIsMainPanelLoading } from '../../store/slices/sidePanelSlice';

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const language = useSelector(languageSelector);

  const { register, handleSubmit, watch } = useForm<FormTypes>();
  const dispatch = useDispatch();
  const selectedLanguage = TRANSLATIONS[language];

  const onSubmit = async (values: FormTypes) => {
    try {
      setLoading(true);
      const { data } = await axios.post(`${config.apiUrl}/auth/login`, { ...values });
      if (data && data.user && data.tokens) {
        setTimeout(() => {
          // TODO why is this needed?
          dispatch(setVisiblewindow('map'));
        }, 100);

        // TODO when swapping app to full public, use /agol-token endpoint in HomePage
        dispatch(setGeneratedToken(data.tokens.access.agolToken));
        dispatch(setIsMainPanelLoading(true));
      }
    } catch (error: any) {
      if (error && error.response && error.response?.data) {
        setErrorMessage(error.response.data.message);
        setLoading(false);
        setTimeout(() => setErrorMessage(''), 4000);
        console.log('Error: ', error.response?.data.message);
        return;
      }
    }
  };

  const username = watch('email', '');
  const password = watch('password', '');

  return (
    <div className='Login'>
      <div className='login-container'>
        <div className='left-side'>
          <div className='language-switcher-container'>
            <LangaugeSwitcher {...config.languageConfig} />
          </div>
          <div className='login-header'>
            <img src={logo} alt='iucn logo' />
            <h1>{selectedLanguage['International Union for Conservation of Nature']}</h1>
          </div>
          <div className='login-sub-header'>
            <h2>{selectedLanguage['Contributions for Nature Platform']}</h2>
            <p>{selectedLanguage['Use your IUCN credentials to login.']}</p>
          </div>

          <form onSubmit={handleSubmit(onSubmit)}>
            {errorMessage && (
              <div className='display-error-message'>
                <p>{errorMessage}</p>
              </div>
            )}
            <label htmlFor='email'>
              <span className='label'>{selectedLanguage['User Name']}</span>
              <input type='text' className='email' {...register('email', { required: true })} />
            </label>
            <label htmlFor='password'>
              <span className='label'>{selectedLanguage['Password']}</span>

              <input type='password' {...register('password', { required: true })} />
            </label>

            <div className='login-btn'>
              <button type='submit' disabled={loading || !username || !password}>
                {' '}
                {loading ? selectedLanguage['Loading'] : selectedLanguage['Login']}
              </button>
            </div>
          </form>
        </div>
        <div className='right-side'>
          <img src={loginImg} alt='Contributions placeholder' />
        </div>
      </div>
    </div>
  );
};

export default Login;
