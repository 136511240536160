export const globalChartContent = (selectedLanguage: any) => {
  return {
    Conservation: {
      title: selectedLanguage['Conservation contribution to biodiversity'],
      content: (
        <>
          <p>
            {
              selectedLanguage[
                'Methods for generating Conservation and Restoration contributions towards biodiversity goals Contributions towards biodiversity goals are generated using the Species Threat Abatement and Restoration (STAR) Metric, which quantifies the potential reduction in global species extinction risk that can be achieved by acting through threat abatement or restoration in a specific area (Mair et al., 2021).'
              ]
            }
          </p>
          <h4>{selectedLanguage['Data Sources']}</h4>
          <p>
            {
              selectedLanguage[
                "Method: STAR scores are calculated for species of amphibians, birds and mammals for which current or historical area of habitat occurs in the Area of Interest. Species assessed as Near Threatened, Vulnerable, Endangered or Critically Endangered on the IUCN Red List of Threatened Species receive non-zero weightings in calculating the STAR score. For each 5x5 km grid square, the STAR Threat Abatement (STAR TA) score for a species is assessed based on the proportion of current range represented by the grid square, and the species extinction risk. Adding STAR scores across all species gives the total STAR score for the square. Adding all the STAR scores across all the squares in a particular defined area indicates that site's relative conservation contribution towards biodiversity goals."
              ]
            }
          </p>
          <p>
            {
              selectedLanguage[
                'Restoration contributions towards biodiversity goals (STAR R) are calculated similarly to STAR Threat Abatement Scores, but for areas formerly occupied by a species. They indicate the potential of restoration within a grid cell to reduce species extinction risk. A global weighting of 0.29 is applied to STAR Restoration scores based on studies of restoration success, to discount for the time taken for restoration and its probability of success.'
              ]
            }
          </p>
          <p>
            {
              selectedLanguage[
                'Pie charts visualizing the STAR TA and STAR R contributions in a site as a proportion of the maximum potential contribution that could be generated in terrestrial United Nations Statistical Division M49 Geographic Regions and Countries.'
              ]
            }
          </p>
          <h4>{selectedLanguage['Data used to compute STAR']}:</h4>
          <p>
            {' '}
            {
              selectedLanguage[
                'The underlying STAR data is produced from the IUCN Red List of Threatened Species (iucnredlist.org)'
              ]
            }
          </p>
        </>
      ),
    },
    Restoration: {
      title: selectedLanguage['Restoration contribution to biodiversity'],
      content: (
        <>
          <p>
            {
              selectedLanguage[
                'Methods for generating Conservation and Restoration contributions towards biodiversity goals Contributions towards biodiversity goals are generated using the Species Threat Abatement and Restoration (STAR) Metric, which quantifies the potential reduction in global species extinction risk that can be achieved by acting through threat abatement or restoration in a specific area (Mair et al., 2021).'
              ]
            }
          </p>
          <h4>{selectedLanguage['Data Sources']}</h4>
          <p>
            {
              selectedLanguage[
                "Method: STAR scores are calculated for species of amphibians, birds and mammals for which current or historical area of habitat occurs in the Area of Interest. Species assessed as Near Threatened, Vulnerable, Endangered or Critically Endangered on the IUCN Red List of Threatened Species receive non-zero weightings in calculating the STAR score. For each 5x5 km grid square, the STAR Threat Abatement (STAR TA) score for a species is assessed based on the proportion of current range represented by the grid square, and the species’ extinction risk. Adding STAR scores across all species gives the total STAR score for the square. Adding all the STAR scores across all the squares in a particular defined area indicates that site's relative  conservation contribution towards biodiversity goals."
              ]
            }
          </p>
          <p>
            {
              selectedLanguage[
                'Restoration contributions towards biodiversity goals (STAR R) are calculated similarly to STAR Threat Abatement Scores, but for areas formerly occupied by a species. They indicate the potential of restoration within a grid cell to reduce species extinction risk. A global weighting of 0.29 is applied to STAR Restoration scores based on studies of restoration success, to discount for the time taken for restoration and its probability of success.'
              ]
            }
          </p>
          <p>
            {
              selectedLanguage[
                'Pie charts visualizing the STAR TA and STAR R contributions in a site as a proportion of the maximum potential contribution that could be generated in terrestrial United Nations Statistical Division M49 Geographic Regions and Countries.'
              ]
            }
          </p>
          <h4>{selectedLanguage['Data used to compute STAR']}:</h4>
          <p>
            {' '}
            {
              selectedLanguage[
                'The underlying STAR data is produced from the IUCN Red List of Threatened Species (iucnredlist.org)'
              ]
            }
          </p>
        </>
      ),
    },
    'Climate change': {
      title: selectedLanguage['Restoration contribution to climate change mitigation'],
      content: (
        <>
          <p>
            {
              selectedLanguage[
                'Methods for generating Restoration contributions towards climate change mitigation goals Contributions towards carbon sequestration goals are generated through the IUCN- Winrock FLR removal database, which provides the potential impacts of climate mitigation based on the global carbon dioxide (CO2) removal rates for the selective FLR intervention activities. The carbon sequestration potential of restoration activities iscalculated by multiplying the restoration area and region-specific carbon removal factors.'
              ]
            }
          </p>
          <p>
            {
              selectedLanguage[
                'The carbon removal factors are derived from the Global FLR CO2 removal database, developed by IUCN in partnership with Winrock international. This database offers potential CO2 removals from specific FLR actions (i.e. natural regeneration, planted forests and woodlots, agroforestry and mangrove restoration) at both national and subnational scales.'
              ]
            }
          </p>
          <p>
            {
              selectedLanguage[
                'The carbon removal factors are derived from the Global FLR CO2 removal database, developed by IUCN in partnership with Winrock international. This database offers potential CO2 removals from specific FLR actions (i.e. natural regeneration, planted forests and woodlots, agroforestry and mangrove restoration) at both national and subnational scales. The carbon removal rate (i.e removal factor) database was developed by reviewing the literature on biomass accumulation rates from FLR activities across the globe, using published and scientifically validated data. All these collected data points are converted into spatial points and also overlaid on the global Koppen-Geiger Climate classification to determine the climate of the FLR sites. The aboveground biomass curve was developed by Chapman Richards growth model applied by collating data points over 144 studies whereby the belowground biomass was estimated as a function of the calculated aboveground biomass. For each FLR activity, the sum of the aboveground and belowground biomass growth rates are presented as total tons C ha-1 year-1 and then converted to annual removal factors as carbon dioxide (tons CO2 ha-1 year-1) by multiplying the tons of C by the factor 3.66 (i.e., 44 g CO2 over 12 g C).'
              ]
            }
          </p>
          <p>
            {
              selectedLanguage[
                'Pie charts visualizing the carbon sequestration contributions in a site as a proportion to countries FLR basedrestoration commitments potential of climate mitigation. The Bonn Challenge and RIO conventions pledges from 115 countries are used for this pie chart.'
              ]
            }
          </p>
          <h4>{selectedLanguage['Data Sources']}</h4>
          <span style={{ display: 'block' }}>{selectedLanguage['Method']}:</span>
          <p>
            {
              selectedLanguage[
                'Bernal, Blanca, Lara T. Murray, and Timothy RH Pearson. "Global carbon dioxide removal rates from forest landscape restoration activities." Carbon balance and management 13.1 (2018): 1-13.'
              ]
            }
          </p>
          <h4>{selectedLanguage['Data used to compute Pie Charts']}:</h4>
          <p>
            {
              selectedLanguage[
                'Sewell, Annelies, Stefan van der Esch, and Hannah Löwenhardt. "Goals and Commitments for the Restoration Decade." Sewell, Annelies, H. Löwenhardt, and S. Van der Esch. "Technical Note on Methodology for the Global Restoration Commitments Database." PBL Netherlands Environmental Assessment Agency, The Hague (2020).'
              ]
            }
          </p>
        </>
      ),
    },
  };
};
