interface ValueTypes {
  country: string;
  region: string;
}

type ChartCountTypes = 1 | 2 | 3;

export const headerDescriptionBuilder = (chartsCount: ChartCountTypes, values: ValueTypes, selectedLanguage: any) => {
  const { country, region } = values;

  if (chartsCount === 1) {
    return {
      climateMitigation: {
        header: [
          selectedLanguage[
            "Contribution's potential contribution towards climate change mitigation through restoration as a proportion of the total global total climate mitigation."
          ],
        ],
        subHeader: [``],
      },
      restoration: {
        header: [
          selectedLanguage[
            'Contribution as a proportion of the global total potential contribution towards species extinction risk reduction by restoration.'
          ],
        ],

        subHeader: [``],
      },
      threatAbatement: {
        header: [
          selectedLanguage[
            'Contribution as a proportion of the global total potential contribution towards species extinction risk reduction by abating threats'
          ],
        ],
        subHeader: [``],
      },
    };
  } else if (chartsCount === 2) {
    return {
      climateMitigation: {
        header: [
          `${country}'s ${selectedLanguage['Potential contribution towards climate change mitigation through restoration as a proportion of the total']} ${region} ${selectedLanguage['potential contribution']}
          `,
          `${region}'s ${selectedLanguage['Potential contribution towards climate change mitigation through restoration as a proportion of the total global potential contribution']}`,
        ],
        subHeader: [``, ``],
      },
      restoration: {
        header: [
          `${selectedLanguage['Contribution as a proportion of']} ${region}'s ${selectedLanguage['total potential contribution towards species extinction risk reduction by restoration.']}`,
          `${region}'s ${selectedLanguage['Potential contribution towards species extinction risk reduction through restoration as a proportion of the total global potential contribution']}`,
        ],
        subHeader: [``, ``],
      },
      threatAbatement: {
        header: [
          `${selectedLanguage['Contribution as a proportion of']} ${region}'s ${selectedLanguage['total potential contribution towards species extinction risk reduction by abating threat']}`,
          `${region} ${selectedLanguage['Potential contribution towards species extinction risk reduction by abating threats as a proportion of the total global potential contribution']}`,
        ],
        subHeader: [``, ``],
      },
    };
  } else {
    return {
      climateMitigation: {
        header: [
          `${selectedLanguage["Contribution's potential contribution towards climate change mitigation through restoration as a proportion of the total"]} ${country} ${selectedLanguage['potential contribution']}`,
          `${country}'s ${selectedLanguage['Potential contribution towards climate change mitigation through restoration as a proportion of the total']} ${region} ${selectedLanguage['potential contribution']}`,
          `${region}'s ${selectedLanguage['Potential contribution towards climate change mitigation through restoration as a proportion of the total global potential contribution']}`,
        ],
        subHeader: [``, ``, ``],
      },
      restoration: {
        header: [
          `${selectedLanguage['Contribution as a proportion of']} ${country}'s ${selectedLanguage['total potential contribution towards species extinction risk reduction by restoration.']}`,
          `${country}'s ${selectedLanguage['Potential contribution towards species extinction risk reduction through restoration as a proportion of the potential contribution from']} ${region} ${selectedLanguage['overall']}.`,
          `${region}'s ${selectedLanguage['Potential contribution towards species extinction risk reduction through restoration as a proportion of the total global potential contribution']}`,
        ],
        subHeader: [``, ``, ``],
      },
      threatAbatement: {
        header: [
          `${selectedLanguage['Contribution as a proportion of']} ${country}'s ${selectedLanguage['total potential contribution towards species extinction risk reduction by abating threat']}`,
          `${country}'s ${selectedLanguage['Potential contribution towards species extinction risk reduction by abating threats as a proportion of the potential contribution from']} ${region} ${selectedLanguage['overall']}.`,
          `${region} ${selectedLanguage['Potential contribution towards species extinction risk reduction by abating threats as a proportion of the total global potential contribution']}`,
        ],
        subHeader: [``, ``, ``],
      },
    };
  }
};
