import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from '../../../store/store';
import LayerWidgetDropdown from '../../Dropdowns/LayerWidgetDropdown';
import './index.scss';

export const dropdownLayerWidget = (mapView: __esri.MapView, index: number) => {
  const node = document.createElement('div');
  mapView?.ui.add(node, { position: 'top-left', index });
  ReactDOM.render(
    <Provider store={store}>
      <LayerWidgetDropdown />
    </Provider>,
    node
  );
};
