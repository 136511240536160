import { RefObject } from 'react';
import { setDefaultOptions, loadModules } from 'esri-loader';
import store from '../store/store';
import {
  setMapLoaded,
  setSelectedContribution,
  setSelectedCountryOrRegion,
  setStrMapExtent,
} from '../store/slices/mapSlice';
import { dropdownLayerWidget } from '../components/mapview/createNodes/DropdownLayerWidget';
import _debounce from 'lodash/debounce';
import {
  setClickedCountryContributions,
  setCountryLevelData,
  setMemberTypes,
  setSingleContribution,
  setUserData,
  setRegionLevelData,
} from '../store/slices/appSlice';
import {
  setContributionAttachments,
  setIsContributionLoading,
  setIsCountryLoading,
  setIsMainPanelLoading,
  setVisiblePanel,
  setOrganizationData,
} from '../store/slices/sidePanelSlice';
import config from '../configs/config';
import { AttributeTypes } from '../utils/types';
import { mapConfig } from '../configs/mapConfig';
import { statisticsQueries } from '../configs/statisticsQueries';
import { dropdownWidgetsWrapper } from '../components/mapview/createNodes/DropdownWidgetsWrapper';
import { TRANSLATIONS } from '../translations/translations';
import { setGlobalLoader } from '../store/slices/loaderSlice';
import { urlParamParser } from '../components/publicApp/sidePanels/helpers/utils';
const { layersConfig, LAYER_IDS } = config;

setDefaultOptions({ css: true, version: '4.22' });

interface OrgsCountTypes {
  orgs_count: number;
  organizationMemberType: string;
  ownerOrgs: string;
  originalOwnerOrg?: string | null;
  partnerOrgs?: string | null;
  mainOwnerOrgs?: string | null;
}

const iucnPolygonStyle = {
  type: 'simple-fill',
  color: [0, 52, 120, 0.3],
  // color: 'red',
  outline: {
    color: [0, 0, 255],
    width: 1,
  },
};

const iucnHighlightStyle = {
  type: 'simple-fill',
  color: [0, 255, 0, 0.3],
  outline: {
    color: [0, 255, 0],
    width: 2,
  },
};

export const STYLE_TYPE = {
  DEFAULT: 'default',
  NORMAL: 'normal',
  HIGHLIGHT: 'highlight',
};

const STYLES_MAP = {
  [STYLE_TYPE.DEFAULT]: iucnPolygonStyle,
  [STYLE_TYPE.NORMAL]: iucnPolygonStyle,
  [STYLE_TYPE.HIGHLIGHT]: iucnHighlightStyle,
};

class MapController {
  #map?: __esri.Map;
  #mapView?: __esri.MapView;
  #webmap?: __esri.WebMap;
  #contributionsGraphicsLayer?: __esri.GraphicsLayer | any;
  #watchPopup?: __esri.WatchHandle;
  #layerView?: __esri.LayerView | any;

  initializeAuth = async (agolToken: any) => {
    const [esriId] = await loadModules(['esri/identity/IdentityManager']);
    esriId.registerToken({
      server: 'https://www.arcgis.com/sharing/rest',
      token: agolToken,
    });
  };

  initializeMap = async (domRef: RefObject<HTMLDivElement>) => {
    if (!domRef.current) return;

    const [MapView, GraphicsLayer, Map, FeatureLayer, esriConfig] = await loadModules([
      'esri/views/MapView',
      'esri/layers/GraphicsLayer',
      'esri/Map',
      'esri/layers/FeatureLayer',
      'esri/config',
    ]);

    const token = store.getState().appSlice.generatedToken;

    esriConfig.apiKey = token;
    this.#map = new Map({ basemap: 'streets-vector' });

    this.#mapView = new MapView({
      map: this.#map,
      container: domRef.current,
      zoom: 2,
      center: [45.9441973, 49.9555081],
      constraints: {
        minZoom: 2,
      },

      highlightOptions: {
        color: 'orange',
      },
    });

    this.#mapView?.when(async () => {
      store.dispatch(setMapLoaded(true));
      if (!this.#mapView) return;

      const searchCountryLayerUrl = layersConfig.find((layer) => layer.id === LAYER_IDS.COUNTRY_LAYER);

      const searchCountryLayer = new FeatureLayer({
        url: searchCountryLayerUrl?.url,
      });

      for (const layer of layersConfig) {
        const featureLayer = new FeatureLayer({
          id: layer.id,
          url: layer.url,
          title: layer.name,
          visible: layer.visible,
          opacity: layer.opacity,
          popupTemplate: layer.popupTemplate,
          popupEnabled: layer.popupEnabled,
          defaultPopupTemplateEnabled: true,
          definitionExpression: layer.definitionExpression,
        });

        if (layer.renderer) {
          featureLayer.renderer = layer.renderer;
        }

        featureLayer.load();

        if (layer.id === LAYER_IDS.APPROVED_CONTRIBUTIONS) {
          const where = 'ownerOrgs IS NOT NULL AND beneficiaryCountries IS NOT NULL';
          const mainOrgsData = (await this.queryTotalOrgs(featureLayer, where)) as __esri.Graphic[];
          this.handleUpdateContributionState(mainOrgsData);
        }
        this.#map?.add(featureLayer);
      }
      await this.handleMapClickEvent();

      this.#contributionsGraphicsLayer = new GraphicsLayer({
        visible: true,
        id: LAYER_IDS.CONTRIBUTION_GRAPHICS,
      }) as __esri.GraphicsLayer;

      this.#map?.add(this.#contributionsGraphicsLayer);

      await this.handleAddWidgetsToMap(searchCountryLayer);
      await this.handlePopupChanges();
      await this.initializeAuth(token);
      await this.getMapExtent();
      urlParamParser();
    });
  };

  getMapExtent = async () => {
    const [watchUtils] = await loadModules(['esri/core/watchUtils']);

    watchUtils.watch(
      this.#mapView,
      'extent',
      _debounce((extent: __esri.Extent) => {
        if (extent) {
          const { xmax, xmin, ymax, ymin } = extent;
          const { wkid } = extent.spatialReference;
          const extentOjb = { xmax, xmin, ymax, ymin, wkid };

          store.dispatch(setStrMapExtent(JSON.stringify(extentOjb)));
        }
      }, 500)
    );
  };

  addStrExtentToMap = async (strExtent: string) => {
    const [Extent] = await loadModules(['esri/geometry/Extent']);

    const parseStrExtent = JSON.parse(strExtent);
    const { wkid, xmax, xmin, ymin, ymax } = parseStrExtent;

    const newExtent = new Extent({
      ymin,
      xmin,
      ymax,
      xmax,
      spatialReference: {
        wkid,
      },
    });

    this.#mapView?.when(() => {
      this.#mapView?.goTo(newExtent);
    });
  };

  handleAddWidgetsToMap = async (layer: __esri.FeatureLayer) => {
    const [Locate, BasemapGallery, Expand] = await loadModules([
      'esri/widgets/Locate',
      'esri/widgets/BasemapGallery',
      'esri/widgets/Expand',
    ]);

    const locate = new Locate({
      view: this.#mapView,
    });

    const basempaGallery = new BasemapGallery({
      view: this.#mapView,
    });

    const expand = new Expand({
      view: this.#mapView,
      content: basempaGallery,
    });

    if (this.#mapView) {
      dropdownLayerWidget(this.#mapView, 0);
      dropdownWidgetsWrapper(this.#mapView, 0);
      this.#mapView.ui.add(locate, { position: 'top-left', index: 1 });
      this.#mapView.ui.add(expand, { position: 'top-left', index: 3 });
    }
  };

  filterLayer = async (where: string) => {
    const countributionLayer = this.findLayerById(LAYER_IDS.APPROVED_CONTRIBUTIONS) as __esri.FeatureLayer;

    if (countributionLayer) {
      countributionLayer.definitionExpression = where;
    }
  };

  findLayerById = (id: string): __esri.Layer | undefined => {
    const layer = this.#map?.layers.find((lyr: any) => lyr.id === id);
    return layer;
  };

  handleMapClickEvent = () => {
    const countributionLayer = this.findLayerById(LAYER_IDS.APPROVED_CONTRIBUTIONS) as __esri.FeatureLayer;
    const countryLayer = this.findLayerById(LAYER_IDS.COUNTRY_LAYER) as __esri.FeatureLayer;
    const regionLayer = this.findLayerById(LAYER_IDS.REGION_LAYER) as __esri.FeatureLayer;

    if (this.#watchPopup) {
      this.#watchPopup.remove();
    }

    this.#mapView?.on('click', async (event) => {
      store.dispatch(setIsCountryLoading(true));
      // add loader panel as soon as map is clicked
      store.dispatch(setVisiblePanel('country-panel'));

      const opts: any = {
        include: [countryLayer, regionLayer],
        exclude: countributionLayer,
      };

      if (countributionLayer) {
        const query = countributionLayer.createQuery();
        query.geometry = event.mapPoint;
        query.outFields = ['*'];
        query.returnGeometry = true;

        try {
          const queryResult = await countributionLayer.queryFeatures(query);
          if (queryResult.features.length) {
            const features = queryResult.features;
            store.dispatch(setIsCountryLoading(false));
            const selectedContribution = {
              label: features[0]?.attributes.name,
              value: features[0]?.attributes.OBJECTID,
            };

            store.dispatch(setIsContributionLoading(true));
            store.dispatch(setSingleContribution(features[0]?.attributes));
            store.dispatch(setSelectedContribution(selectedContribution));

            if (features.length === 1) {
              store.dispatch(setVisiblePanel('contribution-panel'));
            } else {
              store.dispatch(setVisiblePanel('contribution-panel'));

              setTimeout(() => {
                store.dispatch(setIsContributionLoading(false));
              }, 500);
            }
          } else {
            // otherwise country or region was clicked
            await this.handleClickCountryOrRegionLayer(event, opts);
          }
        } catch (error) {
          console.log('error', error);
        }
      }
    });
  };

  addImagesToTemplate = async (layer: any, data: any) => {
    const language = store.getState().appSlice.language;
    const selectedLanguage = TRANSLATIONS[language || 'en'];
    const popupTemplate = mapConfig(selectedLanguage);

    if (!data.length) {
      const showFielsOnly = popupTemplate.contributionTemplateOptions.content[0];
      layer.popupTemplate = { content: [showFielsOnly] };
    } else {
      const newPopupTemplate = {
        lastEditInfoEnabled: false,
        content: popupTemplate.contributionTemplateOptions.content.filter((item) => item.type === 'fields'),
      };
      layer.popupTemplate = newPopupTemplate;
    }
  };

  attachmentsDataBuilder = (data: any) => {
    const token = store.getState().appSlice.generatedToken;
    return data.map((attachment: any) => {
      return {
        name: attachment.name,
        url: `${attachment.url}?token=${token}`,
        type: attachment.contentType,
      };
    });
  };

  handleQueryAttachments = async (id: number) => {
    const contributionLayer = this.findLayerById(LAYER_IDS.APPROVED_CONTRIBUTIONS) as __esri.FeatureLayer;

    try {
      const queryAttachementsResponse = await contributionLayer.queryAttachments({ objectIds: [id] });
      if (queryAttachementsResponse && queryAttachementsResponse[id]?.length) {
        this.addImagesToTemplate(contributionLayer, queryAttachementsResponse[id]);
        const attachmentData = this.attachmentsDataBuilder(queryAttachementsResponse[id]);
        store.dispatch(setContributionAttachments(attachmentData));
      } else {
        this.addImagesToTemplate(contributionLayer, []);
        store.dispatch(setContributionAttachments([]));
      }
    } catch (error) {
      console.error(error);
    }
  };

  handlePopupChanges = async () => {
    this.#mapView?.popup.watch('selectedFeature', async (event) => {
      this.#watchPopup = event;

      store.dispatch(setIsContributionLoading(true));
      if (event) {
        const id = event.attributes?.OBJECTID;
        await this.handleQueryAttachments(id);
        const queryResponse = await this.queryData({ where: `OBJECTID = ${id}`, outFields: ['*'] });
        const contributionData = queryResponse?.length ? queryResponse[0] : null;

        if (contributionData) {
          store.dispatch(setSingleContribution(contributionData));
        }

        setTimeout(() => {
          store.dispatch(setIsContributionLoading(false));
        }, 500);
      }
    });
  };

  handleClickCountryOrRegionLayer = async (event: any, opts: any) => {
    const showCountry = store.getState().mapSlice.displayCountryLayer;
    const response = (await this.#mapView?.hitTest(event, opts)) as __esri.HitTestResult;

    if (response.results.length > 0) {
      const attributes = response.results[0].graphic.attributes;
      const objectId = showCountry ? attributes.OBJECTID_1 : attributes.OBJECTID;

      showCountry
        ? this.handleQueryCountry({ where: `OBJECTID_1=${objectId}` }, LAYER_IDS.COUNTRY_LAYER)
        : this.handleQueryRegion({ where: `OBJECTID=${objectId}` }, LAYER_IDS.REGION_LAYER);
    } else {
      store.dispatch(setIsCountryLoading(false));
    }
  };

  handleQueryRegion = async (params: any, layerId: string, goTo?: boolean | null) => {
    const featureLayer = this.findLayerById(layerId) as __esri.FeatureLayer;
    const contributionLayer = this.findLayerById(LAYER_IDS.APPROVED_CONTRIBUTIONS) as __esri.FeatureLayer;
    const { where } = params;

    // if goTo is undefined or null, means is not defined, so  by default we zoom to the area, this is regarding the exception we are doing to handle the region with a odd extent
    const zoomToArea = goTo === undefined || goTo === null ? true : goTo;

    if (featureLayer) {
      const query = featureLayer.createQuery();
      query.where = where;
      query.outFields = ['*'];
      query.returnGeometry = true;

      try {
        const queryResult = await featureLayer.queryFeatures(query);

        if (queryResult.features.length) {
          store.dispatch(setVisiblePanel('region-panel'));
          if (zoomToArea) {
            this.#mapView?.goTo(queryResult.features);
          }
          const region = queryResult.features[0].attributes.MapRegion;
          const OBJECTID = queryResult.features[0].attributes.OBJECTID;

          const where = `ownerOrgs IS NOT NULL AND beneficiaryCountries IS NOT NULL AND beneficiaryRegions LIKE '%${region}%'`;
          const mainOrgsData = (await this.queryTotalOrgs(contributionLayer, where)) as __esri.Graphic[];
          const structureData = this.mainDataBuilder(mainOrgsData);

          store.dispatch(setSelectedCountryOrRegion({ value: region, label: region }));
          store.dispatch(setRegionLevelData({ region, OBJECTID, data: structureData }));
          store.dispatch(setIsCountryLoading(false));
          store.dispatch(setGlobalLoader(false));
        } else {
          store.dispatch(setIsCountryLoading(false));
          store.dispatch(setGlobalLoader(false));
        }
      } catch (error) {
        console.error('error: e', error);
        store.dispatch(setIsCountryLoading(false));
        store.dispatch(setGlobalLoader(false));
      }
    }
  };

  handleQueryCountry = async (params: any, layerId: string) => {
    const featureLayer = this.findLayerById(layerId) as __esri.FeatureLayer;
    const contributionLayer = this.findLayerById(LAYER_IDS.APPROVED_CONTRIBUTIONS) as __esri.FeatureLayer;

    const { where } = params;

    if (featureLayer) {
      const query = featureLayer.createQuery();
      query.where = where;
      query.outFields = ['OBJECTID', 'ISO3', 'NAME_0'];
      query.returnGeometry = true;

      try {
        const queryResult = await featureLayer.queryFeatures(query);
        if (queryResult.features.length) {
          this.#mapView?.goTo(queryResult.features);
          store.dispatch(setVisiblePanel('country-panel'));
          const country = queryResult.features[0].attributes.NAME_0;
          const countryISO3 = queryResult.features[0].attributes.ISO3;

          const where = `ownerOrgs IS NOT NULL AND beneficiaryCountries IS NOT NULL AND beneficiaryCountries LIKE'%${countryISO3}%'`;

          const mainOrgsData = (await this.queryTotalOrgs(contributionLayer, where)) as __esri.Graphic[];
          const structureData = this.mainDataBuilder(mainOrgsData);

          store.dispatch(setSelectedCountryOrRegion({ value: country, label: country }));
          store.dispatch(setCountryLevelData({ country, countryISO3, data: structureData }));
          store.dispatch(setIsCountryLoading(false));
          store.dispatch(setGlobalLoader(false));
        } else {
          store.dispatch(setIsCountryLoading(false));
          store.dispatch(setGlobalLoader(false));
        }
      } catch (error) {
        console.log('error: e', error);
      }
    }
  };

  queryCountryBasedOnRegionException = async (where: string, layerId: string) => {
    const featureLayer = this.findLayerById(layerId) as __esri.FeatureLayer;

    if (featureLayer) {
      const query = featureLayer.createQuery();
      query.where = where;
      query.returnGeometry = true;

      try {
        const queryResult = await featureLayer.queryFeatures(query);
        store.dispatch(setGlobalLoader(false));

        if (queryResult.features.length) {
          this.#mapView?.goTo(queryResult.features);
        }
      } catch (error) {
        console.log('error: e', error);
        store.dispatch(setGlobalLoader(false));
      }
    }
  };

  queryOrgsByPage = async (params: any) => {
    const { page, count, where, outFields, orderByFields, keepPrevious } = params;

    this.#mapView?.when(async () => {
      const contributionLayer = this.#map?.layers.find(
        (lyr: any) => lyr.id === LAYER_IDS.APPROVED_CONTRIBUTIONS
      ) as __esri.FeatureLayer;

      if (contributionLayer) {
        const query = contributionLayer.createQuery() as __esri.Query;
        query.where = where;
        query.outFields = outFields;
        query.returnGeometry = false;
        query.start = page;
        query.num = count;
        query.orderByFields = orderByFields;

        try {
          const queryResult = await contributionLayer.queryFeatures(query);
          const attributes = queryResult.features.map((feature) => feature.attributes);
          const updatedAttributes = attributes.map((item: any) => {
            return { ...item };
          });

          store.dispatch(setOrganizationData({ data: updatedAttributes, keepPrevious: keepPrevious }));
        } catch (error) {
          console.warn('error: ', error);
          store.dispatch(setIsMainPanelLoading(false));
        }
      }
    });
  };

  querySearchContribution = async (org: string, name: string, partner: string | null) => {
    const contributionLayer = this.findLayerById(LAYER_IDS.APPROVED_CONTRIBUTIONS) as __esri.FeatureLayer;

    if (contributionLayer) {
      const query = contributionLayer.createQuery() as __esri.Query;
      const queryString = name
        .split(' ')
        .map((item) => `name LIKE '%${item}%'`)
        .join(' AND ');

      let defaultQueryString = `ownerOrgs IS NOT NULL AND beneficiaryCountries IS NOT NULL AND ownerOrgs LIKE '%${org}%'`;

      if (partner) {
        defaultQueryString += ` OR partnerOrgs LIKE '%${partner}%'`;
      }

      query.where = `${defaultQueryString} AND ${queryString}`;
      query.outFields = ['*'];
      query.returnGeometry = false;
      query.num = 10;
      query.orderByFields = ['name'];

      try {
        const queryResult = await contributionLayer.queryFeatures(query);

        const attributes = queryResult.features.map((feature) => feature.attributes);

        const updatedAttributes = attributes.map((item: any) => {
          return { ...item };
        });
        store.dispatch(setOrganizationData({ data: updatedAttributes, keepPrevious: false }));
      } catch (error) {
        console.warn('error: ', error);
        store.dispatch(setIsMainPanelLoading(false));
      }
    }
  };

  groupBy = (data: OrgsCountTypes[]) => {
    const groupData: any = {};

    data.forEach((item) => {
      if (!groupData[item.ownerOrgs]) {
        groupData[item.ownerOrgs] = [item];
      } else {
        groupData[item.ownerOrgs].push(item);
      }
    });
    return groupData;
  };

  getSingleOrgs = (data: OrgsCountTypes[]) => {
    const uniqueOrgsData: OrgsCountTypes[] = [];

    data.forEach((item) => {
      const splitOrgs = item.ownerOrgs.split('|');
      if (splitOrgs.length > 1) {
        splitOrgs.forEach((org: string) => {
          uniqueOrgsData.push({ ...item, ownerOrgs: org, mainOwnerOrgs: org });
        });
      } else {
        uniqueOrgsData.push({ ...item, mainOwnerOrgs: item.ownerOrgs });
      }

      if (item.partnerOrgs) {
        const splitpartnerOrgs = item.partnerOrgs.split('|');
        if (splitpartnerOrgs.length > 1) {
          splitpartnerOrgs.forEach((org: string) => {
            uniqueOrgsData.push({ ...item, ownerOrgs: org, partnerOrgs: org, mainOwnerOrgs: item.ownerOrgs });
          });
        } else {
          uniqueOrgsData.push({ ...item, ownerOrgs: item.partnerOrgs, mainOwnerOrgs: item.ownerOrgs });
        }
      }
    });
    return uniqueOrgsData;
  };

  getContributionsCount = (groupData: any) => {
    const result: OrgsCountTypes[] = [];

    Object.keys(groupData).forEach((key) => {
      const orgs = groupData[key] as OrgsCountTypes[];
      if (orgs.length) {
        const org = orgs[0];
        const newCount = orgs.map((item) => item.orgs_count).reduce((a, b) => a + b, 0);
        result.push({ ...org, orgs_count: newCount });
      }
    });

    return result;
  };

  handleOrgCount = (data: OrgsCountTypes[]) => {
    if (!data.length) return;

    const uniqueOrgsData = this.getSingleOrgs(data);
    const groupByResult = this.groupBy(uniqueOrgsData);
    const contributionsCountResult = this.getContributionsCount(groupByResult);

    return [...contributionsCountResult].sort((a, b) => a.ownerOrgs.localeCompare(b.ownerOrgs));
  };

  queryTotalOrgs = async (layer: __esri.FeatureLayer, where: string) => {
    const { mainPanel } = statisticsQueries;
    const { statistics, groupByFieldsForStatistics, orderByFields } = mainPanel;

    if (layer) {
      const query = layer.createQuery() as __esri.Query;
      query.where = where;
      query.outFields = ['*'];
      query.returnGeometry = false;
      query.outStatistics = statistics;
      query.groupByFieldsForStatistics = groupByFieldsForStatistics;
      query.orderByFields = orderByFields;

      try {
        const queryResult = await layer.queryFeatures(query);
        return queryResult.features;
      } catch (error) {
        console.warn('error: ', error);
        return;
      }
    }
  };

  handleUpdateContributionState = (features: __esri.Graphic[]) => {
    const memberTypes = features.map((data) => data.attributes.organizationMemberType);
    const uniqueMemberTypes = [...new Set(memberTypes)];
    const addPropsTomemberTypes = uniqueMemberTypes.map((type: string) => {
      return {
        checked: false,
        id: type,
        label: type,
      };
    });

    const updateData = this.mainDataBuilder(features);
    store.dispatch(setUserData(updateData));
    store.dispatch(setClickedCountryContributions(features));
    store.dispatch(setIsMainPanelLoading(false));
    store.dispatch(setGlobalLoader(false));

    store.dispatch(setMemberTypes(addPropsTomemberTypes));
  };

  mainDataBuilder = (features: __esri.Graphic[]) => {
    if (!features.length) return [];
    const attributes = features.map((feature) => feature.attributes);

    const orgsData = this.handleOrgCount(attributes);
    return orgsData?.map((item) => {
      return {
        organization: item.ownerOrgs,
        memberType: item.organizationMemberType,
        orgName: item.ownerOrgs,
        orgCount: item.orgs_count,
        partnerOrgs: item.partnerOrgs,
        mainOwnerOrgs: item.mainOwnerOrgs,
        data: [],
      };
    });
  };

  queryMapExtent = async (whereClause: string) => {
    const contributionLayer = this.findLayerById(LAYER_IDS.APPROVED_CONTRIBUTIONS) as __esri.FeatureLayer;
    store.dispatch(setIsMainPanelLoading(true));

    if (contributionLayer) {
      const query = contributionLayer.createQuery();
      query.where = whereClause;
      query.outFields = ['OBJECTID'];
      query.returnGeometry = true;

      try {
        const queryResult = await contributionLayer.queryExtent(query);
        this.#mapView?.goTo(queryResult.extent);

        store.dispatch(setIsMainPanelLoading(false));
        store.dispatch(setGlobalLoader(false));
      } catch (error) {
        console.warn('error: ', error);
        store.dispatch(setIsMainPanelLoading(false));
        store.dispatch(setGlobalLoader(false));
      }
    }
  };

  queryData = async (params: any) => {
    const contributionLayer = this.findLayerById(LAYER_IDS.APPROVED_CONTRIBUTIONS) as __esri.FeatureLayer;
    const { where, outFields } = params;

    store.dispatch(setIsMainPanelLoading(true));

    if (contributionLayer) {
      const query = contributionLayer.createQuery();
      query.where = where;
      query.outFields = outFields;
      query.returnGeometry = true;

      try {
        const queryResult = await contributionLayer.queryFeatures(query);
        this.#mapView?.goTo(queryResult.features);
        store.dispatch(setIsMainPanelLoading(false));

        return this.getAttributes(queryResult.features);
      } catch (error) {
        console.warn('error: ', error);
        store.dispatch(setIsMainPanelLoading(false));
      }
    }
  };

  toggleLayerOpacity = (values: any) => {
    const { id, checked } = values;

    this.#map?.layers.forEach((layer) => {
      if (layer.id === id) {
        layer.opacity = checked ? 1 : 0;
      }
    });
  };

  toggleLayerVisibility = (visible: boolean) => {
    const countryLayer = this.findLayerById(LAYER_IDS.COUNTRY_LAYER) as __esri.FeatureLayer;
    const regionLayer = this.findLayerById(LAYER_IDS.REGION_LAYER) as __esri.FeatureLayer;

    if (countryLayer && regionLayer) {
      countryLayer.visible = visible;
      regionLayer.visible = !visible;
    }
  };

  fadeGraphicsOnDelay = () => {
    const graphicsLayer = this.findLayerById(LAYER_IDS.CONTRIBUTION_GRAPHICS) as __esri.GraphicsLayer;

    let fadeInterval = null as any;

    const fadeLayer = () => {
      if (graphicsLayer.opacity === 0) {
        clearInterval(fadeInterval);
        graphicsLayer.removeAll();
        graphicsLayer.set('opacity', 1);
      }
      graphicsLayer.set('opacity', graphicsLayer.opacity - 0.1);
    };

    setTimeout(() => {
      fadeInterval = setInterval(() => fadeLayer(), 70);
    }, 400);
  };

  getAttributes = (features: __esri.Graphic[]) => {
    if (features.length) {
      return features.map((feature) => {
        return { ...feature.attributes };
      });
    }
    return [];
  };

  removeGraphicsOnDelay = () => {
    setTimeout(() => {
      this.#contributionsGraphicsLayer.removeAll();
      this.#mapView?.graphics.removeAll();
    }, 1500);
  };

  resetMapView = () => {
    this.#mapView?.goTo({ center: [45.9441973, 49.9555081], zoom: 2 });
    this.#contributionsGraphicsLayer?.removeAll();
    this.#mapView?.popup.close();
    this.filterLayer('ownerOrgs IS NOT NULL AND beneficiaryCountries IS NOT NULL');
  };

  closePopup = () => {
    this.#mapView?.popup.close();
  };

  goToSelectedContribution = async (contribution: any) => {
    const { geometry } = contribution;
    this.#mapView?.goTo(geometry);
  };

  handlePopupTemplate = (attributes: AttributeTypes) => {
    if (!this.#mapView) return;
  };

  /**
   *
   * Creates an Esri Graphic based on a feature and a passed style key
   *
   * @param feature esri Feature {geometry, attributes}
   * @param type STYLE_TYPE.DEFAULT | STYLE_TYPE.NORMAL | STYLE_TYPE.HIGHLIGHT
   * @returns __esri.Graphic
   */
  createGraphic = async (feature: any, type = STYLE_TYPE.DEFAULT) => {
    const [Graphic] = await loadModules(['esri/Graphic']);

    const { geometry, attributes } = feature;

    const graphic = new Graphic({
      attributes,
      geometry,
      symbol: STYLES_MAP[type],
    });

    return graphic;
  };

  graphicSymbol = (color: string) => {
    return {
      type: 'simple-fill',
      color: [0, 52, 120, 0.3],

      outline: {
        color: [0, 0, 255],
        width: 1,
      },
    };
  };

  removeGraphics = () => {
    this.#contributionsGraphicsLayer.removeAll();
    this.#mapView?.goTo({ center: [45.9441973, 49.9555081], zoom: 2 });
    this.#mapView?.popup.close();
  };

  queryFeatures = async (params: any) => {
    const contributionLayer = this.findLayerById(LAYER_IDS.APPROVED_CONTRIBUTIONS) as __esri.FeatureLayer;
    const { where, outFields, returnGeometry, num, goToLocation, openPopup } = params;

    if (contributionLayer) {
      const query = contributionLayer.createQuery();
      query.where = where;
      query.outFields = outFields;
      query.num = num;
      query.returnGeometry = returnGeometry;

      try {
        const queryResult = await contributionLayer.queryFeatures(query);

        if (goToLocation) {
          this.#mapView?.goTo(queryResult.features);
        }
        // this only applies when user uses the search input for contributions
        if (openPopup) {
          this.#mapView?.popup.open({ features: queryResult.features });
        }
        return queryResult.features.map((feature) => {
          return { ...feature.attributes };
        });
      } catch (error) {
        console.warn('error: ', error);
        return null;
      }
    }
  };

  /**
   * This function highlights the passed features and can use a specific style and fade out after a time period.
   * @param {Object} params - Param object
   * @param {Feature[]} params.features - Features to be drawn
   * @param {string} params.styleType - string representing style to use.
   * @param {boolean} params.fade - should the highlighted feature fade out
   * @param {boolean} params.calledByWidget - This is a hack as it interferes with widgets that also have goTo operations.
   * @returns undefined
   */
  highlightFeatures = async (params: {
    features: any[];
    styleType?: string;
    fade?: boolean;
    calledByWidget?: boolean;
  }) => {
    const layer = this.findLayerById(LAYER_IDS.CONTRIBUTION_GRAPHICS) as __esri.GraphicsLayer;
    const features = params.features || null;
    const styleType = params.styleType || STYLE_TYPE.DEFAULT;
    const fade = params.fade || false;
    const calledByWidget = params.calledByWidget || false;

    if (!features || features.length < 1 || !layer) {
      return;
    }

    layer.removeAll();

    const geometries = [];

    for (const feature of features) {
      const { geometry } = feature;
      geometries.push(geometry);

      const graphic = await this.createGraphic(feature, styleType);
      layer.add(graphic);
    }

    if (!calledByWidget) {
      // @TODO look into Search overrideGoTo in order to remove this.
      // This is a hack so that Search will continue to goTo the selection. If this is
      // added when Search is used the map fails to goTo.
      // Probably need to overrideGoTo on the widget, but need to research that.
      this.#mapView?.goTo(geometries);
    }

    if (fade) {
      this.fadeGraphicsOnDelay();
    }
  };
}

export const mapController = new MapController();
