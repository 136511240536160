import { ENDPOINTS } from './endpoints';

export const LAYER_IDS = {
  APPROVED_CONTRIBUTIONS: 'approved-contributions',
  REGION_LAYER: 'region-layer',
  COUNTRY_LAYER: 'country-layer',
  KBA_POLYGON: 'kba-polygon',
  WDPA: 'wdpa',
  SEARCH_COUNTRY: 'search-country',
  CONTRIBUTION_GRAPHICS: 'contribution-graphics',
};

export const layersConfig = [
  {
    id: LAYER_IDS.COUNTRY_LAYER,
    showInWidgetList: false,
    name: 'World Map',
    url: ENDPOINTS.WORLD_MAP_LAYER,
    visible: true,
    opacity: 0,
    popupTemplate: null,
    definitionExpression: '',
    renderer: null,
  },
  {
    id: LAYER_IDS.APPROVED_CONTRIBUTIONS,
    showInWidgetList: true,
    name: 'Contributions',
    url: ENDPOINTS.APPROVED_CONTRIBUTIONS_LAYER,
    visible: true,
    opacity: 1,
    popupEnabled: true,
    popupTemplate: {},
    definitionExpression: 'validated = 1 AND ownerOrgs IS NOT NULL AND beneficiaryCountries IS NOT NULL',
    renderer: {
      type: 'simple',
      symbol: {
        type: 'simple-fill',
        color: 'rgba(0, 197, 255, 0.6)',
        outline: {
          width: 0.5,
          color: '#0084a8',
        },
      },
    },
  },
  {
    id: LAYER_IDS.REGION_LAYER,
    showInWidgetList: false,
    name: 'Regions',
    url: ENDPOINTS.REGION_LAYER,
    visible: false,
    opacity: 0,
    popupEnabled: false,
    popupTemplate: null,
    definitionExpression: '',
    renderer: null,
  },
  {
    id: LAYER_IDS.KBA_POLYGON,
    showInWidgetList: true,
    name: 'Key Biodiversity Areas',
    url: ENDPOINTS.KBA_POLYGON_LAYER,
    visible: true,
    opacity: 0,
    popupTemplate: null,
    definitionExpression: '',
    renderer: null,
  },
  {
    id: LAYER_IDS.WDPA,
    showInWidgetList: true,
    name: 'Protected Areas',
    url: ENDPOINTS.WDPA_LAYER,
    visible: true,
    opacity: 0,
    popupTemplate: null,
    definitionExpression: '',
    renderer: null,
  },
  {
    id: LAYER_IDS.SEARCH_COUNTRY,
    showInWidgetList: false,
    name: 'search-country',
    url: ENDPOINTS.SEARCH_COUNTRY_LAYER,
    visible: false,
    opacity: 0,
    popupTemplate: null,
    definitionExpression: '',
    renderer: null,
  },
];
