import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useSelector } from 'react-redux';
import { languageSelector } from '../../store/slices/appSlice';
import { TRANSLATIONS } from '../../translations/translations';

interface Props {
  data?: any;
}
const GlobalLevelPieChart = ({ data }: Props) => {
  const language = useSelector(languageSelector);
  const selectedLanguage = TRANSLATIONS[language];

  const options = {
    title: {
      text: '',
    },
    chart: {
      height: 160,
      width: 200,
      type: 'pie',
    },
    legend: {
      enabled: false,
    },
    series: data,
    credits: {
      text: '',
    },
    colors: ['#C1E0F5', '#037FB0'],
    tooltip: {
      pointFormat: '{series.name}: <b>{point.percentage:.0f}%</b>',
      formatter: function (this: any) {
        const value = this.y as number;
        const updatedValue = parseInt(this?.point?.rawValue || this.y);
        const percentValue = `${selectedLanguage['Percentage value']} <b>${this.point.percentage.toFixed(0)}%</b>`;
        const rawValue = `${selectedLanguage['Absolute value']} <b>${updatedValue?.toLocaleString()}</b>`;
        const precisionValue = `${selectedLanguage['Absolute value']} <b>${value.toPrecision(4)}</b>`;
        return `${this.key}</br>${value > 1000 ? rawValue : precisionValue}</br> ${percentValue}`;
      },
      valueDecimals: 0,
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: false,
          style: {
            fontWeight: 500,
          },
        },
      },
    },
  };
  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default GlobalLevelPieChart;
